@import "../../../_styles/utils";

.agreement {
  text-align: center;
  color: #868e96;
  font-size: 13px;

  a {
    text-decoration: none;
    color: #868e96;
  }
}
