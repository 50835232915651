@import '../../../_styles/utils';

.course_rcmd__wrapper {
  width: 100vw;
  height: 100vh;
  background: #1a1a1a;
  //background-image: var(--bg-image);
  //background-size: cover;
  //background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .course_rcmd__header {
    margin-bottom: 60px;

    h1 {
      font-family: GmarketSans;
      font-weight: bold;
      line-height: 1.4;
      font-size: 24px;
      color: #eaebed;
      @include desktop {
        font-size: 30px;
      }
    }

  }

  .course_rcmd__body {
    margin-bottom: 60px;

    img {
      width: 249px;
    }

    .course_rcmd__title {
      margin: 24px 0;
      font-family: "Pretendard";
      font-weight: bold;
      font-size: 24px;
      color: $white;
    }

    .course_rcmd__label {
      margin-bottom: 24px;

      span {
        color: $primary-color;
        background-color: #ffe5e9;
        margin-right: 12px;
        border-radius: 100px;
        padding: 5px 14px;
      }
    }

    .course_rcmd__detail {
      p {
        color: #eaebed;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 12px;
        @include desktop {
          br {
            display: none;
          }
        }
      }

      ul {
        font-size: 16px;
        font-family: "Pretendard";
        font-weight: 500;
        line-height: 24px;
        color: #c7c9cb;
        list-style: none;

        li::before {
          content: "\2022";
          color: #c7c9cb;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
      }
    }
  }

  .course_rcmd__footer {
    button {
      color: $white;
      background: $hanghae-primary-color;
      border-radius: 100px;
      width: 286px;
      height: 44px;
      font-family: "Pretendard";
      font-size: 16px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: -0.54px;
      text-align: center;
      outline: none;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}