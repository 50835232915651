@import "./utils.scss";

.ant-page-header-content {
  @include tablet {
    width: 360px;
  }
  @include desktop {
    width: 360px;
  }
}

.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding: 0;
}

.ant-collapse.ant-collapse-icon-position-left.ant-collapse-ghost {
  border-radius: 10px;
  box-shadow: 4px 5px 12px 0 rgba(0, 0, 0, 0.05);
  border: none;
  overflow: hidden;
}


.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow {
  .ant-collapse-content {
    border-top: none;

    .ant-collapse-content-box {
      background: #ffffff;
      padding: 10px 60px 20px;

      @include adesktop {
        padding: 10px 17px 20px;
      }
    }
  }
}

.ant-collapse-item.ant-collapse-no-arrow.panel {
  margin-bottom: 0;
}

.ant-select-selector:hover {
  //border: 0.5px solid #13C2C2 !important;
}

.ant-pagination-prev:hover a,
.ant-pagination-next:hover a {
  border-color: #13C2C2 !important;
}

.ant-select:hover, .ant-tree-select:hover, .ant-select-single:hover, .ant-select-show-arrow:hover {
  border-color: #13C2C2 !important;
}

.ant-slider-track {
  background-color: $hover-color;
}

.ant-slider-handle {
  border: solid 2px $hover-color !important;
}

.ant-slider-handle:focus {
  border-color: $hover-color;
}

.ant-slider-handle.ant-tooltip-open {
  border-color: $hover-color;
}

.ant-card-head-title {
  padding: 7px 0;
}

.ant-card-head {
  min-height: 40px;
}

.ant-pagination-prev, .ant-pagination-next, .ant-pagination-item {
  margin-right: -1px;
  margin-left: -1px;
}

.ant-pagination-item {
  border: none;

  height: 32px;
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  background-color: white;
  border: 1px solid #adb5bd;
}

.ant-pagination-prev .ant-pagination-item-link:hover, .ant-pagination-next .ant-pagination-item-link:hover {
  color: black !important;
}

.ant-pagination-next .ant-pagination-item-link {
  border-radius: 0px 10px 10px 0px;
}

.ant-pagination-prev .ant-pagination-item-link {
  border-radius: 10px 0px 0px 10px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: white;
  border: 1px solid #adb5bd;
  height: 40px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector:hover {
  color: black !important;
}

.ant-select-arrow {
  top: 17%;
  right: 1px;
  color: #495057;
  background-color: #e9ecef;
  padding: 19px;

  .anticon.anticon-down {
    position: absolute;
    top: 14px;
    right: 11px;
  }
}

.anticon {
  vertical-align: 0em;
}

.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 37px;
}

.ant-rate {
  line-height: 0px;
}

.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode {
  overflow-x: scroll;
  padding-bottom: 0px;
}

.ant-tooltip {
  max-width: 400px;
}

.ant-select-tree-indent-unit {
  width: 0px;
}

.sparta-success .ant-message-notice-content {
  background: #000;
  padding: 20px 140px;
  border-radius: 100px;

  .anticon {
    color: #fff;
  }
  span {
    color: #fff;
    font-size: 16px;
    line-height: 1;
    letter-spacing: -0.48px;
    margin-left: 16px;
    font-weight: bold;
    font-family: $spoqa-hans-neo;
  }
}