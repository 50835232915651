@import '../../../../_styles/utils';

.B2bNPSFooterWrapper {
  width: 100%;
  height: 320px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @include desktop {

  }
}

.B2bNPSFooterContainer {
  display: flex;
  flex-direction: column;
  max-width: 330px;
  text-align: center;
  margin: 0 auto;

  @include desktop {
    max-width: 510px;
    padding-top: 40px;
  }
}

.B2bNPSFooterTitle {
  font-family: "Pretendard";
  font-size: 20px;
  color: #0a0a0c;
  font-weight: bold;
  @include desktop {
    font-size: 28px;
    padding-top: 0px;
  }
}

.B2bNPSFooterBody {
  font-family: "Pretendard";
  font-size: 16px;
  font-weight: normal;
  color: black;
  @include desktop {
    font-size: 18px;
  }
}

.B2bNPSFooterContent {
  font-family: "Pretendard";
  font-size: 18px;
  font-weight: normal;
  @include desktop {
    font-size: 18px;
  }
}

.B2bNPSFooterIcon {
  width: 180px;
  height: 180px;
  padding-bottom: 24px;
}

.B2bNPSButtonWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 24px;
}

.B2bNPSFooterSubmitButton {
  width: 240px;
  height: 80px;
  font-family: Pretendard;
  font-weight: bold;
  background-color: black;
  color: white;
  border-radius: 24px;
  font-size: 18px;
}