@import "../../_styles/utils";

.contentWrapper {
  padding: 15px 0 126px 0;
  width: 100%;
  max-width: 1230px;
  margin: auto;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  @include desktop {
    padding: 30px 0 126px 0;
  }

  .highlight {
    color: $primary-color;
  }

  .rcmd-paid-after {
    width: 100%;
    max-width: 1230px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 72px;
    .myClassButton {
      width: 240px;
      height: 60px;
      border-radius: 12px;
      background-color: #e8344e;
      color: #ffffff;
      text-align: center;
      font-family: Pretendard;
      font-weight: bold;
      font-size: 22px;
      line-height: 60px;
      margin-bottom: 16px;
      cursor: pointer;
    }
    .myClassButton:hover {
      box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    }

    span {
      border-radius: 24px;
      background-color: #d4d4d4;
      text-alignment: center;
      padding: 10px 40px;
      font-size: 18px;
      font-weight: 500;
      color: black;
      margin: 24px auto;
      @include desktop {
        padding: 10px 56px;
      }

      img {
        height: 22px;
        transform: translateY(-2px);
      }
    }

    &__characters {
      position: relative;
      width: 100%;
      max-width: 90%;
      margin: auto;
      @include desktop {
        max-width: 100%;
      }

      &__img {
        width: 100%;
      }

      &__intro {
        position: absolute;
        top: 19px;
        white-space: nowrap;
        left: 50%;
        transform: translateX(-50%);
        font-family: NotoSansCJKKR;
        font-weight: bold;
        font-size: 18px;
        line-height: 1.4;
        color: $black;
        letter-spacing: -0.6;
        text-align: center;

        br {
          display: block;
        }

        @include desktop {
          top: 24px;
          font-size: 20px;
          br {
            display: none;
          }
        }
      }
    }

    &__no_characters {
      margin-top: 32px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        font-size: 16px;
        color: $black;
        line-height: 1.5;
        font-family: "Pretendard";
        margin-bottom: 0;
        font-weight: 500;
        text-align: center;

        @include desktop {
          font-size: 24px;
          br {
            display: none;
          }
        }
      }

      h4 {
        font-size: 20px;
        font-weight: bold;
        font-family: "Pretendard";
        color: $black;

        img {
          height: 21px;
          transform: translateY(-2px);
        }
      }

      &__header {
        margin-bottom: 24px;
        margin-top: 0;
        font-size: 18px;
        font-weight: bold;
        color: $black;
        font-family: "Pretendard";
        @include desktop {
          margin-bottom: 32px;
          font-size: 28px;
        }
      }

      &__bar {
        margin: 32px auto;
        width: 46px;
        height: 6px;
        background-color: #d4d4d4;
      }

      button {
        width: 248px;
        height: 56px;
        background: $primary-color;
        border: none;
        outline: none;
        border-radius: 4px;
        box-shadow: 0 4px 6px 0 rgba(163, 24, 24, 0.25);
        font-family: "Pretendard";
        color: $white;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        @include desktop {
          font-size: 20px;
        }

        img {
          margin-left: 8px;
        }
      }
    }

    &__bottom_cta {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__icon {
        width: 24px;
        margin: 32px 0 24px 0;
        cursor: pointer;
      }

      &__detail {
        cursor: pointer;
        font-family: NotoSansCJKKR;
        font-size: 16px;
        font-weight: 500;
        text-decoration: underline;
        color: #8b8b8b;
      }
    }
  }

  .formWrapper {
    overflow: hidden;
    width: 100%;
    @include desktop {
      width: 794px;
    }

    .done-header {
      h3 {
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: -1px;
        text-align: center;
        color: #000000;
        @include desktop {
          font-size: 35px;
        }
      }

      .desc {
        margin-top: 22px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: -0.7px;
        text-align: center;
        color: #000000;
        @include desktop {
          font-size: 18px;
          font-weight: 500;
        }
      }

      margin-bottom: 22px;
      @include desktop {
        margin-bottom: 43px;
      }
    }

    .rcmd-content {
      width: 92%;
      margin: 0 auto;
      @include desktop {
        width: 100%;
      }

      .rcmd-intro {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.9px;
        text-align: left;
        color: #000000;
        @include desktop {
          font-size: 30px;
          padding-top: 50px;
        }
      }

      .rcmd-title {
        width: 100vw;
        @include mobile {
          position: relative;
          left: -4.3%;
          margin-bottom: 24px;
        }
        @include desktop {
          width: 100%;
          margin-bottom: 16px;
          font-size: 38px;
        }
      }

      .rcmd-outro {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        margin-bottom: 16px;
        letter-spacing: -0.96px;
        text-align: left;
        color: #212529;
        @include desktop {
          font-size: 16px;
          max-width: 628px;
          margin-bottom: 48px;
        }
      }
    }

    img.ic-rcmd {
      width: 100%;
      object-fit: contain;
      display: block;

      @include desktop {
      }
    }

    .howto {
      margin-top: 32px;

      h3 {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: -0.9px;
        text-align: left;
        color: #212529;
        margin-bottom: 16px;

        @include desktop {
          font-size: 20px;
        }
      }

      .steps {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 40px;
        @include desktop {
          margin-bottom: 72px;
          flex-direction: row;
          justify-content: space-between;
        }

        .step {
          width: 332px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          margin-top: 10px;

          @include desktop {
            width: 375px;
            margin-top: 0;
            flex-direction: column;
          }

          .step-number {
            color: #212529;
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 1.6;
            font-weight: bold;

            hr {
              margin: 5px 0 0 0;
              border-top: 2px solid #d4d4d4;
            }
          }

          .step-title {
            color: black;
            font-size: 14px;
            line-height: 1.6;
            font-weight: 500;
            letter-spacing: -0.96;
            margin-left: 10px;
            @include desktop {
              font-size: 17px;
              margin-left: 0;
            }

            b {
              color: #e8344e;
            }
          }
        }
      }
    }

    .rank_wrap {
      h4 {
        @include mobile {
          margin-left: 4%;
          margin-bottom: 5px;
        }

        b {
          color: #e8344e;
          font-weight: 500;
        }

        font-size: 14px;
        @include desktop {
          font-size: 20px;
        }
      }

      h3 {
        @include mobile {
          margin-left: 4%;
        }
        font-size: 18px;
        font-weight: bold;
        padding-bottom: 24px;
        margin: 0 auto;
        line-height: 1.1;
        @include desktop {
          line-height: 1.2;
          font-size: 24px;
        }
      }

      .table-container {
        background-color: #f8f9fa;
        @include desktop {
          width: 586px;
        }

        table {
          width: 92%;
          margin: auto;
          box-sizing: border-box;
          padding: 4% 4%;

          thead {
            border-bottom: 1px solid #dee2e6;

            tr th {
              font-size: 12px;
              text-align: center;
              color: #495057;
              padding: 13px 0;
              font-weight: 500;
              @include desktop {
                font-size: 14px;
              }
            }
          }

          tbody {
            tr {
              border-bottom: 1px solid #dee2e6;

              &:last-child {
                border-bottom: none;
              }

              td {
                font-size: 16px;
                font-weight: 500;
                color: #212529;
                text-align: center;
                padding: 27px 0 23px 0;
                @include desktop {
                  padding: 33px 0;
                }

                &.star {
                  background-size: 37.6px;
                  background-position: 50% 50%;
                  background-repeat: no-repeat;
                }

                &.amount {
                  color: #e8344e;
                }
              }
            }
          }
        }
      }
    }

    .caution {
      width: 92%;
      margin: 48px auto 0 auto;
      @include desktop {
        width: 100%;
        margin-top: 48px;
      }

      p.notice {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.88px;
        text-align: left;
        color: #495057;
        @include desktop {
          font-size: 18px;
          font-weight: 500;
          letter-spacing: -0.9px;
          color: #495057;
          margin-bottom: 16px;
        }
      }

      p {
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: -0.88px;
        text-align: left;
        font-size: 16px;
        color: #495057;
        @include desktop {
          font-size: 18px;
          letter-spacing: -1.26px;
        }
      }
    }
  }

  .dashboard {
    width: 100%;
    height: fit-content;
    padding: 20.3px 21px 23px 21px;
    border-radius: 24px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
    border: solid 0.6px #dee2e6;
    background-color: #ffffff;

    @include desktop {
      width: 381px;
      height: fit-content;
      padding: 36px 28px 23px 27px;
    }

    &:last-child {
      @include desktop {
        margin-left: 30px;
      }
    }

    .dashboard-title {
      padding-bottom: 28px;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.6px;
      color: #212529;
    }

    table {
      width: 100%;
      padding-top: 28px;

      tr {
        td {
          padding: 9px 0;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: -0.48px;
          color: #212529;
        }

        .title {
          text-align: left;
        }

        .detail {
          text-align: right;
        }

        &:last-child {
          border-bottom: 1px solid #e9ecef;

          td {
            padding-bottom: 17px;
          }
        }
      }
    }

    .step-desc {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.6px;
      text-align: center;
      color: #212529;
      margin: 10px auto;
      @include desktop {
        margin-top: 24px;
        margin-bottom: 8px;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: -0.9px;
        text-align: center;
        color: #212529;
      }
    }

    .step-cta {
      margin-bottom: 8px;
      font-size: 17.1px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      object-fit: contain;
      border-radius: 40px;
      margin-left: 10px;
      background-color: #e8344e;
      padding: 16px 17px;
      @include desktop {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
      }

      img {
        margin-right: 10px;
      }

      &.kakao {
        color: black;
        background-color: #fae301;

        img {
          height: 22px;
          position: relative;
          bottom: 2px;
        }
      }
    }
  }
}

.korea-invest-container {
  .header {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    margin-bottom: 14px;
    @include tablet-desktop {
      gap: 10px;
      margin-bottom: 20px;
    }

    .korea-invest {
      font-family: NotoSans;
      font-size: 12px;
      font-weight: bold;
      line-height: 2.33;
      letter-spacing: -0.36px;
      text-align: center;
      color: #3492e8;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 2px 14px;
      border-radius: 100px;
      background-color: #ebf7ff;

      @include tablet-desktop {
        font-size: 14px;
      }
    }

    svg {
      margin-top: 3px;
      width: 24px;
      height: 24px;
      object-fit: contain;
      @include tablet-desktop {
        margin-top: 5px;
      }
    }

    .sparta {
      font-family: NotoSans;
      font-size: 12px;
      font-weight: bold;
      line-height: 2.33;
      letter-spacing: -0.36px;
      text-align: center;
      color: #e8344e;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 2px 14px;
      border-radius: 100px;
      background-color: #ffe7e7;
      @include tablet-desktop {
        font-size: 14px;
      }
    }
  }

  .title {
    font-family: NotoSans;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.27;
    letter-spacing: -0.66px;
    text-align: center;
    color: #000;
    margin-bottom: 30px;
    @include tablet-desktop {
      margin-bottom: 40px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 20px;
    border-radius: 24px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    @include tablet-desktop {
      padding: 30px;
    }

    .content-title {
      font-family: NotoSans;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.56;
      letter-spacing: -0.54px;
      text-align: center;
      color: #000000;
      @include tablet-desktop {
        font-size: 20px;
      }
    }

    .content-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 14px;

      .content-row {
        font-family: NotoSans;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: -0.48px;
        text-align: center;
        color: #000000;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
        padding: 20px 49px;
        border-radius: 24px;
        background-color: #f4f5f6;

        @include tablet-desktop {
          width: 445px;
          height: 72px;
          gap: 4px;
          padding: 24px 30px;
          border-radius: 24px;
          background-color: #f4f5f6;
          font-size: 16px;
          font-weight: 600;
          line-height: 1.5;
        }
      }
    }
  }

  .btn {
    margin: 30px auto;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14.3px 22.8px;
    border-radius: 40px;
    background-color: #e8344e;
    font-family: NotoSansKR;
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    color: #fff;
    @include tablet-desktop {
      width: 443px;
      font-size: 18px;
      padding: 22px;
    }
  }
}

.mobile {
  display: block;
  @include tablet-desktop {
    display: none;
  }
}
