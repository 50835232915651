@import '../../../../_styles/utils';

.B2bNPSTextWrapper {
  max-width: 580px;
  height: 200px;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  widht: 100%;
  margin-bottom: 50px;

}

.B2bNPSTextNumber {
  width: 40px;
  height: 25px;
  background-color: #FFE3E3;
  margin-left: auto;
  margin-right: auto;
  color: #E8344E;
  vertical-align: center;
  border-radius: 10px;
  margin-bottom: 15px;
}

.B2bNPSTextQuestion {
  font-family: Pretendard;
  font-size: 20px;
  font-weight: normal;
  padding-bottom: 16px;

}

.B2bNPSTextButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;


}

.B2bNPSTextArea {
  width: 100%;
  height: 100px;
  border: 2px solid #CED4DA;
  border-radius: 12px;
  &::placeholder {
    color: #ADB5BD;
    padding-left: 4px;
  }
}

.B2bNPSTextLine {
  height: 2px;
  width: 100%;
  content: " ";
  background: #CED4DA;
  position: relative;
  bottom: 4px;
}
