@import '../../../../_styles/utils.scss';


.dotted {
  margin-top: 16px;
  width: 100%;
  height: 2px;
  background-image: linear-gradient(to right, black 50%, rgba(255, 255, 255, 0) 0%);
  background-size: 10px 2px;
  background-repeat: repeat-x;
  @include desktop {
    margin-top: 32px;
  }
}

.course-reco-wrap {
  width: 100%;
  box-sizing: border-box;
  margin-top: 16px;
  @include desktop {
    margin-top: 32px;
  }

  .course-reco-title {
    font-family: GmarketSans;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    white-space: pre-wrap;
    @include desktop {
      font-size: 24px;
      line-height: 1;
      text-align: center;
      white-space: normal;
    }
  }

  .course-reco-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 16px;
    @include desktop {
      flex-direction: row;
      margin-top: 32px;
    }

    .course-reco-card:nth-child(2) {
      margin-top: 16px;
      @include desktop {
        margin-top: 0;
      }
    }

    .course-reco-card {
      box-sizing: border-box;
      width: 100%;
      align-items: center;
      border-radius: 24px;
      padding: 29px 0;
      box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.15);
      display: flex;
      background: white;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      @include desktop {
        width: 541px;
        padding: 29px 27px 29px 16px;

      }

      .course-reco-main {
        width: 84px;
        height: 84px;
        border-radius: 45.4px;
        background: #f1f3f5;
        color: #000;

        p {
          font-weight: bold;
          font-family: $spoqa-hans-neo;
          text-align: center;
          vertical-align: middle;
          line-height: 84px;
          font-size: 14px;
          @include desktop {
            font-size: 18px;
          }

          b {
            font-size: 22px;
            font-weight: bold;
            font-family: $spoqa-hans-neo;

            @include desktop {
              font-size: 24px;
            }
          }
        }

      }

      .course-reco-desc {
        width: 50%;
        margin-left: 24px;
        line-height: 1.6;
        font-weight: 500;
        letter-spacing: -1px;
        font-size: 14px;
        color: #000;
        word-break: keep-all;
        font-family: $spoqa-hans-neo;
        @include desktop {
          font-size: 16px;
          width: calc(100% - 282px);
        }
      }

      .course-reco-cta {
        width: 100%;
        text-align: center;
        margin-top: 16px;
        @include desktop {
          width: 174px;
          margin: auto;
          text-align: left;
        }

        button {
          width: 149px;
          height: 48px;
          box-sizing: border-box;
          border: 2px solid #000;
          border-radius: 100px;
          background: white;
          color: #000;
          font-weight: bold;
          margin-bottom: 16px;
          cursor: pointer;
          font-family: $spoqa-hans-neo;
          font-size: 14px;
          @include desktop {
            width: 100%;
            font-size: 18px;
            height: 58px;
          }
        }

        .course-reco-sub-cta {
          font-size: 14px;
          font-weight: bold;
          cursor: pointer;
          font-family: $spoqa-hans-neo;
          color: #000;
        }
      }
    }
  }
}
