@import '../../_styles/utils.scss';

.lectureWrapper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: #CCC;

  .h_iframe {
    position: relative;
    padding-top: 56%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.ctaButtonContainer {
  height: 32px;
  margin-top: 12px;

  button {
    font-size: 11px;
    color: white;
    line-height: 11px;
    letter-spacing: -0.55px;
    text-align: left;
    padding: 9px 6px 8px 7px;
    background: #1a1b1c;
    border-radius: 5px;
    border: none;
    margin-right: 20px;
  }

  button:hover {
    background: #333333;
    border: none;
    color: white;
  }

}

.ctaButtonContainer + .buttonContainer {
  height: 70px;
}

.buttonContainer {
  height: 75px;
  //background-color: #ffffff;
  border-radius: 0px 0px 10px 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @include adesktop {
    flex-direction: row;
    height: 80px;
    padding: 0px 0px;
  }


  .communityButton {
    color: black;
    margin: 0px 8px 0px 20px;
    border: 2px solid $hover-color;
    font-weight: 600;
    font-size: 15px;
    width: 93px;
    height: 36px;
    border-radius: 5px;

    @include mobile {
      display: none;
      margin-left: 50px;
    }
  }

  .communityButton:hover {
    background-color: $hover-color;
    border: 2px solid $hover-color;
    color: white;
    font-weight: 600;
    font-size: 15px;
    width: 93px;
    height: 36px;
    border-radius: 5px;
  }

  .leftButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    @include adesktop {
      width: 100%;
      justify-content: flex-start;
    }

    .lectureButton {
      color: black;
      margin: 0px 8px 0px 20px;
      border: 2px solid $hover-color;
      font-weight: 600;
      font-size: 15px;
      width: 111px;
      height: 36px;
      border-radius: 5px;
    }

    .lectureButton:hover {
      background-color: $hover-color;
      border: 2px solid $hover-color;
      color: white;
      font-weight: 600;
      font-size: 15px;
      width: 111px;
      height: 36px;
      //padding: 0px 30px;
      //height: 45px;
      border-radius: 5px;
    }

    .treasureboxWrapper {
      position: relative;

      img {
        width: 44px;
        height: 44px;
      }

      .numberindicator {
        position: absolute;

        top: -5px;
        right: -5px;

        width: 20px;
        height: 20px;
        padding: 3px;
        background-color: $primary-color;
        border-radius: 7px;
        text-align: center;
        vertical-align: middle;

        object-fit: contain;
        font-size: 13.4px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.98px;
        color: #ffffff;
      }

      @media all and (max-width: 720px) {

      }
    }
  }

  .buttonGroupTop {
    @include mobile {
      width: 100%;
      padding: 0 20px;
    }
    @include tablet {
      width: 71%;
      padding: 0 20px;
    }
  }


  .qnaButton {
    background-color: #13C2C2;
    border-color: #13C2C2;
    color: white;
    //margin: 20px 20px 0px 0px;
    margin: 0px 20px 0px 10px;
    //border: 2px solid #38D9A9;
    font-weight: 600;
    font-size: 15px;
    //width: 122px;
    height: 36px;
    border-radius: 5px;

    @include adesktop {
      margin: 0px 0px 0px -1px;
    }
  }

  .qnaButton:hover {
    border-color: $hover-color-deeper;
    background-color: $hover-color-deeper;
  }


  .qnaButton-inactive {
    background-color: gray;
    color: white;
    margin: 0px;
    font-size: 15px;
    width: 111px;
    height: 36px;
    font-weight: 600;
    border-radius: 5px;
  }

  .materialContainer {
    cursor: pointer;
    float: left;

    font-weight: 600;
    color: black;
    font-size: 15px;
    padding-top: 8px;

    display: flex;
    align-items: center;

    @include mobile {
      display: none;
    }

    img.outlinkImage {
      width: 9.9px;
      height: 9.9px;
    }
  }
}

.modalBodyContainer {
  width: 100%;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .titleContainer {
    text-align: center;

    h4 {
      @include adesktop {
        font-size: 16px;
      }
    }

    p {
      @include adesktop {
        font-size: 13px;
      }
    }
  }

  .myinfoContainer {
    border: 1px solid #CED4DA;
    border-radius: 10px;
    padding: 15px 20px;

    width: 300px;
    margin: 10px auto;

    font-size: 20px;
    font-weight: 500;
    color: black;

    @include adesktop {
      padding: 5px 10px;
      font-size: 13px;
    }

    ul {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .labelContainer {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          width: 40px;
          height: 40px;
        }

        span {
          padding: 0px;
          margin: 0px;
        }
      }

      .numberContainer {
        border-radius: 10px;
        background-color: #F1F3F5;
        padding: 5px 15px;

      }
    }
  }

  .treasureBoxContainer {
    margin-top: 75px;
    @include adesktop {
      margin-top: 32px;
    }

    img {
      width: 200px;
      height: 200px;
      @include adesktop {
        width: 100px;
        height: 100px;
      }
    }
  }

  .treasureBoxContainerOpening {
    //margin-top: 20px;
    img {
      width: 400px;
      height: 276px;
      @include adesktop {
        width: 200px;
        height: 130px;
      }
    }
  }

  .emtpyboxContainer {
    img {
      margin-top: 150px;
      width: 200px;
      height: 130px;
      @include adesktop {
        margin-top: 40px;
        width: 100px;
        height: 75px;
      }
    }
  }

  .addedPointContainer {
    position: fixed;
    top: calc(50% + 10px);
    font-size: 32px;
    color: black;
    font-weight: 500;
    @include adesktop {
      top: calc(50% - 90px);
      font-size: 18px;
    }
  }


  .buttonContainer {
    text-align: center;
    background-color: rgb(249, 251, 250);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 67%;

    @include adesktop {
      height: auto;
      padding-top: 15px;
      width: 78%;
    }

    .endButtonContainer {
      margin: 0 auto;
      width: 150px;

      button {
        margin-top: 30px;
        color: black;
        font-weight: 500;
        background-color: white;
        border: 2px solid #13C2C2;
        border-radius: 5px;
        width: 150px;
        font-size: 18px;
        height: 45px;
        @include adesktop {
          font-size: 13px;
          width: auto;
          height: auto;
          margin-top: 0px;
        }
      }

      button:hover {
        background-color: $hover-color;
        color: white;
      }
    }

    .continueButtonContainer {
      margin: 0 auto;
      button {
        margin-top: 30px;
        color: white;
        font-weight: 500;
        background-color: $hover-color;
        border: 2px solid $hover-color;
        border-radius: 5px;
        width: 150px;
        font-size: 18px;
        height: 45px;
        @include adesktop {
          font-size: 13px;
          width: auto;
          height: auto;
          margin-top: 0px;
        }
      }

      button:hover {
        background-color: $hover-color-deeper;
        border-color: $hover-color-deeper;
      }
    }


  }

  .buttonContainerInactive {
    text-align: center;


    button {
      margin-top: 30px;
      color: white;
      font-weight: 500;
      background-color: lightgray;
      //border: 2px solid #13C2C2;
      border-radius: 5px;
      width: 150px;
      height: 48px;
      font-size: 18px;
      @include adesktop {
        font-size: 13px;
        width: auto;
        height: auto;
        margin-top: 0px;
      }
    }
  }
}


.modalRatingBodyContainer {
  width: 90%;
  margin: auto;

  .modalScoreContainer {
    margin-top: 10px;

    h4 {
      @include adesktop {
        font-size: 18px;
      }
    }

    .individualScoreContainer {
      margin: 10px auto;
      width: 260px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: row;

      font-size: 16px;
      font-weight: 500;

      img {
        width: 30px;
        height: 30px;
      }
    }
  }

  .modalOpinionContainer {
    margin-top: 20px;

    h4 {
      @include adesktop {
        font-size: 18px;
      }
    }

    p {
      text-align: center;
      width: 340px;
    }

    textarea {
      @include adesktop {
        font-size: 14px;
      }
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: center;

    @include adesktop {
      height: 75px;
      padding: 0px;
    }


    button {
      margin-top: 17px;
      color: black;
      font-weight: 500;
      //background-color: #13C2C2;
      border: 2px solid #13C2C2;
      border-radius: 5px;
      width: 200px;
      height: 45px;
      font-size: 18px;
      @include adesktop {
        font-size: 16px;
      }
    }

    button:hover {
      background-color: $hover-color;
    }
  }
}

.flexColumnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.reviewDonePointContainer {
  position: absolute;
  top: 29px;
  right: 35px;
  padding: 5px 10px;
  background-color: #E9ECEF;
  border-radius: 8px;
  border: 2px solid #E9ECEF;
  font-size: 13px;
  color: black;
  @include mobile {
    display: none;
  }
}

.footer {
  text-align: center;
  @include adesktop {
    margin-top: 6px;
  }

  span {
    border-bottom: 1px dotted;
  }

  span:hover {
    cursor: pointer;
    border-bottom: 1px solid;
  }
}

h4 {
  font-size: 1.5rem;
}