@import '../../../../_styles/utils';


.AttendanceMarkContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 80px;

  img{
    position: absolute;
  }

  .date {
    transform: translateY(40px);
    position: absolute;
    font-family: GmarketSans;
    font-size: 12px;
    font-weight: bold;
    color: #000;

  }
}