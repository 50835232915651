@import '../../../../_styles/utils';

.main-copy {
  font-size: 18px;
  line-height: 1.4;
  color: #000;
  font-weight: normal;
  margin: 0 auto 18px auto;
  white-space: pre-line;
  max-width: 89%;
  width: 100%;
  @include desktop {
    white-space: normal;
    font-size: 28px;
    line-height: 1;
    margin-bottom: 32px;
    max-width: 100%;
  }
  font-family: GmarketSans;
  span {
    font-family: GmarketSans;
  }
  img {
    width: 18px;
    margin-left: 4px;
    @include desktop {
      width: 24px;
    }
  }
}