@import "../../../_styles/utils";

.nps-page {

  max-width: 100%;
  margin: auto;
  background: #e9ecef;
  height: 100%;
  overflow: auto;
  padding-bottom: 200px;

  &.pirates {
    background: #141617;
  }

  &.__mainNPS {
    padding-bottom: 0;
  }

  &__mainNPS__background {
    display: flex;
    flex-direction: column;
    background-color: #F6F9FA;
    padding-bottom: 84px;
    @include desktop {
      padding-bottom: unset;
      height: 100%;
    }

    &.pirates {
      background-color: #141617;
    }
  }

  &__nav {
    max-width: calc((92% - 80px));
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 77px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    @include lectureNav {
      justify-content: space-around;
      max-width: 100%;
    }

    &__bg {
      width: 100vw;
      height: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;

      &.pirates {
        background-color: #222425;
      }
    }
  }

  &__content {
    padding-top: 20px;
    max-width: 850px;
    width: 92%;
    margin: auto;
    @include desktop {
      width: 100%;
      padding-top: 57px;
    }

    &.__mainNPS {
      padding-top: 0;
      width: 100%;
      height: auto;
      @include desktop {
        width: 588px;
        padding-top: 60px;
      }
    }

    &__header {
      max-width: 94%;
      margin: auto;
      white-space: pre-wrap;
      @include desktop {
        max-width: unset;
        margin: unset;
        &__title {
          white-space: nowrap;

          br {
            display: none;
          }
        }
      }

      &__title {
        font-family: GmarketSans;
        font-size: 28px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: -1.68px;
        text-align: left;
        color: $black;
        word-break: keep-all;

        &.pirates {
          color: #EEF3F6;
        }
      }

      &__subtitle {
        margin-top: 17px;
        font-family: $spoqa-hans-neo;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: -0.48px;
        text-align: left;
        color: $black;
        word-break: keep-all;

        &.pirates {
          color: #C7D2D8;
        }
      }
    }

    &__hr {
      width: 100%;
      height: 2px;
      border-bottom: dashed 2px $black;
      margin: 36px 0;
      display: none;
      @include desktop {
        display: block;
      }

      &.pirates {
        border-bottom: 2px dashed #9DA7AE;
      }
    }

    &__body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 32px;
      margin-top: 35px;

      @include desktop {
        display: grid;
        gap: unset;
        grid-template-columns: 78px 1fr;
        grid-row-gap: 24px;
        margin: 0;
      }

      &.__mainNPS {
        display: flex;
        gap: 0;
        margin-top: 35px;
      }

      &__num {
        width: 30px;
        @include desktop {
          width: 39px;
        }
      }


      &__question {
        font-family: $spoqa-hans-neo;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.8px;
        text-align: center;
        color: $black;
        max-width: 92%;
        width: 100%;
        word-break: keep-all;
        @include desktop {
          text-align: left;
        }

        &.pirates {
          color: #EEF3F6;
        }

        &.--isRadioGroup {
          text-align: left;

          .nps-page__content__body__question__title {
            text-align: center;
          }

          .ant-radio-group {
            width: 300px;
            margin: auto;
          }

          @include desktop {
            text-align: left;
            .nps-page__content__body__question__title {
              text-align: left;
            }
          }
        }

        &__title {
          margin-top: -16px;
          margin-bottom: 16px;
          @include desktop {
            margin-top: 13px;
          }

          &.trial {
            font-family: "Pretendard";
            color: #505254;
            font-weight: 700;
            line-height: 1.3;
            white-space: pre-wrap;
            @include mobile {
              font-size: 24px;
            }
            @include desktop {
              white-space: nowrap;
              br {
                display: none;
              }
            }
          }

          &__sub {
            &.trial {
              font-family: 'Pretendard';
              font-weight: 500;
              font-size: 15px;
              line-height: 150%;
              color: #5F666B;
              margin: -10px 0 20px;
              @include desktop {
                br {
                  display: none;
                }
              }
            }
          }
        }

        &__textarea {
          width: 100%;
          border-radius: 8px;
          border: none;
          padding: 16px 24px;
          resize: none;
          height: 99px;
          -webkit-appearance: none;
          outline: none;

          &.pirates {
            background: #2B2D2E;
            color: #EEF3F6;

            &::placeholder {
              color: #81898F;
            }
          }
        }

        &__rcmd {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 50px;

          &__span {
            border-bottom: 1px dotted grey;
            margin-left: 5px;

            @include adesktop {
              margin-top: 10px;
            }

            &:hover {
              cursor: pointer;
              border-bottom: 1px solid grey;
            }
          }
        }
      }
    }
  }
}

.nps-page__content__body__question__rcmd__wrapper {
  background: $white;
  padding: 14px 20px;
  border-radius: 8px;

  &.pirates {
    background: #222425;
  }
}

.course_rcmd__question {
  margin-bottom: 8px;
  width: 100%;
  border-radius: 8px;
  border: solid 1px #eaebed;
  padding: 16px 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  position: relative;

  &.pirates {
    border: solid 1px #81898F;
  }

  &__icon {
    margin-right: 10px;

    path {
      fill: #eaebed;
    }

    &.pirates {
      path {
        fill: #5F666B;
      }

      &.--selected,
      &:hover {
        path {
          fill: #EEF3F6;
        }
      }
    }
  }

  &__desc {
    font-size: 15px;
    font-weight: 500;
    color: #505254;
  }

  &__tooltip {
    &__wrapper {
      display: none;

      &.--active {
        display: block;
        width: fit-content;
        height: fit-content;
        position: absolute;
        top: -26px;
        right: -24px;
        @include desktop {
          top: -30px;
          right: -30px;
        }
      }
    }

    width: 126px;
    height: 40px;
    position: relative;

    @include desktop {
      width: 147px;
      height: 45px;
    }

    img {
      width: 100%;
      height: 100%;
    }

    &__text {
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      font-family: 'Pretendard';
      font-weight: 600;
      font-size: 12px;
      line-height: 130%;
      color: #505254;
      white-space: nowrap;
      width: fit-content;
      @include desktop {
        font-size: 14px;
      }
    }
  }

  &.--selected,
  &:hover {
    background: $primary-color;

    &.pirates {
      border: solid 1px rgba(0, 0, 0, 0);
    }

    .course_rcmd__question__icon {
      path {
        fill: #eaebed;
      }

      path {
        fill: $white;
      }
    }

    .course_rcmd__desc {
      color: $white;

      &.pirates {
        color: #EEF3F6;
      }
    }
  }
}

.course_rcmd__desc {
  &.pirates {
    color: #EEF3F6 !important;
  }

  &.trial {
    font-family: 'Pretendard';
    font-weight: 500;
    color: #505254;
    @include mobile {
      font-size: 13px;
      line-height: 16px;
      text-align: left;
    }

    &:hover {
      color: white;
    }
  }
}

.satisfaction-container {
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
}