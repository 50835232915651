@import '../../../_styles/utils';

.topContainer{
  width: 100%;
  background: linear-gradient(#FFFFFF, #FFE7E7);
  display: flex;
  justify-content: center;
  text-align: center;

  .topWrapper{
    margin-top: 60px;
    margin-bottom: 60px;

    @include desktop{
      margin-top: 100px;
      margin-bottom: 120px;
    }

    .topHeader{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap:40px;
      margin-bottom: 49px;

      @include desktop{
        margin-bottom: 78px;
      }

      .firstImg{
        width: 111px;
      }
      .headerTitle{
        font-size: 16px;
        font-family: Pretendard;
        color: #505254;
        font-weight: 500;

        @include desktop{
          font-size: 22px;
        }

        strong{
          font-weight: bold;
        }

        h2{
          font-size: 24px;
          font-weight: bold;

          @include desktop{
            font-size: 34px;
          }
        }
      }

      .headerFooter{
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        color: #1c1d1e;
        background-color: rgba(250, 130, 127, 0.1);
        padding: 14px 20px;
        border-radius: 10px;
        min-width: 290px;

        strong{
              color: #E45A5A;
        }

        @include desktop{
          font-size: 20px;
        }
      }
    }

    .topContents{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 38px;

      @include desktop{
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
      }

      .classCard{
        width: 320px;
        height: 408px;
        background-color: white;
        padding: 30px;
        text-align: left;
        font-family: 'Pretendard';
        font-style: normal;
        border:none;
        box-shadow: 20px 20px 30px 0 rgba(204, 124, 124, 0.1);
        border-radius: 10px;
        position: relative;
        //display: flex;
        //flex-direction: column;
        //align-items: center;

        @include desktop{
          width: 380px;
          height: 481.5px;
          padding: 40px;
        }

        &:first-child {
          border: solid 1px #fa827f;
          color: #e45a5a;
        }

        &__header{
          width: 72px;
          height: 30px;
          position: absolute;
          top:-15px;
          left: 40%;
          background-color: white;
          border: solid 1px #dbdde0;
          border-radius: 100px;
          //padding: 6px 24px;
          font-weight: 600;
          font-size: 18px;
          line-height: 25px;
          font-family: Pretendard;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          &.colored{
            border: solid 1px #fa827f;
          }
        }

        &__title {
          font-size: 18px;
          font-weight: bold;
          line-height: 1.5;
          color: #1C1D1E;
          width: 301px;
          font-family: Pretendard;

          @include desktop {
            font-size: 23px;
          }
        }
        &__tags{
          font-weight: 500;
          font-size: 14px;
          color: #929598;
          margin-top: 10px;
        }
        &__img{
          width: 262px;
          position: relative;
          top: -25px;
          left: 20px;
          @include desktop{
            width: 309px;
          }
        }

        .btn_wrapper {
          width: 100%;
          display:flex;
          justify-content: center;
          align-items: center;

          button {
            width: 300px;
            height: 48px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 10px;
            border: none;
            background-color: #e45a5a;
            font-family: Pretendard;
            font-size: 16px;
            font-weight: bold;
            color: white;
            cursor: pointer;
            @include desktop{
              font-size: 18px;
              height: 54px;
            }
          }
        }
      }
    }
    .topNotice{
      padding-top: 40px;
      font-family: Pretendard;
      font-size: 12px;
      font-weight: 500;
      color: #505254;
      @include desktop{
        font-size: 18px;
      }
    }
  }
}

.couponContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  //text-align: center;
  background-color: white;
  padding: 60px 0 60px 0;

  @include desktop{
    padding: 100px 0 120px 0;
  }

    .coupon__header{
      width: 104px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Pretendard;
      border-radius: 100px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 100%;
      text-align: center;
      color: #E45A5A;
      background: rgba(250, 130, 127, 0.1);
      margin-bottom: 26px;

      @include desktop{
        width: 153px;
        height: 50px;
        font-size: 22px;
      }
    }

    .coupon__title{
      font-family: Pretendard;
      text-align: center;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 20px;
      color: #1c1d1e;
      width: 237px;

      @include desktop{
        font-size: 34px;
        width: 100%;
      }

      strong{
        color: #E45A5A;
      }
    }
    .coupon__subtitle{
      font-family: Pretendard;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      line-height: 1;
      color: #505254;
      padding-bottom: 32px;
      @include desktop{
        font-size: 22px;
        padding-bottom: 60px;
      }
    }
  .coupon__img{
    padding-bottom: 40px;
    max-width: 77%;
  }
  .coupon__footer{
    font-family: Pretendard;
    font-size: 12px;
    line-height: 1.33;
    color: #505254;
    margin-bottom: 30px;
    @include desktop{
      font-size: 18px;
      margin-bottom: 70px;
    }
  }
  button{
    min-width: 320px;
    height: 56px;
    border-radius: 10px;
    border: none;
    background-color: #1c1d1e;
    font-family: NotoSansKR;
    font-size: 16px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    @include desktop{
      font-size: 24px;
      min-width: 268px;
      height: 74px;
    }
  }
}

.qaContainer{
  width: 100%;
  background-color: #FFF4F4;
  padding: 60px 0 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @include desktop{
    padding: 100px 0 120px 0;
  }

  h1{
    font-family: Pretendard;
    font-size: 24px;
    font-weight: bold;
    color: #1c1d1e;
    padding-top:30px;
    padding-bottom: 14px;
    width: 245px;

    @include desktop{
      font-size: 34px;
      width: 100%;
      padding-top: 40px;
      padding-bottom: 20px;
    }

  }
  h2{
    font-family: Pretendard;
    font-size: 16px;
    width: 255px;
    font-weight: 500;
    color: #505254;
    padding-bottom: 40px;
     @include desktop{
      font-size: 22px;
      width: 100%;
      padding-bottom: 70px;
    }
  }
  &__footer{
    padding: 14px 20px;
    border-radius: 10px;
    background-color: rgba(250, 130, 127, 0.1);
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 600;
    color: #e45a5a;
    display: flex;
    align-items: center;
    @include desktop{
      font-size: 20px;
    }
  }
  .triangleDown{
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-top: 12px solid rgba(250, 130, 127, 0.1);
  }

  button{
    width: 320px;
    height: 56px;
    margin-top: 12px;
    font-size: 16px;
    border-radius: 10px;
    border:none;
    background-color: #e45a5a;
    font-family: NotoSansKR;
    font-weight: bold;
    color: white;
    cursor: pointer;

    @include desktop{
      width: 345px;
      height: 74px;
      font-size: 24px;
    }
  }
}