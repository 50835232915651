@import "../../_styles/utils.scss";

.birthInfo {
  &__container {
  }

  &__label {
  }

  &Input {

    &__item {
      position: relative;

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type=number] {
        -moz-appearance: textfield;
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &__year {
      width: 148px;
      border-radius: 8px;
      height: 53px;
      outline: none;
      border: 1px solid $gray150;
      padding: 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.5;
      color: $gray600;
      position: relative;

      &::placeholder {
        color: $gray200
      }

      &:focus {
        border-color: $gray300;
      }

      & + span::after {
        content: "년";
        display: block;
        color: $gray600;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translate(-50%, -50%);
      }

    }

    &__month {
      width: 82px;
      border-radius: 8px;
      height: 53px;
      outline: none;
      border: 1px solid $gray150;
      padding: 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.5;
      color: $gray600;

      @include desktop {
        width: 97px;
      }

      &::placeholder {
        color: $gray200
      }

      &:focus {
        border-color: $gray300;
      }

      & + span::after {
        content: "월";
        display: block;
        color: $gray600;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translate(-50%, -50%);
      }
    }

    &__date {
      width: 82px;
      border-radius: 8px;
      height: 53px;
      outline: none;
      border: 1px solid $gray150;
      padding: 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.5;
      color: $gray600;

      @include desktop {
        width: 97px;
      }

      &::placeholder {
        color: $gray200
      }

      &:focus {
        border-color: $gray300;
      }

      & + span::after {
        content: "일";
        display: block;
        color: $gray600;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__notice {
    font-weight: 400;
    font-size: 13px;
    line-height: 1.5;
    margin-bottom: 0;
    margin-top: 8px;
    color: $gray400;
    &.--warning {
      color: $primary-color;
    }
  }
}
