@import "../../_styles/utils";

.accent {
  color: #e8344e;
}

.comento {
  font-size: 13px;
}

.contentWrapper {
  padding: 10px 0px 0px;
  background-color: white;

  .formWrapper {
    .mainContainer {
      width: 100%;
      margin: auto;
      text-align: center;

      .contentLimit {
        max-width: 1230px;
        width: 100%;
        margin: auto;
      }

      .prequestion-header {
        background-color: #ffedf1;
        padding: 27px 5%;

        &.--bottom_padding_zero {
          padding-bottom: 0;
        }

        h3 {
          margin: 0 10px 18px;
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: -1px;
          text-align: center;
          color: #000000;
          @include desktop {
            font-size: 35px;
            line-height: 1;
            letter-spacing: -1.75px;
            margin-bottom: 43px;
            margin-top: 73px;
          }
        }

        .preq-desc {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: -0.7px;
          text-align: center;
          color: #000000;
          margin-bottom: 10px;
          @include desktop {
            font-size: 18px;
            line-height: 1.6;
            letter-spacing: -0.9px;
          }
        }

        img.ic-preq {
          width: 159px;
          height: 141.5px;
          object-fit: contain;
          margin-top: 18px;
          @include desktop {
            width: 306px;
            height: 272.2px;
            margin: 30px 122px 0 121px;
            object-fit: contain;
          }
        }

        .desktopContainer {
          &__weekzero_wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            &__header {
              font-family: NotoSansCJKKR;
              font-size: 20px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: -0.84px;
              text-align: center;
              color: #000;
              white-space: pre-line;
              @include desktop {
                font-size: 28px;
                white-space: unset;
              }
            }

            &__content {
              width: 90%;
              padding: 16px 32px 16px;
              background: #fff;
              border-radius: 24px;
              box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.15);
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              @include desktop {
                width: 750px;
                padding: 24px 72px 16px;
              }

              &__header {
                padding: 8px 40px;
                border-radius: 40px;
                background: #000;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-family: NotoSansCJKKR;
                font-size: 17px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.3;
                letter-spacing: -0.6px;
                text-align: center;
                color: #ffffff;
                margin-bottom: 16px;
                img {
                  margin-right: 8px;
                  @include desktop {
                    margin-right: 0;
                  }
                }
                @include desktop {
                  font-size: 20px;
                }
              }

              &__desc {
                margin-bottom: 16px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                &__row {
                  display: flex;
                  flex-direction: row;
                }
                &__header {
                  font-family: NotoSansCJKKR;
                  font-size: 18px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.67;
                  letter-spacing: -0.54px;
                  color: #000;
                }

                &__detail {
                  margin-left: 8px;
                  font-family: NotoSansCJKKR;
                  font-size: 18px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.67;
                  letter-spacing: -0.54px;
                  color: #000;
                  text-align: left;

                  b {
                    color: #e8344e;
                  }
                }
              }

              &__bar {
                width: 40px;
                height: 4px;
                border-radius: 24px;
                background-color: #d4d4d4;
                margin-bottom: 16px;
              }

              &__user {
                font-family: NotoSansCJKKR;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: -0.48px;
                text-align: center;
                color: #000000;
              }
            }

            &__notice {
              font-family: NotoSansCJKKR;
              font-size: 20px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: -0.6px;
              text-align: center;
              color: #000;
              margin-top: 48px;
              margin-bottom: 16px;
              span {
                text-decoration: underline;
              }
            }

            &__sub {
              font-family: NotoSansCJKKR;
              font-size: 18px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.67;
              letter-spacing: -0.54px;
              text-align: center;
              color: #000;
              margin-bottom: 16px;
            }

            &__img {
              width: 215px;
            }
          }
        }
      }

      .pre-info {
        //overflow: hidden;

        .first-half {
          margin-top: 32px;
          padding: 0 5%;
          background-color: white;
          @include desktop {
            margin-top: 80px;
          }
          .ic-rcmd.weekzero {
            width: 180px;
          }
        }
        .question-wrapper {
          background-color: #f4f5f6;
          @include desktop {
            width: 500%;
            margin-left: -200%;
          }
        }
        .question-wrapper-white {
          background-color: #fff;
          @include desktop {
            width: 500%;
            margin-left: -200%;
          }
        }

        .last-half {
          padding: 38px 5%;
          background-color: #f4f5f6;
          @include desktop {
            width: 500%;
            margin-left: -200%;
            padding: 40px 205%;
          }
        }

        .desktopContainer {
          @include desktop {
            width: 590px;
            margin: auto;
          }
        }

        .q-box {
          margin-bottom: 51px;
          @include desktop {
            margin-bottom: 60px;
          }

          &:last-child {
            margin-bottom: 0;
          }

          &.--select {
            .question {
              margin-bottom: 20px;
            }

            textarea {
              margin-top: 20px;
            }
          }

          .question {
            text-align: left;
            margin-bottom: 0.5rem;
            margin-left: 2px;
            font-weight: 500;
            font-size: 1rem;

            .qnumber {
              width: 58px;
              margin: auto;
              padding: 5.5px 15px;
              border-radius: 20px;
              background-color: #ffe3e3;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.6;
              letter-spacing: -0.6px;
              text-align: center;
              color: #e8344e;
              @include desktop {
                width: 76px;
                font-size: 18px;
                line-height: 1.6;
                letter-spacing: -0.9px;
              }
            }
            .--center {
              text-align: center;
            }
            .qtitle {
              margin-top: 13px;
              font-size: 15px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.6;
              letter-spacing: -1.05px;
              text-align: center;
              color: #000000;
              @include desktop {
                margin-top: 18px;
                margin-bottom: 15px;
                font-size: 22px;
                line-height: 1.6;
                letter-spacing: -1.2px;
              }

              small.small {
                font-size: 14px;
                font-weight: normal;
              }
            }
          }

          input[type="radio"],
          input[type="checkbox"] {
            -moz-appearance: none;
            -webkit-appearance: none;
            -ms-appearance: none;
            appearance: none;
            display: block;
            float: left;
            margin-right: -2rem;
            opacity: 0;
            width: 1rem;
            z-index: -1;
          }

          input[type="radio"] + label,
          input[type="checkbox"] + label {
            text-decoration: none;
            color: #0a0a0c;
            cursor: pointer;
            display: inline-block;
            font-size: 1rem;
            font-weight: 300;
            padding-left: 0.4rem;
            padding-right: 0.75rem;
            position: relative;
          }

          textarea,
          select {
            resize: vertical;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background: rgba(144, 144, 144, 0.075);
            border-radius: 4px;
            border: solid 1px rgba(144, 144, 144, 0.25);
            color: inherit;
            display: block;
            outline: 0;
            padding: 0 1rem;
            text-decoration: none;
            width: 100%;
            font-weight: 400;
            line-height: 1.65;
          }

          select {
            height: 2.75rem;
          }

          textarea {
            padding: 0.75rem 1rem;
            height: 100px;
            resize: none;
            background-color: white;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: -0.84px;
            text-align: left;
            color: black;
            line-height: 1.71;

            &::placeholder {
              color: #adb5bd;
            }
          }

          input[type="text"] {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: -0.84px;
            text-align: left;
            color: black;
            line-height: 1.71;
            padding: 10px 10px 11px 9px;
            border-radius: 4.3px;
            border: solid 0.9px #ced4da;
            background-color: #ffffff;

            &::placeholder {
              color: #adb5bd;
            }
          }

          div.code-question {
            text-align: left;
            margin-bottom: 10px;
          }

          div.question-radio {
            margin-top: 1rem;
          }

          select + textarea {
            margin-top: 10px;
          }
        }

        .thx {
        }

        .rcmd {
          .ic-rcmd {
            width: 262.7px;
            height: 139.9px;
            margin: 11px auto 30.1px auto;
            object-fit: contain;
            @include desktop {
              width: 429.1px;
              height: 228.5px;
              margin: 11px auto 38.5px;
              object-fit: contain;
            }
          }
        }

        .desc {
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: -0.7px;
          text-align: center;
          color: #000000;
          margin-bottom: 21px;
          @include desktop {
            font-size: 18px;
            line-height: 1.6;
            letter-spacing: -0.9px;
          }

          &:last-child {
            margin-bottom: 11px;
          }

          &:first-child {
            margin-top: 31px;
          }
        }
      }
    }

    button {
      display: block;
      width: 90%;
      font-stretch: normal;
      font-style: normal;
      padding: 17.5px 0;
      border-radius: 6px;
      margin: 20px auto;
      font-size: 16px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      height: auto;
      @include desktop {
        font-size: 20px;
      }

      &.go_roadmap {
        border-radius: 50px;
        border: none;
        outline: none;
        background: #000;
        color: white;
      }
    }
  }
}
