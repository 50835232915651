@import '../../_styles/utils.scss';

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.active {
  display: block !important;
}

.modal-background {
  overflow-y: hidden;
  display: none;
  position: fixed;
  z-index: 2;
  margin: auto;
  background-color: white;
  width: 95%;
  left: 2.5%;
  top: calc(50% - 240px);
  border-radius: 10px;
  border: 2px solid #13C2C2;
  padding: 20px;
  height: 480px;

  @include desktop {
    width: 550px;
    height: 555px;
    left: calc(50% - 275px);
    top: calc(50% - 340px);
  }

  @include adesktop {
    width: 98%;
    height: 555px;
    left: calc(50% - 48%);
    top: calc(50% - 340px);
  }

  .modal-content {
    //margin: 90px 0px;
    //height: 300px;
    vertical-align: center;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    .modal-body {
      width: 90%;
      margin: 0 auto;

      .modal-info {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;

        .modal-notice {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          align-items: flex-start;
          width: 100%;

          .modal-title {
            font-size: 20px;
            color: black;
          }

          .modal-likes {
            font-size: 20px;
            color: black;
            margin-top: 10px;
            //position: absolute;
            //top: 145px;
            //right: 20px;
          }
        }


        .modal-title {
          padding-top: 10px;
          text-align: center;
          font-size: 20px;
          color: black;
        }

        .modal-advantages {
          font-size: 17px;
          margin: 20px auto;
          color: black;
        }
      }

      h3, label {
        color: black;
      }

      form {
        width: 100%;
        margin: 0 auto;

        button {
          margin-top: 10px;
          text-align: center;
          color: white;
          font-weight: 500;
          background-color: #13C2C2;
          border: 1px solid #13C2C2;
          border-radius: 5px;
          width: 200px;
          height: 48px;
          font-size: 18px;
        }
      }

      .close {
        color: white;
        font-size: 28px;
        font-weight: bold;
      }

      .close:hover,
      .close:focus {
        text-decoration: none;
        cursor: pointer;
      }

      color: white;
    }
  }
}