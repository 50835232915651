@import "../../../../../../_styles/utils";

.cta_btn {
    margin: 0 auto;
    width: 95%;
    border-radius: 24px;
    text-align: center;
    padding: 10px 0px;
    font-family: $spoqa-hans-neo;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;

    &.purchased {
        background-color: #ffffff;
        color: #e8344e;
        border: solid 2px #e8344e;
    }
    &.voucher {
        background-image: linear-gradient(232deg, #ff6c7a, #e8344e);
        color: #ffffff;
    }

    &.retro_true {
        background-color: #ffffff;
        border: solid 2px #000000;
    }
    &.retro_false {
        background-color: #000000;
        color: #ffffff;
        border: 2px solid #000000;
    }
}

.certi_wrapper {
    display: flex;
    justify-content: space-between;
    font-family: $spoqa-hans-neo;
    font-weight: 500;
    margin-top: 15px;
    text-decoration: underline;

    .certi_upload_wrapper {
        display: flex;
        cursor: pointer;
        white-space: nowrap;

        .certi_upload {
            //cursor: pointer;
        }
    }

    .certi_download_wrapper {
        display: flex;
        cursor: pointer;

        .certi_download {
            //cursor: pointer;
        }
    }
    img {
        margin-left: 5px;
        width: 18.3px;
        height: 22px;
        object-fit: contain;
    }
}
