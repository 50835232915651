@import '../../../_styles/utils';

.bg {
  background-color: #E9ECEF;
  @include desktop {
    width: 100%;
    max-width: $maxWidth;
    margin: auto;
    background-color: white;
    position: relative;
  }

  .friend-banner {
    background-color: #6967ce;
    padding: 17px 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include desktop {
      margin-top: -50px;
      width: 500%;
      margin-left: -200%;
      padding: 28px 0 22px;
    }

    &__content {

      &__title {
        font-family: $spoqa-hans-neo;
        font-size: 14px;
        line-height: 1.4;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.3px;
        text-align: left;
        white-space: pre-line;
        color: $white;

        @include desktop {
          line-height: 1;
          font-size: 18px;
          white-space: nowrap;
        }
      }

      &__img {
        width: 64px;
        height: 37px;
        transform: translateY(-6px);
      }
    }
  }
}