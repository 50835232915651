
.ant-radio-inner {
    width: 24px;
    height: 24px;
    border: solid 1px black;
}

.ant-radio-inner::after {
    width: 16px;
    height: 16px;
}

.ant-radio-wrapper span {
    font-family: 'Spoqa Han Sans Neo', 'sans-serif';
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: black;
}

.ant-radio-group.nps {
    width: 300px;
    margin: auto;
    display: block;

}

@media (min-width: 1200px) {
    .ant-radio-group.nps {
        width: auto;
        display: inline-block;
    }
}