@import '../../../../_styles/utils';

.lec-nav-list-week {
  margin-bottom: 18px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__main {
      display: flex;
      align-items: center;

      &__toggle-img {
        padding-top: 3px;
        margin-right: 23px;
        cursor: pointer;

        &__img {

        }
      }

      &__title {
        padding-top: 3px;
        font-family: $spoqa-hans-neo;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.9px;
        text-align: left;
        color: #868e96;

        &.pirates {
          color: #EEF3F6;
        }
      }
    }

    &__progress {
      display: flex;
      align-items: center;

      &__text {
        padding-top: 3px;
        font-family: $spoqa-hans-neo;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: -0.96px;
        text-align: left;
        color: #868e96;

        &.pirates {
          color: #EEF3F6;
        }
      }

      &__coming_soon_text {
        color: #ff2424;
        font-size: 16px;
        font-weight: bold;
      }

      &__bg {
        margin-left: 8px;
        border-radius: 10px;
        position: relative;
        width: var(--full-length);
        height: 8px;
        background-color: rgba(232, 52, 78, 0.2);

        &__done {
          position: absolute;
          width: var(--length);
          height: 8px;
          border-radius: 10px;
          background-color: $primary-color;
        }
      }
    }
  }

  &__lists {
    &__lectures {

    }

    &__homeworks {

    }
  }

}

.--active {
  color: $black;
}

.--rotate {
  transform: rotate(90deg);
}