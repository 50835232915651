@import "../../../_styles/utils";

section.sec {
  padding: 20px;
  margin: 8px 0 0;
  background-color: white;
}

section.reviews {
  padding-bottom: 120px;
  @include desktop {
  }

  .review {
    margin-bottom: 44px;

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      .rating {
        flex-shrink: 0;
      }

      .summary {
        margin-left: 10px;
        font-family: $spoqa-hans-neo;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.7px;
        text-align: left;
        color: #212529;
        width: 200px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .content {
      margin-bottom: 16px;
      font-family: $spoqa-hans-neo;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.7px;
      text-align: left;
      color: #212529;
    }

    .name {
      font-family: $spoqa-hans-neo;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: normal;
      color: #212529;
      margin-bottom: 12px;
    }

    .meta {
      font-family: $spoqa-hans-neo;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.7px;
      text-align: left;
      color: #212529;
    }
  }
}


section.reviews_b {
  .info-header {
    @include desktop {
      width: 1200px;
    }

    .pagination-icon {
      display: flex;
      align-items: center;

      img.arrow {
        cursor: pointer;
        display: none;
        @include desktop {
          display: block;
          width: 38px;
          height: 38px;
          margin-left: 16px;
        }
      }

      .--inverse {
        transform: rotate(-180deg);
      }

      .--inactive {
        opacity: 40%;
      }
    }
  }

  .review-wrapper {
    &::after {
      content: "";
      border-left: 16px solid transparent;
      @include desktop {
        border-left: none;
      }
    }

    &::before {
      content: "";
      border-left: 16px solid transparent;
      @include desktop {
        border-left: none;
      }
    }

    display: flex;
    flex-direction: row;
    align-items: flex-end;
    overflow-x: auto;
    white-space: break-spaces;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 100px;
    @include desktop {
      overflow-x: unset;
      padding-bottom: 120px;
    }

    .review {
      width: 270px;
      height: 516px;
      margin-right: 21px;
      border-radius: 24px;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
      margin-bottom: 44px;
      margin-top: 10px;
      padding: 24px 20.5px 27px;
      scroll-snap-align: center;
      position: center;
      @include desktop {
        box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.15);
        padding: 32px;
        height: 492px;
        width: 390px;
      }

      .title {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .rtan {
          width: 40px;
          @include desktop {
            width: 46px;
          }
        }

        .name {
          font-family: $spoqa-hans-neo;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.07;
          letter-spacing: normal;
          color: #000000;
          margin-left: 12px;
          @include desktop {
            font-size: 18px;
            margin-left: 15px;
          }
        }

        .round {
          font-family: $spoqa-hans-neo;
          color: #8b8b8b;
          font-size: 12px;
          margin-left: 4px;
          @include desktop {
            font-size: 18px;
            margin-left: 8px;
          }
        }
      }

      .meta {
        display: flex;
        align-items: center;

        .tag {
          font-family: $spoqa-hans-neo;
          font-size: 12px;
          font-weight: 500;
          line-height: 1;
          color: #212529;
          padding: 6px 12px;
          border-radius: 24px;
          background-color: #f1f1f1;
          margin-right: 4px;
          @include desktop {
            font-size: 14px;
            margin-right: 8px;
          }
        }
      }

      .content {
        font-size: 14px;
        width: 214px;
        height: 312px;
        margin-top: 24px;
        font-family: $spoqa-hans-neo;
        line-height: 1.6;
        letter-spacing: -0.7px;
        text-align: left;
        color: #212529;
        @include desktop {
          font-size: 16px;
          width: 320px;
        }
      }
    }
  }
}

.info-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0 22px;

  .info-header-text {
    display: flex;
    align-items: center;

    h2 {
      font-family: $spoqa-hans-neo;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.48px;
      text-align: left;
      color: #212529;
      @include desktop {
        font-size: 20px;
        line-height: 1;
        letter-spacing: -0.6px;
      }
    }
  }
}