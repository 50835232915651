@import '../../../_styles/utils';

.course_rcmd__wrapper {
  width: 100vw;
  height: 100vh;
  background-image: var(--bg-image);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;

  &.--isEmbed {
    &::after {
      width: 100%;
      position: absolute;
      height: 202px;
      top: 0;
      left: 0;
      right: 0;
      content: "";
      display: block;
      background: linear-gradient(180deg, #fffcf7 0%, rgba(250, 250, 250, 0) 100%);
    }

  }

  .course_rcmd__header {
    margin-bottom: 60px;

    h1 {
      font-family: GmarketSans;
      font-weight: bold;
      line-height: 1.4;
      font-size: 24px;
      color: $white;
      @include desktop {
        font-size: 30px;
      }
    }

  }

  .course_rcmd__body {
    margin-bottom: 60px;

    img {
      width: 348px;
    }

    .course_rcmd__title {
      margin: 24px 0;
      font-family: "Pretendard";
      font-weight: bold;
      font-size: 24px;
      color: $white;
    }

    .course_rcmd__label {
      margin-bottom: 24px;

      span {
        color: $chang-primary-color;
        background-color: #dae6ff;
        margin-right: 12px;
        border-radius: 100px;
        padding: 5px 14px;
      }
    }

    .course_rcmd__detail {
      p {
        color: $white;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 12px;
        @include desktop {
          br {
            display: none;
          }
        }
      }

      ul {
        font-size: 16px;
        font-family: "Pretendard";
        font-weight: 500;
        line-height: 24px;
        color: $white;
        list-style: none;

        li::before {
          content: "\2022";
          color: $white;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
      }
    }
  }

  .course_rcmd__footer {
    button {
      color: $white;
      background: $chang-primary-color;
      border-radius: 100px;
      width: 286px;
      height: 44px;
      font-family: "Pretendard";
      font-size: 16px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: -0.54px;
      text-align: center;
      outline: none;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}