@use 'sass:math';
@import '../../../../_styles/utils';

.bubblingHeartWrap {
  width: 100%;
  max-width: 1132px;
  margin: 0 auto;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  img {
    opacity: 0;
    -webkit-animation: linear alternate;
    animation-fill-mode: forwards;
    @for $i from 1 through 30 {
      &:nth-child(#{$i}) {
        -webkit-animation-name: bubbling#{$i};
        -webkit-animation-duration: 0.5 + math.div(random(7), 2) + s;
        -webkit-animation-delay: math.div(random(6), 2) + s;
      }
    }
  }
}

@for $i from 1 through 30 {
  @-webkit-keyframes bubbling#{$i} {
    0% {
      opacity: 0;
      transform: translateY(random(40) + px) translateX(-1*random(30) + px);
    }
    20% {
      opacity: 0.2;
      transform: translateY(-80 + random(40) + px) translateX(random(30) + px);
    }
    30% {
      transform: translateY(-100 + random(40) + px) translateX(-1*random(30) + px);
      opacity: 0.5;
    }
    50% {
      transform: translateY(-200  + random(40) + px) translateX(1*random(30) + px);
      opacity: 1;
    }
    80% {
      transform: translateY(-300  + random(40) +  px) translateX(-1*random(30) + px);
      opacity: 0.5;
    }
    100% {
      transform: translateY(-400  + random(40) + px) translateX(1*random(30) + px);
      opacity: 0;
    }
  }
}



