@import '../../../_styles/utils';

section.sec {
  padding: 20px;
  margin: 8px 0 0;
  background-color: white;

  div.row {
    align-items: unset;
    -webkit-align-items: flex-start;

    .course {
      display: flex;

      .course-img {
        width: 80px;
        height: 80px;
        object-fit: contain;
      }

      .course-body {
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 13px;
        padding: 5px 0;

        .course-info {
          span.course-title {
            font-family: $spoqa-hans-neo;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: -1px;
            text-align: left;
            color: #212529;

            @include desktop {
              font-size: 18px;
            }
          }

          span.course-round {
            font-family: $spoqa-hans-neo;
            font-size: 15px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: -0.45px;
            text-align: left;
            color: #868e96;
            margin-left: 6px;

            @include desktop {
              font-size: 16px;
            }
          }

          span.course-others {
            font-family: $spoqa-hans-neo;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: -0.6px;
            text-align: left;
            color: #868e96;

            @include desktop {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0 22px;

    .info-header-text {
      display: flex;
      align-items: center;

      h2 {
        font-family: $spoqa-hans-neo;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.48px;
        text-align: left;
        color: #212529;
        @include desktop {
          font-size: 20px;
          line-height: 1;
          letter-spacing: -0.6px;
        }
      }

      img.ic-question {
        width: 18px;
        height: 18px;
        object-fit: contain;
        margin-bottom: 3px;
        margin-left: 8px;
        cursor: default;
      }
    }

    .info-header-icon {
      img {
        width: 4.5px;
        height: 9px;
        object-fit: contain;
      }

      img.ic-question {
        width: 18px;
        height: 18px;
        object-fit: contain;
        margin-bottom: 3px;
        margin-left: 8px;
      }

      button {
        cursor: pointer;
        background-color: white;
        padding: 11px 21px;
        border-radius: 2px;
        border: solid 1px #dee2e6;
        font-family: $spoqa-hans-neo;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.7px;
        text-align: center;
        color: #212529;
      }
    }
  }

  .info-components {
    .info-component {
      margin-bottom: 18px;

      .info-key {
        font-family: $spoqa-hans-neo;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.7px;
        text-align: left;
        color: #868e96;

        &__before {
          margin-left: 4px;
          text-decoration: line-through;
        }
      }

      .info-value {
        font-family: $spoqa-hans-neo;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.7px;
        text-align: left;
        color: #212529;
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #dee2e6;
    margin-bottom: 20px;
  }

  .info-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 39px;

    .info-footer-text {
      font-family: $spoqa-hans-neo;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.7px;
      color: #212529;
    }
  }

  .social-proof {
    margin-bottom: 25px;
    display: flex;
    align-items: center;

    img {
      width: 21.1px;
      height: 19px;
      margin: 0 8px 1px 0;
      object-fit: contain;
    }

    span {
      margin-top: 3px;
      display: inline-block;
      font-family: $spoqa-hans-neo;
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.7px;
      text-align: center;
      color: #e8344e;
    }
  }

  .info-desc {
    color: $black;
    font-weight: bold;

    .warning {
      font-weight: bold;
      color: $primary-color;
    }

    .underline {
      color: $black;
      font-weight: bold;
      text-decoration: underline;
    }
  }
}

section.sec {
  padding: 20px;
  margin: 8px 0 0;
  background-color: white;

  .info-header {
    .info-header-icon {
      font-family: $spoqa-hans-neo;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.7px;
      text-align: right;
      color: #868e96;
      position: relative;

      .installment {
        position: absolute;
        top: 20px;
        right: 0;

        img {
          height: 250px;
          width: 227px;
          object-fit: contain;
        }
      }
    }
  }

  .info-component {
    display: flex;
    align-items: center;
    cursor: pointer;

    .radio {
      width: 18px;
      height: 18px;
      border-radius: 18px;
      border: solid 1px #dee2e6;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;

      &.active {
        border: solid 1px #212529;

        .radio-inner {
          width: 10.5px;
          height: 10.5px;
          border-radius: 10.5px;
          background-color: #212529;
        }
      }
    }

    .method {
      margin-left: 11px;
      display: flex;
      align-items: center;
      width: 100%;

      img {
        margin-left: 10px;
        height: 15px;
        object-fit: contain;
        margin-bottom: 2px;
      }
    }
  }
}
