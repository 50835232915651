@import '../../../_styles/utils';

.homework-answer-page {
  max-width: 100%;
  margin: auto;
  background: #e9ecef;
  height: 100%;
  overflow: auto;

  &__nav {
    max-width: calc((92% - 80px));
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 77px;
    margin: 0 auto;
    width: 100%;
    @include desktop {
      position: relative;
      justify-content: left;
    }
    @include lectureNav {
      justify-content: space-around;
      max-width: 100%;
    }
  }
}