@import "../../../_styles/utils";

.share_rcmd_page__wrapper {
  width: 100vw;
  background-color: #fff6f8;
  display: flex;
  flex-direction: column;
  align-items: center;

  .share_rcmd_page___header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 360px;
    margin-top: 60px;

    h1 {
      font-family: Pretendard;
      font-weight: 700;
      line-height: 33.6px;
      font-size: 24px;
    }

    .share_rcmd_page__header-title {
      &.--best {
        margin-bottom: 20px;
      }

    }
    .share_rcmd__header__desc {
      font-family: "Pretendard";
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: #81898f;
      margin: 0px;
    }
    .share_rcmd_online__card-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      width: 312px;
      height: 56px;
      margin-bottom: 60px;

      background: #e8344e;
      border-radius: 8px;
      border: none;

      color: $white;
      font-size: 16px;
      font-weight: 700;

      cursor: pointer;
    }

    .share_rcmd_online__card__wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;

      white-space: nowrap;
      overflow-x: auto;
      width: 100%;
      scroll-snap-type: x mandatory;
      -webkit-overflow-scrolling: touch;

      -ms-overflow-style: none;
      margin-top: 60px;
      margin-bottom: 40px;
      &.--best {
        justify-content: normal;
        margin-top: 0px;
      gap: 16px;
      padding: 40px 24px;
      }
    }

    @include desktop {
      margin-top: 100px;
      width: auto;
      h1 {
        font-size: 40px;
        line-height: 56px;
      }
      .share_rcmd_page__header-title {
        // margin-bottom: 60px;
      }
      .share_rcmd__header__desc {
        font-size: 20px;
      }
      .share_rcmd_online__card-button {
        margin-bottom: 100px;
      }
      .share_rcmd_online__card__wrapper {
        margin-bottom: 60px;
      }
    }
  }
}
