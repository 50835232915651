@import '../../_styles/utils';


.lecturePageContainer {
  width: 100%;
  background-color: #F5F5F5;

  .lectureWrapper {
    padding: 20px 0px 0px 0px;
    margin: 0px auto;
    background-color: #F5F5F5;
    width: 100%;
    max-width: 850px;

    .logoImg {
      img {
        position: absolute;
        top: 20px;
        //left: 3%;
        width: 120px;
        height: 30px;
      }
    }

    .lectureNavWrapper {
      width: 100%;
      position: relative;
      @include adesktop {
        width: 90%;
        margin: auto;
      }
      .lectureNavContainer {
        margin: auto;
        width: 520px;
        @include adesktop {
          width: 100%;
        }
      }
    }

    .contentContainer {
      width: 100%;
      margin: 20px auto 0px auto;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .videoContainer {
        width: 100%;
        display: inline-block;
        vertical-align: top;
      }

      .snippetContainer {
        width: 100%;
      }

      .snippetOnlyPc {
        //display: none;
        @include mobile {
          display: block;
          padding-bottom: 5px;
        }
      }

      //.sideContainer {
      //  width: 22.5%;
      //  border-radius: 10px;
      //  display: inline-block;
      //  vertical-align: top;
      //}
    }
  }
}