@import '../../../../_styles/utils';


.main-copy-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;


  .main-copy {
    font-size: 18px;
    line-height: 1.4;
    color: #000;
    font-weight: normal;
    margin-bottom: 18px;
    white-space: pre-line;
    @include desktop {
      white-space: normal;
      font-size: 16px;
      line-height: 1;
      margin-bottom: 32px;
    }
    font-family: GmarketSans;

    span {
      font-family: GmarketSans;
      color: #E8344E;
    }

    .bold_span {
      font-size: 24px;
      font-weight: bold;
      color: #000;
      line-height: 1.17;
    }

    img {
      width: 18px;
      margin-left: 4px;
      margin-bottom: 8px;
      object-fit: contain;
      @include desktop {
        height: 26px;
        width: 26px;
      }
    }
  }

}

