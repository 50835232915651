@import "../../_styles/utils";

.container {
  background-color: white;
  padding-bottom: 500px;
  margin-bottom: 30px;

  .mainContainer {
    margin: auto;
    margin-top: 100px;
    width: 1099px;
    line-height: 1;

    @include mobile {
      width: 350px;
      margin: 40px auto;

    }

    .greetingContainer {
      margin-bottom: 25px;

      .nameContainer {

        font-size: 35px;
        letter-spacing: -2.1px;
        color: #212529;
        line-height: 1.6;

        @include mobile {
          font-size: 32px;
        }
      }

      .new_year_banner {
        margin-top: 15px;

        img:first-child {
          display: block;
          max-width: 100%;
          height: auto;
          border-radius: 10px;
          @include adesktop {
            display: none;
          }
        }

        img:last-child {
          display: none;
          max-width: 100%;
          height: auto;
          border-radius: 5px;
          @include adesktop {
            display: block;
          }
        }
      }

      .numberContainer {
        width: 100%;
        display: flex;
        justify-content: space-between;

        @include mobile {
          flex-direction: column;
        }


        font-size: 30px;
        letter-spacing: -1.5px;
        color: #212529;
        line-height: 1.49;
        @include mobile {
          font-size: 24px;
        }

        a {
          color: black;
        }

        .rcmd {
          @include mobile {
            margin-top: 15px;
          }

          button {
            cursor: pointer;
            width: 332px;
            @include mobile {
              width: 100%;
            }
            height: 40px;
            border-radius: 5px;
            border: none;
            background-color: #e9ecef;

            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: -0.7px;
            color: #212529;
          }

          button:hover {
            background-color: #D4D7DB;
          }
        }
      }

      .noticerContainer {
        margin-top: 20px;
        font-size: 20px;
        letter-spacing: -1.5px;
        color: #E8344E;
        //text-align: center;
        line-height: 1.49;
      }
    }

    .cardContainer {
      margin-bottom: 35px;
      border-radius: 10px;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
      background-color: #ffffff;

      @include mobile {

      }

      .imageContainer {
        width: 26%;
        display: inline-block;
        vertical-align: top;
        margin-top: 13px;


        @include mobile {
          width: 100%;
          display: block;
          border-radius: 10px 10px 0px 0px;
        }

        .imageDisplayContainer {
          width: 270px;
          height: 187px;
          background-image: var(--bg-image);
          //background-image: url(https://www.venturesquare.net/wp-content/uploads/2019/12/2019_1224_CODING.jpg);
          background-size: cover;
          background-position: center;


          @include mobile {
            width: 100%;
            height: 220px;
            border-radius: 10px 10px 0px 0px;
          }
        }
      }

      .contentContainer {
        width: 44%;
        display: inline-block;
        vertical-align: top;
        margin-top: 35px;


        @include mobile {
          width: 100%;
          margin: 0px;
          padding: 30px 20px;
        }

        .courseContainer {
          margin-bottom: 0px;
          height: 30px;

          .courseNameContainer {

            font-size: 30px;
            letter-spacing: -1.8px;
            color: #212529;
            display: inline-block;

            @include mobile {
              font-size: 24px;
            }
          }

          .tutorNameContainer {

            font-size: 20px;
            letter-spacing: -0.6px;
            color: #868e96;
            margin-left: 10px;
            display: inline-block;

            @include mobile {
              font-size: 18px;
            }
          }
        }

        .progressContainer {
          margin-top: 19px;

          .progressTitleContainer {

            font-size: 20px;
            letter-spacing: -0.6px;
            color: #212529;
            display: inline-block;

            @include mobile {
              font-size: 18px;
            }
          }

          .progressRateContainer {
            display: inline-block;

            font-size: 20px;
            letter-spacing: -0.6px;
            color: #13c2c2;
            margin-left: 15px;

            @include mobile {
              font-size: 18px;
            }
          }
        }

        .remainingContainer {
          margin-top: 12px;

          .remainingTitleContainer {

            font-size: 20px;
            letter-spacing: -0.6px;
            color: #212529;
            display: inline-block;

            @include mobile {
              font-size: 18px;
            }
          }

          .remainingDaysContainer {
            display: inline-block;

            font-size: 20px;
            letter-spacing: -0.6px;
            color: #13c2c2;
            margin-left: 15px;

            @include mobile {
              font-size: 18px;
            }
          }
        }

        .weekzero {
          color: $primary-color;
          font-size: 14px;
          margin-left: 10px;
          cursor: pointer;
          text-decoration: underline;
        }

        .qnaButtonContainer {
          margin-top: 12px;
          height: 39px;
          border-radius: 5px;
          border: solid 1px #13c2c2;
          background-color: #ffffff;

          .qnaButtonTextContainer {
            font-size: 15px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #212529;
          }
        }

        .qnaButtonContainer:hover {
          background-color: $hover-color;

          .qnaButtonTextContainer {
            color: white !important;
          }
        }
      }
    }

    .buttonContainer {
      width: 30%;
      height: 215px;
      display: inline-block;
      vertical-align: top;
      @include adesktop {
        width: 100%;
        height: 50px;
      }

      .enterButtonContainer:hover {
        background-color: #009696;
        border: 1px solid #009696;
      }

      .enterButtonContainer {
        border: 1px solid #13C2C2;
        width: 300px;
        height: 62px;
        border-radius: 10px;
        background-color: #13c2c2;
        margin-top: 77px;

        @include adesktop {
          height: auto;
          font-size: 13px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.88;
          letter-spacing: -0.48px;
          text-align: center;
          color: #ffffff;
          border-radius: 10px;
          margin: -15px 20px 0px;
          width: calc(100% - 40px);
        }

        .enterButtonTextContainer {
          @include mobile {
            width: 100%;
          }
          width: 300px;
          height: 25.2px;

          font-size: 25px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          color: #ffffff;

          @include mobile {
            font-size: 22px;
          }
        }
      }
    }
  }
}

.certs {
  //border: 1px solid $disabled-background-color;
  border-radius: 4px;
  padding: 15px;
  margin: 10px auto 30px;
  ul {
    margin: 0;
    li {
      font-size: 14px;
      color: #212529;
      line-height: 1.6;

      a {
        text-decoration: none;
        color: black;
      }

      span {
        cursor: pointer;
      }
    }
  }
}