@import "../../_styles/utils.scss";

.wrapper {
  background-color: white;
  @include desktop {
    max-width: 685px;
    margin-left: calc(50% - 555px);
  }
}

.contentWrapper {
  padding-bottom: 50px;
  width: 100%;
}

.headerContainer {
  position: relative;
  margin-top: 4px;

  img {
    width: 100%;


    //@include tablet {
    //  position: absolute;
    //  width: 600px;
    //  right: calc(50% - 602px);
    //}
    //
    //@include desktop {
    //  //position: absolute;
    //  width: 600px;
    //  //right: calc(50% - 602px);
    //}
  }

  img.web {
    top: 68px;

  }


  img.app {
    top: 85px;
  }

}

.headerWrapper {

}

.pageHeader {

  padding: 64px 24px 14px;

  //@include tablet {
  //  width: 1206px;
  //  margin: 0 auto;
  //  padding: 123px 0 42px;
  //}
  //
  //@include desktop {
  //  width: 1206px;
  //  margin: 0 auto;
  //  padding: 0;
  //  padding: 123px 0 42px;
  //}

  .tag {
    opacity: 0.9;
    border-radius: 20px;
    background-color: #212529;
    color: white;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.84px;
    color: #ffffff;
    padding: 7px 13px;
    border: none;
    margin-right: 10px;
    margin-bottom: 10px;

    //@include tablet {
    //  padding: 8px 30px;
    //}
    //@include desktop {
    //  padding: 8px 30px;
    //}
  }
}

.videoWrapper {
  margin: 0px auto;

  iframe {
    width: 100%;
    height: 229px;

    @include desktop {
      height: 385px;
    }

    @include tablet {
      height: 385px;
    }
  }
}

.titleContainer {
  margin: 0px 20px;
  background-color: white;
  padding: 14px 10px 1px 10px;

  @include desktop {
    width: 400px;
    position: fixed;
    top: 76px;
    right: calc(50% - 570px);

    padding: 0px;
    margin: 0px 20px;
  }

  h1 {
    margin-top: 10px;
    color: #212529;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -2px;
    margin-bottom: 10px;
    @include desktop {
      font-size: 35px;
      font-weight: 700;
      letter-spacing: -2px;
      margin-bottom: 10px;
    }

    span.titleRound {
      color: #212529;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -2px;
      margin-bottom: 10px;
      @include desktop {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -2px;
        margin-bottom: 10px;
      }
    }
  }

  p {
    font-size: 20px;
    font-weight: 500;
    color: #212529;
    letter-spacing: -1px;
    margin-bottom: 10px;
    @include desktop {
      //padding-left: 3px;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: -1px;
      margin-bottom: 10px;
    }
  }
}

.summaryWrapper {
  margin: 15px 20px;

  @include desktop {
    margin: 15px 0px;
    //width: 400px;
    //position: fixed;
    //top: 520px;
    //right: calc(50% - 567px);
    //
    //padding: 14px 0px;
    //margin: 0px 20px;
  }
}

.shortedInfoWrapper {
  margin: 0px 20px;
  border-radius: 5px;
  border: 1px solid gray;
  background-color: white;
  padding: 10px 10px;

  @include desktop {
    padding: 10px 20px;
    width: 400px;
    position: fixed;
    top: 180px;
    right: calc(50% - 568px);
  }

  ul.rowFlexStartContainer {
    list-style: none;
    margin: 0;

    li {
      padding: 2px 5px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  ul {
    list-style: none;
    margin: 0;

    li {
      padding: 2px 5px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .reimburseTitle {
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.8px;
        color: black;

        @include mobile {
          font-size: 20px;
        }
      }

      .shutdownContainer {
        color: white;
        background-color: $primary-color;
        border-radius: 50px;
        padding: 2px;
        width: 80px;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        margin-left: 8px;

        @include mobile {
          font-size: 14px;
          margin-left: 4px;

          padding: 2px;
          width: 65px;
        }
      }

      .reimburseNumber {
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.8px;
        color: $primary-color;
      }

      .finalPriceTitle {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.8px;
        color: black;

        @include mobile {
          font-size: 14px;
        }
      }

      .discountRate {
        margin-left: auto;
        padding-right: 15px;
        font-weight: 500;
        color: $primary-color;
      }

      .finalPrice {
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.8px;
        color: black;
      }

    }
  }

  .priceContent {
    display: flex;


    .priceReturn {
      font-size: 40px;
      font-weight: 500;
      color: $primary-color;
      padding: 0 6px;
    }

    .priceDetail {
      padding: 11px 0px;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.53;
      letter-spacing: -0.7px;
      color: #343a40;

      strong {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.53;
        letter-spacing: -0.8px;
        color: $primary-color;
      }

      span {
        margin: 0 5px;
      }
    }
  }

  hr {
    border-top: 1px solid #e0e0e0;
    margin: 0;
  }
}

.priceWrapper {
  margin: 15px 20px;
  border-radius: 10px;
  background-color: #f2f2f2;
  padding: 14px 10px;

  @include desktop {
    width: 400px;
    position: fixed;
    top: 280px;
    right: calc(50% - 567px);
  }

  ul {
    list-style: none;
    margin: 0;

    li {
      padding: 2px 5px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      .reimburseTitle {
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.8px;
        color: black;
      }

      .reimburseNumber {
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.8px;
        color: $primary-color;
      }

      .finalPriceTitle {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.8px;
        color: black;
      }

      .discountRate {
        margin-left: auto;
        padding-right: 15px;
        font-size: 18px;
        font-weight: 500;
        color: $primary-color;

        @include mobile {
          font-size: 13px;
          padding-right: 2px;
        }
      }

      .finalPrice {
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.8px;
        color: black;
      }

    }
  }

  .priceContent {
    display: flex;


    .priceReturn {
      font-size: 40px;
      font-weight: 500;
      color: $primary-color;
      padding: 0 6px;
    }

    .priceDetail {
      padding: 11px 0px;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.53;
      letter-spacing: -0.7px;
      color: #343a40;

      strong {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.53;
        letter-spacing: -0.8px;
        color: $primary-color;
      }

      span {
        margin: 0 5px;
      }
    }
  }

  hr {
    border-top: 1px solid #e0e0e0;
    margin: 0;
  }
}

.livelyReviewWrapper {
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 13px 15px;
  margin: 15px 20px;
  @include desktop {
    width: 610px;
    margin: 0px auto 20px auto;
    padding: 23px 28px;
    border-radius: 15px;
  }

  .headline {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: -0.54px;
    color: #212529;

    @include desktop {
      font-size: 30px;
    }
  }

  .content {
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: -0.91px;
    color: #343a40;
    @include desktop {
      font-size: 22px;
    }
  }

  .footer {
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: -0.91px;
    color: #868e96;
    @include desktop {
      font-size: 22px;
    }
  }
}

.buttonContainer {
  width: 100%;
  background-color: white;
  border-top: 1px solid #eceef1;
  position: fixed;
  bottom: 0;
  //padding: 8px;
  display: flex;

  @include desktop {
    //width: 100%;
    border-top: none;
    position: fixed;
    width: 418px;
    top: 470px;
    height: 30px;
    right: calc(50% - 557px);
  }

  //@include tablet {
  //  width: 100%;
  //  position: fixed;
  //}

  .chitos {
    width: 100%;
    background-color: #343A40;
    position: fixed;
    font-size: 11px;
    right: 0px;
    bottom: 47px;
    padding: 4px;
    color: white;
    font-weight: 500;
    letter-spacing: -0.5px;
    text-align: center;
  }

  .buttonWrapper {

    width: 403px;
    margin: 0 auto;
    //@include desktop {
    //  width: 1204px;
    //  margin: 0 auto;
    //}
    //@include tablet {
    //  width: 1204px;
    //  margin: 0 auto;
    //}
    @include mobile {
      width: 100%;
      margin: 0 auto;
    }
  }


  .buttonAsk {
    flex: 1 2 auto;
    width: 100%;
    height: 48px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.45px;
    color: #959595;
    border-radius: 6px;
    margin-right: 6px;
  }

  .buttonApply {
    flex: 2 1 auto;
    width: 100%;
    height: 48px;
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: -0.66px;
    color: #ffffff;
    //border-radius: 6px;

    //@include tablet {
    //  width: 50%;
    //  float: right;
    //}
    //
    //@include desktop {
    //  width: 50%;
    //  float: right;
    //}
  }

}

.menuWrapper {
  z-index: 99999;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  border-bottom: 1px solid #eceef1;

  //@include tablet {
  //  height: 84px;
  //  padding-top: 20px;
  //}

  //@include desktop {
  //
  //  height: 84px;
  //  padding-top: 20px;
  //}

  li {
    font-size: 0.9rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: -0.9px;
    color: #000000;
    padding: 10px 20px;

    @include desktop {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.53;
      letter-spacing: -0.9px;
      color: #000000;
      padding: 12px 20px;
    }

    a {
      color: #000000 !important;
    }
  }
}

.containerHeader {
  height: 339px;
  background-color: #eaeaea;
}

.containerContent {
  padding-top: 50px;
  padding-bottom: 0px;
  //border-bottom: 1px solid #eceef1;

  img {
    width: 100%;
  }

  h3 {
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: -1.25px;
    color: #000000;
    padding: 0 20px;


    @include desktop {
      font-size: 36px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.58;
      letter-spacing: -1.25px;
      color: #000;
      padding: 10px 20px;
      margin-bottom: 0px;
    }
  }

}

.containerIntro {

}


.imageIntro {
  color: white;
  width: 100%;
  height: 6000px;
  //margin: 100px auto;
  background-color: white;
  background-image: var(--bg-image);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  @include mobile {
    width: 310px;
    height: 6000px;
    background-image: var(--bg-image);
    background-position: center;
    background-size: cover;
  }
}

.containerTutorinfo {
  .tutorProfile {
  }

  .descriptionWrapper {
    padding: 15px 20px;
  }
}

.containerReview {
  //background-color: #f8f9fa;
  padding-bottom: 0 !important;

  p {
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: -1.25px;
    color: #000000;
    padding: 0 20px;

    @include desktop {
      font-size: 24px;
      margin-top: 14px;
      margin-bottom: 40px;
      padding: 0 40px;
    }
  }

  .videoContents {
    .videoContainer {
      margin-bottom: 20px;
    }
  }

  .reviewListItem {
    border-radius: 10px;
    background-color: #ffffff;
    margin: 3px 30px;
    padding-bottom: 20px;
  }
}

.containerCurriculum {
  .curriculumWrapper {
    margin-top: 60px;
    padding: 0 20px;

    @include mobile {
      margin-top: 20px;
    }
  }
}

.containerFAQ {
  .faqWrapper {
    padding: 0 20px;

    .faqCollapse {
      border: none;

      .faqPanel {
        box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.1);
        background-color: #ffffff;

        div {
          background-color: #ffffff;
          padding-left: 18px;
        }
      }

    }
  }
}

.commercialContainer-opacity-top {
  position: fixed;
  top: 61px;
  width: 100%;
  background-color: #F1F3F5;
  color: #03B8B8;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.8px;
  text-align: center;
  padding: 10px 0px;
  opacity: 0.9;

  @include desktop {
    top: 84px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0px;
    padding: 5px 0px;
  }
}

.commercialContainer-opacity {
  top: 0px;
  position: fixed;
  width: 100%;
  background-color: #F1F3F5;
  color: #03B8B8;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.8px;
  text-align: center;
  padding: 10px 0px;
  opacity: 0.9;

  @include desktop {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0px;
    padding: 10px 0px;
  }
}

.commercialContainer {
  position: fixed;
  top: 52px;
  width: 100%;
  background-color: #F1F3F5;
  color: #03B8B8;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.8px;
  text-align: center;
  padding: 10px 0px;
  //opacity: 0.9;

  @include desktop {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0px;
    padding: 10px 0px;
  }
}

.cancelLine {
  text-decoration: line-through;
}

.highlightContainer {
  color: $primary-color !important;
}

.headCountContainer {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.8px;
  color: black;

  @include mobile {
    font-size: 14px;
  }
}

.billContainer {
  border-top: 1px solid;
  padding-top: 7px;
  margin-top: 7px;
}

.count {
  position: absolute;
  top: -20px;
  left: 15%;
  right: -10%;

  @include mobile {
    width: 220px;
    left: calc(50% - 100px);
    top: -25px;
  }

  em {
    display: inline-block;
    padding: 3px 7px;
    background: black;
    font-size: 15px;
    color: white;
    font-weight: 500;
    line-height: 19px;
    vertical-align: top;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
    font-style: normal;
    position: relative;

    @include mobile {
      font-size: 14px;
      padding: 5px 10px;
    }
  }

  em::before {
    content: '';
    position: absolute;
    bottom: -42%;
    left: 30%;
    margin-left: -9px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-top-color: black;
  }
}

div.gifContainer {
  margin: 20px auto;
  width: 480px;
  @include mobile {
    margin: 20px auto;
    width: 90%;
  }

  img.gifContainer {
    border: 1px solid;
    width: 480px;
    @include mobile {
      width: 100%;
    }
  }
}

.tooltipContainer {

  .tooltip {
    background-image: var(--bg-image);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 300px;
    height: 55px;

    left: calc(50% - 150px);
    position: fixed;
    bottom: 47px;

    color: black;
    line-height: 40px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.5px;

    text-align: center;


    @include desktop {
      width: 400px;
      height: 42px;
      font-size: 13px;
      line-height: 30px;

      position: fixed;
      top: 430px;
      left: calc(50% + 140px);
    }
  }

}

.reviewToShowContainer {
  div {
    font-size: 16px;
    color: black;
    margin: 20px auto;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    span {
      display: block;
    }

    @include desktop {
      font-size: 24px;
    }

    .reviewTitleContainer {
      cursor: pointer;
    }

    .reviewIconContainer {
      padding-top: 2px;
      font-size: 12px;
      @include desktop {
        font-size: 16px;
      }
    }
  }
}

.plain {
  color: black;
}