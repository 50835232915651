@import "../../../../_styles/utils";

.modal-content {
  &__text {
  }

  &__agree {
    color: $tone-down;
    margin: 24px auto -16px;
    font-size: 13px;
    &__a {
    }
  }
}
