@import '../../_styles/utils';

.checkinPageContainer {
  z-index: 2;
  width: 100%;
  //height: 100%;
  background-color: #f5f5f5;
  padding: 5%;

  .checkinPageContentsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: auto;

    @include desktop {
      max-width: 1230px;
      //width: 90%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: auto;
    }

    .checkinFixedContainer {
      @include mobile {
        width: 100%;
      }
      @include tablet {
        width: 100%;
      }


      @include desktop {
        position: fixed;
        //top: 102px;
        top: 17px;
      }

      .checkinLeftContainer {
        padding-top: 27px;
        padding-bottom: 10px;
        border-radius: 10px 10px 0px 0px;
        background-color: white;

        @include desktop {
          border-radius: 10px;
          padding-bottom: 129px;
        }

        @include adesktop {
          padding: 27px 20px 10px 20px;
        }

        .checkinCreateContainer {
          @include desktop {
            width: 700px;
          }


          .checkinHeaderWrapper {
            text-align: center;
            background-color: white;

            h2 {
              //position: fixed;
              //top: 44px;
              //left: calc(50% - 424px);

              font-size: 33px;
              //font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: -1.65px;
              color: #000000;
              margin-bottom: 25px;

              @include adesktop {
                font-size: 22px;
              }
            }

            h6 {
              font-size: 18px;

              @include adesktop {
                font-size: 14px;
              }
            }

            img {
              width: 328px;
              height: 168px;
              @include adesktop {
                width: 280px;
                height: 145px;
              }
            }

            .desc {
              margin-top: 10px;
              font-size: 14px;
              font-weight: 500;
              line-height: 1.57;
              letter-spacing: -0.98px;
              color: #495057;
            }
          }

          .checkinInputWrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            margin-top: 12px;

            textarea {
              padding: 15px;
              width: 356px;
              height: 186px;
              border-radius: 10px;
              border: 1px solid #ced4da;
              resize: none;
              @include adesktop {
                width: 300px;
              }
            }

            ::placeholder {
              color: #ADB5BD;
              opacity: 1; /* Firefox */
            }
          }

          .checkinButtonWrapper {
            margin-top: 20px;
            text-align: center;

            button {
              background-color: $hover-color;
              border: none;
              border-radius: 10px;
              padding: 8px 39px;

              font-size: 15px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #ffffff;

              height: 39px;
            }

            button:hover {
              cursor: pointer;
              background-color: $hover-color-deeper;
            }

            .skipWrapper {
              cursor: pointer;

              margin: 23px auto;
              text-decoration: underline;

              height: 14px;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: -0.98px;
              color: #868E96;
            }

            .checkinTipsWrapper {
              margin: auto;
              width: 356px;
              height: 63px;
              border-radius: 10px;
              background-color: #f1f3f5;

              display: flex;
              flex-direction: row;
              justify-content: space-around;
              align-items: center;

              .calloutIconWrapper {
                margin: 25px 14px;
                width: 20px;
                height: 14px;
                object-fit: contain;
                font-size: 20px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 0.7;
                letter-spacing: -1.4px;
                color: #212529;
              }

              .calloutContentWrapper {
                width: 282px;
                height: 42px;
                margin: 11px 30px 10px 0px;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: -0.98px;
                color: #495057;
              }
            }
          }
        }

        .goldinfoContainer {
          position: fixed;
          top: 48px;
          left: calc(50% - 20px);
          background-color: #E9ECEF;
          color: black;
          width: 71px;
          text-align: center;
          padding: 5px;
          border-radius: 10px;
          font-weight: 500;
          font-size: 14px;
        }
      }


    }

    .checkinRightContainer {
      width: 100%;
      text-align: center;

      @include desktop {
        background-color: black;
      }


      .checkinReadHeader {
        background-color: white;
        padding: 32px 0px;
        margin-top: 10px;
        width: 100%;

        @include desktop {
          position: fixed;
          top: 17px;
          right: calc(50% - 572px);
          //right: calc(50% - 582px);
          margin-top: 0px;
          width: 472px;
          padding-top: 22px;
          padding-left: 30px;
          padding-right: 0px;
          padding-bottom: 0px;
          background-color: white;
          border-radius: 10px 10px 0px 0px;
        }

        h4 {
          font-size: 28px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: -1.96px;
          color: #000000;

          @include mobile {
            margin-bottom: 0px;
          }
          @include tablet {
            margin-bottom: 0px;
          }
          @include adesktop {
            font-size: 18px;
          }
        }
      }
    }

    .checkinReadContainer {
      width: 100%;

      .cover {
        position: fixed;
        z-index: 0;
        top: 0px;
        width: 100%;
        height: 17px;
        background-color: #f5f5f5;
      }

      button {
        position: fixed;
        right: 5px;
      }
    }

  }

}