@import '../../_styles/utils';

.contentWrapper {
  padding: 10px 0px;
  background-color: white;

  .highlight {
    color: $primary-color;
  }

  .formWrapper {
    overflow: hidden;

    .done-header {
      h3 {
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: -1px;
        text-align: center;
        color: #000000;
        @include desktop {
          font-size: 35px;
        }
      }

      .desc {
        margin-top: 22px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: -0.7px;
        text-align: center;
        color: #000000;
        @include desktop {
          font-size: 18px;
          font-weight: 500;
        }
      }

      margin-bottom: 22px;
      @include desktop {
        margin-bottom: 43px;
      }
    }

    .seasonal-event-container {
      background-color: #f9f4ea;
      background-image: var(--bg-image);
      background-repeat: no-repeat;
      background-size: calc(100% - 30px);
      background-position: center;
      width: 100%;
      height: 375px;
      padding-top: 65px;

      @include desktop {
        background-size: 500px;
        width: 100%;
        height: 600px;
        padding-top: 130px;
      }

      .seasonal-event-main {
        .event-desc {
          font-family: 'GmarketSans';
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: -0.84px;
          text-align: center;
          color: #5b5b5b;

          @include desktop {
            object-fit: contain;
            font-size: 19.4px;
          }
        }

        .timeclock {
          text-align: center;
          margin: 14px;

          @include desktop {
            margin: 25px;
          }

          span {
            text-shadow: 0 1.3px 1.3px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(0, 0, 0, 0.25);
            font-family: 'GmarketSans';
            font-size: 23.7px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.13;
            letter-spacing: 0.95px;
            text-align: center;
            color: #e70000;

            @include desktop {
              font-size: 30.5px;
              letter-spacing: 1.22px;
            }
          }
        }
      }
    }

    .seasonal-event-text {
      font-family: $spoqa-hans-neo;
      font-size: 14px;
      text-align: center;
      background-color: #f9f4ea;
      padding: 20px;
      font-weight: 700;
      letter-spacing: -1px;
      color: #000000;

      @include desktop {
        font-size: 18px;
        line-height: 1.6;
        letter-spacing: -0.9px;
        text-align: center;
        color: #000000;
        padding: 20px 0 50px;
      }
    }

    .bg-gray {
      padding: 32px 11px 21px;
      background-color: #f8f9fa;
      @include desktop {
        width: 500%;
        margin-left: -200%;
        padding: 0 200% 30px;
      }

      .rcmd-content {
        @include desktop {
          width: 100%;
        }

        .rcmd-intro {
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: -0.9px;
          text-align: center;
          color: #000000;
          @include desktop {
            font-size: 30px;
            padding-top: 50px;
          }
        }

        .rcmd-title {
          margin: 23px auto;
          font-size: 24px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: -1.2px;
          text-align: center;
          color: #000000;
          @include desktop {
            font-size: 35px;
            margin-top: 0;
            padding-top: 30px;
          }
        }

        .rcmd-outro {
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: -0.7px;
          text-align: center;
          color: #000000;
          margin-bottom: 34px;
          @include desktop {
            margin-top: 36px;
            font-size: 18px;
          }
        }

        img.ic-rcmd {
          width: 353px;
          height: 188px;
          margin: 28px auto 0;
          object-fit: contain;
          display: block;
          @include desktop {
            width: 569.1px;
            height: 303.1px;
            margin: 47px auto;
            object-fit: contain;
          }
        }
      }
    }

    .howto {
      h3 {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.9px;
        text-align: center;
        color: #000000;
        margin: 36px 0 27px;

        @include desktop {
          font-size: 30px;
          letter-spacing: -1.5px;
          margin-bottom: 45px;
        }
      }

      .steps {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include desktop {
          flex-direction: row;
          justify-content: center;
        }

        .step {
          width: 332px;
          height: 245px;
          padding: 20.3px 44.6px 23px 44px;
          border-radius: 5px;
          box-shadow: 0 1.7px 43.6px 0 rgba(0, 0, 0, 0.13);
          background-color: #ffffff;
          margin-bottom: 20px;

          @include desktop {
            width: 381px;
            height: 371px;
            padding: 36px 28px 23px 27px;
            border-radius: 3px;
          }

          &:last-child {
            @include desktop {
              margin-left: 30px;
            }
          }

          .step-number {
            width: 30px;
            height: 30px;
            margin: 2.7px 106.4px 10px 107px;
            border-radius: 22.2px;
            background-color: #343a40;
            font-size: 17px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: -0.85px;
            text-align: center;
            color: #ffffff;
            @include desktop {
              width: 42px;
              height: 42px;
              margin: 0 142px 24px;
              padding: 4.2px 16.3px 4.2px 16.3px;
              border-radius: 21px;
              background-color: #343a40;
              font-size: 21px;
            }
          }

          .step-title {
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: -0.8px;
            text-align: center;
            color: #212529;
            margin: 10px auto;
            @include desktop {
              font-size: 24px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.6;
              letter-spacing: -1.2px;
              text-align: center;
              color: #212529;
            }
          }

          .step-desc {
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: -0.6px;
            text-align: center;
            color: #212529;
            margin: 10px auto;
            @include desktop {
              margin-top: 24px;
              font-size: 18px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.6;
              letter-spacing: -0.9px;
              text-align: center;
              color: #212529;
            }
          }

          .el {
            margin: 15px auto;
          }

          .step-cta {
            margin-top: 8px;
            font-size: 17.1px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
            object-fit: contain;
            border-radius: 5px;
            background-color: #e8344e;
            padding: 16px 17px;
            @include desktop {
              margin-top: 40px;
              font-size: 20px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: normal;
              text-align: center;
              color: #ffffff;
              cursor: pointer;
            }
          }
        }
      }
    }

    .rcmd-outro {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.9px;
      text-align: center;
      color: #000000;
      @include desktop {
        margin-top: 53px;
        font-size: 30px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: -1.5px;
        text-align: center;
        color: #212529;
      }
    }

    .hrdiv {
      width: 500%;
      margin-left: -200%;
      height: 1.1px;
      background-color: #ced4da;
      margin-top: 51px;
      margin-bottom: 21px;
    }

    .caution {
      width: 254px;
      margin: auto;
      @include desktop {
        width: 560px;
        margin: 40px auto 80px;
      }

      p.notice {
        font-size: 12.5px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.88px;
        text-align: left;
        color: #495057;
        @include desktop {
          font-size: 20px;
          font-weight: 500;
          letter-spacing: -1.4px;
        }
      }

      p {
        font-size: 12.5px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: -0.88px;
        text-align: left;
        color: #495057;
        @include desktop {
          font-size: 18px;
          letter-spacing: -1.26px;
        }
      }
    }
  }
}

.cta-bg {
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: white;

  .cta-group {
    width: 100%;
    max-width: $maxWidth;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .cta-summary {
      display: none;
      @include desktop {
        display: block;
      }

      .cta-summary-text {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.98px;
        text-align: left;
        color: $primary-color;
      }
    }

    .ctas {
      width: 100%;
      max-width: $maxWidth;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;

      @include desktop {
        width: 60%;
        margin: 0;
      }

      .sub-cta {
        cursor: pointer;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.42px;
        text-align: center;
        padding: 13px 29px;
        border-radius: 3px;
        background-color: white;
        border: 1px solid $primary-color;
        color: $primary-color;

        @include desktop {
          font-size: 20px;
          width: 49%;
        }

        img {
          width: 12px;
          height: 12px;
          margin: 0 0 3px 5px;
          object-fit: contain;

          @include desktop {
            width: 19px;
            height: 19px;
          }
        }
      }

      .main-cta {
        cursor: pointer;
        padding: 14.5px 38px;
        border-radius: 3px;
        border: 1px solid $primary-color;
        background-color: $primary-color;
        color: white;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.42px;
        text-align: center;


        @include desktop {
          font-size: 20px;
          width: 49%;
        }
      }
    }
  }
}