@import '../../_styles/utils';

.backgroundWrapper {
  //background-color: #e3e3e3;
  @include desktop {
    //padding-bottom: 30px;
  }


  .contentWrapper {
    padding: 0;
    background-color: white;
    padding-bottom: 60px;

    @include mobile {
      width: 100%;
    }
    @include tablet {
      width: 680px;
      margin: 0 auto;
    }
    @include desktop {
      width: 680px;
      margin: 0 auto;
    }

    h3 {
      font-size: 25px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.58;
      letter-spacing: -1.25px;
      color: white;
      margin: 0;
    }

    .headerWrapper {
      background-color: #333333;
      padding: 7px 20px;
      color: white;

    }

    .formWrapper {

      .titletWrapper {
        padding: 7px 20px;
        background-color: #f5f5f5;

        label {
          margin-top: 10px;
          width: 254px;
          font-size: 17px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.53;
          letter-spacing: -0.75px;
          color: #000000;

        }
      }

      .content {
        background-color: white;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 20px;

        .promo-basic {
          padding: 0px;
          font-size: 14px;
          @include mobile {
            font-size: 12px;
          }
        }
      }

      .course-title {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.53;
        letter-spacing: -1px;
        color: #000000;


        display: flex;
        flex-direction: row;
        /* justify-content: center; */
        align-items: center;


        .urgentContainer {
          margin-left: 5px;
          background-color: #E8344E;
          padding: 2px 6px;
          border-radius: 50px;
          color: white;
          letter-spacing: -0.5px;
          font-weight: 700;
          font-size: 14px;
        }
      }

      .course-date {
        margin-top: 7px;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: -0.34px;
        color: #828282;
        margin-bottom: 20px;
      }

      .info-label {
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: -0.34px;
        color: #828282;
      }

      .info-detail {
        margin-top: 7px;
        margin-bottom: 20px;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: -0.34px;
        color: #333333;
      }

      table {
        width: 100%;

        .finalContainer {
          border-top: 1px solid;
        }

        .todaydiscountContainer {
          //display: none;
        }
      }

      table td {
        padding: 0.75rem 0;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: -0.34px;
        color: #828282;
      }

      td:last-child {
        text-align: right;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: -0.34px;
        color: #333333;
      }

      td.final {
        font-size: 17px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: -0.51px;
        color: #333333;
      }

      td.highlight-text {
        color: #e8344e;
      }

      td.coupon-highlight {
        //font-weight: bold;
      }

      .promo {
        margin: 15px 0 20px 0;

        input {
          width: 100%;
          height: 38px;
          border: 1px solid #e2e3e4;
          border-radius: 5px;
          font-size: 14px;
          background-color: white;
          color: #959595;
        }
      }

      .payment {
        input[type=radio]:checked + label:before {
          //background-image: url("");
        }
      }

      ul li {
        list-style: none;
        float: left;
        margin-left: 18%;
        width: 30%;
      }

      label {
        font-size: 17px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: -0.6px;
        color: #333333;
        padding: 0;
      }

      .privacy-form {
        padding: 10px 0;
        width: 100%;
        margin: 0 auto;
        text-align: center;
      }

      .privacy {
        padding: 0.75rem 0;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.34px;
        color: #828282;
      }


      button {
        width: 100%;
        margin: 10px auto;
        display: block;
        height: auto;
        font-size: 22px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: -0.66px;
        padding: 12px 0;
        border-radius: 6px;
      }

      .bottomForm {
        padding: 0 20px;

      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .contentWrapper {
    width: 100%;
    margin: 0 auto;
    padding: 0;

    h3 {
      font-size: 25px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.58;
      letter-spacing: -1.25px;
      color: #000000;
      margin: 0;
    }

    .formWrapper {

      .titletWrapper {
        padding: 7px 20px;

        label {
          margin-top: 10px;
          width: 254px;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.53;
          letter-spacing: -0.75px;
          color: #000000;
        }
      }

      .content {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
      }

      .course-title {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.53;
        letter-spacing: -1px;
        color: #000000;
      }

      .course-date {
        margin-top: 7px;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: -0.34px;
        color: #828282;
        margin-bottom: 20px;
      }

      .info-label {
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: -0.34px;
        color: #828282;
      }

      .info-detail {
        margin-top: 7px;
        margin-bottom: 20px;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: -0.34px;
        color: #333333;
      }

      table {
        width: 100%;
      }

      table td {
        padding: 0.75rem 0.75rem 0.75rem 0;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: -0.34px;
        color: #828282;
      }

      td:last-child {
        text-align: right;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: -0.34px;
        color: #333333;
      }

      td.final {
        font-size: 17px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: -0.51px;
        color: #333333;
      }

      .promo-label {
        display: none;
      }


      input[type=text] {
        width: 100%;
        border: 1px solid #e2e3e4;
        border-radius: 5px;
        font-size: 14px;
        background-color: white;
        color: #959595;
      }

      .promo {
        margin: 15px 0 20px 0;

        label {
          display: none;
        }

        input {
          width: 100%;
          height: 38px;
          border: 1px solid #e2e3e4;
          border-radius: 5px;
          font-size: 14px;
          background-color: white;
          color: #959595;
        }
      }

      .payment {
        input[type=radio]:checked + label:before {
          //background-image: url("");
        }
      }

      ul li {
        list-style: none;
        float: left;
        width: 50%;
        margin-left: 0px;
        margin-bottom: 30px;
      }

      label {
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: -0.6px;
        color: #333333;
        padding: 0;
      }

      .privacy-form {
        line-height: 20px;
        width: 100%;
        margin: 0 auto;
        text-align: center;
      }

      .privacy {
        padding: 0.75rem 0.75rem 0.75rem 0;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.34px;
        color: #828282;
      }


      button {
        display: block;
        width: 90%;
        margin: 10px auto;
        height: auto;
        font-size: 22px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: -0.66px;
        padding: 12px 0;
        border-radius: 6px;
      }
    }
  }
}


.inputContainer {
  border-radius: 6px;
  border: solid 1px #c4c4c4;
  display: flex;
  flex-direction: row;

  @include mobile {
    height: 38px;
  }
  @include tablet {
    height: 46px;
  }
  @include desktop {
    height: 46px;
  }

  input[type=text].myInput {
    border: none;
    border-radius: 6px;
    padding-left: 10px;
    background-color: white;

    color: #959595;

    @include mobile {
      width: calc(100% - 80px);
      font-size: 14px;
    }
    @include tablet {
      width: calc(100% - 100px);
      font-size: 17px;

    }
    @include desktop {
      width: calc(100% - 100px);
      font-size: 17px;
    }
  }

  a {
    border-radius: 6px;
    color: #4f4f4f;
    background-color: #f5f5f5;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    place-content: center;
    align-items: center;

    @include mobile {
      width: 80px;
    }
    @include tablet {
      width: 100px;
    }
    @include desktop {
      width: 100px;
    }
  }
}

.userinfoContainer {
  input[type=text].userInfo {
    border-radius: 6px;
    border: solid 1px #c4c4c4;
    padding: 10px;
    background-color: white;
    width: 100%;
    font-size: 17px;

    color: #959595;
  }
}

.display-price {
  text-decoration: line-through;
}

.todaydiscount-top {
  background-color: #13C2C2;
  padding: 10px 0px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;

  @include mobile {
    font-size: 18px;
  }
}

.todaydiscount-tooltip {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  text-align: center;
  border-radius: 5px;
  width: 280px;
  padding: 5px;
  margin: 0 auto;
}

.timer {
  background-color: #212529;
  color: white;
  padding: 18px 20px 20px 20px;

  .timerFirstRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .timerTitle {
      font-size: 24px;
      font-weight: 700;
      letter-spacing: -1.5px;
    }

    .timerClock {
      text-align: center;

      img {
        width: 18px;
        height: 18px;
        margin-right: 10px;
        margin-bottom: 6px;
      }

      span {
        font-size: 21px;
        font-weight: 700;
        letter-spacing: -1.5px;
      }
    }
  }

  .timerSecondRow {
    margin-top: 5px;
    font-size: 15px;
    letter-spacing: -0.5px;
    font-weight: 500;
  }
}

.featureContent {
  width: 100%;
  border: 1px solid #e2e3e4;
  border-radius: 5px;


  .feature {
    width: 100%;
    border-bottom: 1px solid #e2e3e4;
    padding: 10px 10px 8px 10px;
    img {
      width: 25px;
      height: 25px;
      margin-bottom: 3px;
      margin-right: 8px;
    }

    span {
      color: black;
      font-size: 17px;
      letter-spacing: -1px;
    }
  }


  .feature:last-child {
    border: none;
  }
}