@import "../../../../_styles/utils";

.text_wrapper {
    display: flex;
    justify-content: center;
    padding-bottom: 60px;

    img {
        width: 40px;
        object-fit: contain;
    }

    .picker_down {
        width: 20px;
    }

    span {
        font-family: $spoqa-hans-neo;
        font-size: 14px;
        font-weight: 500;
        margin-left: 10px;
        color: #8b8b8b;
    }

    .fingers_down {
        height: 100%;
    }
}
