@import '../../../../../../_styles/utils';

.WeeklyBPWrapper {
  display: grid;
  justify-content: center;

  .hrContainer{
    display: flex;
    height: 100%;
    grid-row: 1;
    grid-column: 1;

    hr {
      border-left: dotted 4px black;
      width: 1px;
      height: 100%;
    }

  }

  img {
    grid-column: 1;
    grid-row: 1;
    width: 60px;
    height: 73px;
    object-fit: contain;
  }


}

