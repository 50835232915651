
.ant-rate-star-first, .ant-rate-star-second {
    color: #CED4DA;
}
.ant-rate-star.ant-rate-star-full svg {
  width: 15px;
  height: 15px;
  margin: -2.5px;
}
.ant-rate-star.ant-rate-star-zero svg {
  width: 15px;
  height: 15px;
  margin: -2.5px;
}
.ant-rate-star.ant-rate-star-half.ant-rate-star-active svg {
  width: 15px;
  height: 15px;
  margin: -2.5px;
}
/*.ant-select-arrow {*/
/*  top: 53%;*/
/*}*/
