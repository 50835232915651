@import '../../_styles/utils';


.pageContainer {
  width: 100%;
  padding: 15px;
  //height: 100%;
  background-color: #f5f5f5;

  .pageContentContainer {
    //position: absolute;
    //top: 15px;
    //left: calc(50% - 615px);


    background-color: white;
    border-radius: 10px;

    width: 1230px;
    margin: 30px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .pageTitleContainer{
      margin-top: 30px;
      text-align: center;

      h1 {
        font-size: 40px;
        letter-spacing: -2px;
      }

      img {
        margin: 0px 0px 40px 0px;
        width: 290px;
        height: 250px;
      }

      .pageTitleDesc {
        width: 470px;
        margin: auto;
        padding: 20px;

        background-color: #f5f5f5;
        border-radius: 10px;
        text-align: start;
      }
    }
    .pageQuestionContainer{
      margin-top: 30px;
      width: 470px;

      .questionContainer {
        margin-top: 30px;
        h4 {
          font-size: 18px;
          letter-spacing: -1px;
          font-weight: 500;
        }
        .questionTitleWrapper {

        }
        .questionAnswerWrapper {
          display: flex;
          align-items: center;
          label {
            margin-bottom: 0px !important;
          }

          input[type=text] {
            width: 470px;
            padding: 10px;
            border: 1px solid #e2e3e4;
            border-radius: 5px;
            font-size: 14px;

            &.email2 {margin-top: 10px;}
          }
        }
      }
    }
    .buttonContainer{
      margin: 30px auto 40px auto;
      button {
        cursor: pointer;
        width: 240px;
        padding: 10px 15px;
        font-size: 18px;
        letter-spacing: -1px;
        font-weight: 500;
        color: white;
        background-color: $hover-color;
        border-radius: 10px;
        border: none;
      }

      button:hover{
        background-color: $hover-color-deeper;
        border: none;
      }
    }
  }
}

span.underline {
  text-decoration: underline;
}