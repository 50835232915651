@import '../../../../_styles/utils';


.UserSettingsContainer {
  width: 100%;
  line-height: 3;
  margin-top: 23px;
  table {
    width: 100%;

    tr {
      td:nth-child(1) {
        font-size: 16px;
        color: #000;
        line-height: 1.6;
        font-weight: 500;
        letter-spacing: -1px;
      }

      td:nth-child(2) {
        text-align: right;
        span {
          background: #fff;
          border: 1px solid #ced4da;
          padding: 11px 17px;
          font-size: 14px;
          line-height: 1;
          cursor: pointer;
          color: #495057;
          font-family: $spoqa-hans-neo;

          &:nth-child(1) {
            border-top-left-radius: 100px;
            border-bottom-left-radius: 100px;
          }

          &:nth-child(2) {
            border-top-right-radius: 100px;
            border-bottom-right-radius: 100px;
          }

          &.active {
            color: #fff;
            border-color: #000;
            background: #000;
          }
        }
      }
    }
  }

}