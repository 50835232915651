@import '../../../_styles/utils';


.page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  .title {
    padding-top: 60px;
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 130%;
    /* or 29px */

    text-align: center;

    /* Gray100 */

    color: #141617;
  }

  .desc {
    margin-top: 20px;
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */

    text-align: center;

    /* Gray70 */

    color: #81898F;

  }

  .image {
    width: 291px;
    height: 120px;
    margin-top: 30px;
  }

  .caption {
    margin-top: 10px;
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #81898F;
  }

  .seperator {
    margin: 30px 0;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 330px;
    flex-wrap: wrap;

    .coupon {
      width: 155px;
      height: 116px;
    }
  }

  .cta {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
    gap: 10px;

    width: 320px;
    height: 56px;

    /* SpartaRed_E8344E */

    background: #E8344E;
    border-radius: 8px;

    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #FFFFFF;

  }
}