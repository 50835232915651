@import '../../../../_styles/utils';

.homework-answer-previous-btn {
  padding: 16px 20px;
  border-radius: 100px;
  background-color: $black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $spoqa-hans-neo;
  font-size: 16px;
  font-weight: bold;
  color: $white;
  cursor: pointer;
  @include desktop {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  &__img {
    transform: rotate(-90deg);
    margin-left: 9.2px;
  }
}