@import '../../../_styles/utils';

.delivery-info {
  padding: 20px;
  margin: 8px 0 0;
  background-color: white;
  font-family: "Pretendard", "Nanum Gothic", serif;

  h2{
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 12px;
  }

  .description {
    font-family: 'Pretendard';
    font-weight: 500;
    font-size: 13px;
    line-height: 21px;
    margin-bottom: 20px;
    color: var(--Neutral-Day-Gray70, #81898F);

    @include desktop {
      font-size: 14px;
      line-height: 22px;
    }
  }

  .rows{
    display: grid;
    gap: 12px;
    .row{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @include tablet{
        justify-content: normal;
        gap: 16px;
      }
      @include desktop{
        justify-content: normal;
        gap: 16px;
      }
      .row-title{
        display: flex;
        align-items: center;
        width: 72px;
        height: 38px;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #81898F;
      }
      .input-container{
        display: grid;
        gap: 4px;
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

      .input-desc{
        display: flex;
        align-items: center;
        width: 240px;
        height: 38px;
        line-height: 140%;
        color: #81898F;
        font-size: 12px;

        @include desktop {
        font-size: 14px;
          width: 484px;
        }
      }
        input[type=number] {
          -moz-appearance: textfield;
        }
        input {
          ::placeholder{
            color: #C7D2D8;
          }
          font-weight: 500;
          font-size: 14px;
          line-height: 140%;
          width: 240px;
          height: 38px;
          padding: 9px 12px;
          border: 1px solid #DEE2E6;
          border-radius: 2px;
          color: #141617;
          margin: 0;
          @include tablet{
            gap: 12px;
          }
          @include desktop{
            gap: 12px;
          }
        }
        input.address-num {
          width: 160px;
        }
        input.memo{
          @include tablet{
            width: 484px;
          }
          @include desktop{
            width: 484px;
          }
        }
        .address{
          width: 240px;
          display: flex;
          flex-wrap: wrap;
          gap: 4px;
          @include tablet{
            width: 484px;
          }
          @include desktop{
            width: 484px;
          }
        }
      }
      .phone-input-container{
        width: 240px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #141617;
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type=number] {
          -moz-appearance: textfield;
        }
        input.phone {
          margin: 0;
          width: 67px;
          height: 38px;
          padding: 9px 12px;
          border: 1px solid #DEE2E6;
          border-radius: 2px;
          font-weight: 500;
          font-size: 14px;
          line-height: 140%;
        }
      }
      .search-container {
        display: flex;
        cursor: pointer;
        gap: 4px;
      }
     .search-address {
       width: 76px;
       height: 38px;
       padding: 9px 12px;
       background: #141617;
       font-family: 'Pretendard', "Nanum Gothic", serif;
       font-style: normal;
       font-weight: 500;
       font-size: 14px;
       line-height: 140%;
       border-radius: 2px;
       border: none;
       color: #FFFFFF;
     }


    }
  }
}
