@import "../../../_styles/utils";

.course {
  display: flex;
  align-items: center;
  @include desktop {
    margin-bottom: 10px;
  }

  &.gift {
    margin-top: 15px;
    @include desktop {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }

  .course-img {
    width: 125px;
    height: 80px;
    object-fit: cover;
    border-radius: 8px;
  }

  .course-body {
    height: 80px;
    display: flex;
    flex-direction: column;
    margin-left: 13px;

    .course-info {
      span.course-title {
        font-family: Pretendard;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        text-align: left;
        color: #212529;

        @include desktop {
          font-size: 18px;
        }
      }

      span.course-round {
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        text-align: left;
        color: #868e96;
        margin-left: 6px;

        @include desktop {
          font-size: 16px;
        }
      }

      span.course-others {
        font-family: Pretendard;
        font-size: 13px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.6px;
        text-align: left;
        color: #868e96;
        span {
          font-family: Pretendard;
        }

        @include desktop {
          font-size: 14px;
        }

        &.gift {
          color: $primary-color;
          //margin-top: 1px;
          margin-left: 7px;
        }
      }

      img {
        margin-bottom: 1px;
      }
    }
    .course-info-pretendard {
      div {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
      }

      span.course-title {
        font-family: "pretendard", "Nanum Gothic", serif;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -1px;
        text-align: left;
        color: #212529;

        @include desktop {
          font-size: 18px;
        }
      }

      span.course-round {
        font-family: "pretendard", "Nanum Gothic", serif;
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        letter-spacing: -0.45px;
        color: #868e96;
        margin-left: 6px;
        @include desktop {
          font-size: 16px;
        }
      }

      span.course-others {
        font-family: "pretendard", "Nanum Gothic", serif;
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        color: #81898f;

        @include desktop {
          font-size: 14px;
        }

        &.gift {
          color: $primary-color;
          //margin-top: 1px;
          margin-left: 7px;
        }
      }

      img {
        margin-bottom: 1px;
      }
    }
  }
}
