@import '../../../../_styles/utils';

.roadmap-rank-container {
  padding: 22px 18px;
  background-color: $white;
  border-radius: 24px;
  margin-bottom: 16px;
  margin-top: 16px;

  &.pirates {
    background-color: #2B2D2E;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title {
      font-family: $spoqa-hans-neo;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.6px;
      text-align: left;
      color: $black;

      &.pirates {
        color: #EEF3F6;
      }
    }

    &__detail {
      font-family: $spoqa-hans-neo;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.42px;
      text-align: left;
      color: $black;

      &.pirates {
        color: #C7D2D8;
      }
    }
  }

  &__header-sub {
    margin: 24px auto;
    padding: 8px 23px;
    border-radius: 103px;
    background-color: $pale-gray;
    display: flex;
    align-items: center;
    white-space: pre-line;

    &.pirates {
      background-color: #3A3E41;
    }

    &__img {
      width: 26.7px;
      height: 40px;
      object-fit: contain;
    }

    &__text {
      margin-left: 13px;
      font-family: $spoqa-hans-neo;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.96px;
      text-align: left;
      color: $black;

      &.pirates {
        color: #EEF3F6;
      }
    }
  }

  &__contents {
    &__content {
      display: flex;
      align-items: center;
      padding: 10px;

      &.--me {
        border-radius: 48px;
        background-color: #fff5f5;

        &.pirates {
          background-color: rgba(255, 108, 122, 0.4);
        }
      }

      &__order {
        width: 40px;
        height: 26px;
        font-family: $spoqa-hans-neo;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: -0.96px;
        text-align: left;
        color: $black;

        &.pirates {
          color: #EEF3F6;
        }
      }

      &__profile {
        margin-left: 30px;
        display: flex;
        align-items: center;

        &__img {
          display: inline-block;
          width: 33.7px;
          height: 34px;
          object-fit: contain;
        }

        &__name {
          width: 60px;
          margin-left: 8px;
          display: inline-block;
          font-family: $spoqa-hans-neo;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: -0.96px;
          text-align: left;
          color: $black;
          padding-top: 3px;
          overflow-x: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &.pirates {
            color: #EEF3F6;
          }
        }
      }
    }
  }
}