@import "../../../../_styles/utils";

.roadmap-maker-dashboard-container {
  background-size: 100% 100%;
  height: 240px;
  padding: 0 75px 0 28px;
  border-radius: 24px;
  border: solid 1.6px $black;
  overflow: hidden;
  margin-bottom: 11px;
  position: relative;

  .roadmap-maker__chars {
    height: 130px;
    position: relative;
    width: calc(100% + 56px);
    margin-left: -28px;
    margin-bottom: 10px;
  }

  .roadmap-maker__weeks {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;

    &__plain-bar {
      position: absolute;
      width: var(--full-length);
      height: 12px;
      background-color: #692819;
      bottom: 15px;
      left: 10px;
      z-index: 0;

      &--active {
        position: absolute;
        width: var(--length);
        height: 12px;
        background-color: #e8505b;

        &__rtan {
          position: absolute;
          bottom: 18px;
          left: var(--length);
          z-index: 19 !important;
          right: -18px;
          width: fit-content;

          &__img {
            height: 75px;
          }
        }
      }
    }

    &__week {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;

      &__dino {
        position: absolute;

        &__img {
          height: 90px;
          position: absolute;
          bottom: -18px;
          left: -70px;
          z-index: 10;
        }
      }

      &__img {
        width: 62px;
        height: 75px;
        object-fit: contain;
        z-index: 9;

        &__last {
          object-fit: contain;
          z-index: 9;
        }
      }

      &__fin {
        display: none;

        &__done {
          display: block;
          width: 70px;
          height: 70px;
          position: absolute;
          top: -75px;
        }

        width: 70px;
        height: 70px;
        position: absolute;
        top: -75px;
      }

      &__title {
        font-family: $spoqa-hans-neo;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.54px;
        text-align: center;
        color: $black;
        padding: 7px 28px;

        &:hover {
          //padding: 7px 28px;
          border-radius: 100px;
          background-color: $pale-gray;
        }
      }

      &:hover {
        img {
          display: block;
        }
      }
    }
  }
}
