@import "../../../../_styles/utils";

.LecMenuWrapper {
  display: flex;
  flex-direction: column;
  max-width: 850px;
  width: 100%;
  margin: 0 auto;
  position: relative;

  .LecMenuContainer {
    width: 100%;
    background-color: #e9ecef;
    display: flex;
    flex-direction: row;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .LecMenuContentsContainer {
    width: 100%;
  }

  // 듀얼 모드
  &.dual {
    max-width: 58px;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;

    .LecMenuContainer {
      flex-direction: column;
      align-items: center;
      height: 100vh;
      position: relative;
      box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.05);
    }
  }
}

.modal-content {
  &__header {
    font-family: $spoqa-hans-neo;
    text-align: left;
    margin-left: 30px;
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.39;
    letter-spacing: -1.08px;
    color: #000;
  }

  &__textarea {
    width: 394px;
    height: 160px;
    padding: 24px 18px;
    border-radius: 24px;
    border: solid 1px #adb5bd;
    background-color: $white;
    resize: none;
    font-family: $spoqa-hans-neo;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -0.96px;
    text-align: left;
    color: $black;

    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    &::placeholder {
      font-family: $spoqa-hans-neo;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.96px;
      text-align: left;
      color: #adb5bd;
    }
  }
}
