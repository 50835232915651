@import '../../../../_styles/utils';

.B2bNPSCheckWrapper {
  max-width: 580px;
  height: 200px;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  widht: 100%;

}

.B2bNPSCheckNumber {
  width: 40px;
  height: 25px;
  background-color: #FFE3E3;
  margin-left: auto;
  margin-right: auto;
  color: #E8344E;
  vertical-align: center;
  border-radius: 10px;
  margin-bottom: 15px;
}

.B2bNPSCheckQuestion {
  font-family: Pretendard;
  font-size: 20px;
  font-weight: normal;
  padding-bottom: 16px;

}

.B2bNPSCheckButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.B2bNPSCheckPlaceholder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  font-family: Pretendard;
  font-weight: normal;
  color: #ADB5BD;
}

.B2bNPSCheckButton {
  -webkit-appearance: none;
  width: 40px;
  height: 30px;
  border: 2px solid #CED4DA;
  cursor: pointer;
  &:checked {
    background-color: #ADB5BD;
  }
  @include desktop {
    width: 60px;
  }
}

.B2bNPSCheckSpan {
  pointer-events: none;
  position: relative;
  top: -30px;
  @include desktop {
    top: -30px;
  }
}

