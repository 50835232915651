@import "../../../../_styles/utils";

.bg {
  display: none;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.--active {
  display: block !important;
}

.--active--flex {
  display: flex !important;
  flex-direction: column;
}

.modal {
  width: 100%;
  height: fit-content;
  overflow: visible;
  display: none;
  position: fixed;
  bottom: 0;
  z-index: 999;
  background-color: white;
  border-radius: 24px 24px 0 0;
  border: none;
  padding: 30px 20px 0;
  gap: 20px;
  @include tablet {
    width: 374px;
    height: 414px;
    left: 50%;
    top: 50%;
    padding: 30px;
    transform: translate(-50%, -50%);
    border-radius: 8px;
  }
  @include desktop {
    width: 374px;
    height: 444px;
    left: 50%;
    top: 50%;
    padding: 30px;
    transform: translate(-50%, -50%);
    border-radius: 8px;
  }

  .header{
    font-family: "Pretendard", "Nanum Gothic", serif;
    display: grid;
    gap: 10px;
    .header-title{
      font-weight: 700;
      font-size: 18px;
      line-height: 140%;
      color: #141617;
    }
    .header-desc{
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: #81898F;
    }
  }

  .body{
    width: 100%;
    padding: 16px;
    display: grid;
    gap: 15px;
    font-family: 'Pretendard', "Nanum Gothic", serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    background: #F2F6F8;
    border-radius: 8px;
    .row{
      display: flex;
      justify-content: flex-start;
      gap: 6px;
      .body-title{
        width: 72px;
        color: #81898F;
      }
      .body-content{
        @include mobile{
          width: 195px;
        }
        color: #141617;
      }
      .--non {
        color: #81898F;
      }
    }
  }
  .ctaContainer{
    display: grid;
    gap: 20px;
    .cta{
      width: 100%;
      border-radius: 8px;
      font-family: "Pretendard", "Nanum Gothic", serif;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 140%;
      cursor: pointer;
    }
    .confirm{
      font-weight: 700;
      font-size: 16px;
      height: 56px;
      background: #E8344E;
      color: #FFFFFF;
    }
    .back{
      font-size: 14px;
      font-weight: 500;
    }
  }

}

.modal.white {
  background-color: $white;
}
