@import '../../../_styles/utils';

.course_rcmd__wrapper {
  width: 100vw;
  min-height: 100%;
  padding: 40px 0;
  background-image: var(--bg-image);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @include desktop {
    padding: 80px 0;
  }

  .course_rcmd__header {
    margin-bottom: 30px;

    h1 {
      font-family: "Pretendard";
      font-weight: bold;
      line-height: 1.5;
      font-size: 18px;
      color: $gray500;
      @include desktop {
        font-size: 20px;
      }
    }

  }

  .course_rcmd__body {
    padding: 24px;
    margin-bottom: 30px;
    background: #FFF1E4;
    border-radius: 8px;
    width: calc(100% - 40px);
    @include desktop {
      padding: 30px;
      width: auto;
    }

    &__img {
      width: 211px;
      margin-bottom: 30px;
    }

    &__container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      @include desktop {
        width: auto;
        flex-direction: row;
      }
    }

    .course_rcmd__card {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      gap: 10px;
      @include desktop {
        justify-content: center;
        background: $white;
        flex-direction: column;
        padding: 20px 0px;
        border-radius: 8px;
        width: 180px;
      }

      &__thumb {
        width: 20px;
      }

      &__detail {
        font-family: "Pretendard";
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        width: auto;
        margin: 0;
        color: $gray500;

        @include desktop {
          // width: 150px;
        }

        br {
          display: none;
          @include desktop {
            display: block;
          }
        }
      }
    }
  }

  .course_rcmd__footer {
    img {
      width: 234px;
      @include desktop {
        width: 250px
      }
    }

    p {
      margin-top: 10px;
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.5;
      color: $gray500;
      @include desktop {
        font-size: 16px;
        margin-bottom: 30px;
      }

      b {
        color: $ddingdong-primary-color;
        font-weight: bold;
      }
    }

    button {
      color: $white;
      background: $ddingdong-primary-color;
      border-radius: 100px;
      width: 320px;
      height: 48px;
      font-family: "Pretendard";
      font-size: 16px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: -0.54px;
      text-align: center;
      outline: none;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}