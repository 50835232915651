@import '../../../../_styles/utils';

.CodingRaceTop {
  max-width: 100vw;
  height: 15vh;
  display: grid;

  .CodingRaceSkyContainer {
    grid-column: 1;
    grid-row: 1;

    img {
      width: 100%;
    }
  }

  .CodingRaceMessageContainer {
    grid-column: 1;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -2%;

    .CodingRaceMessageText {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -30px;

      p {
        font-size: 16px;
        font-family: $spoqa-hans-neo;
        font-weight: bolder;
        span {
          color: #E8344E;
        }

      }

    }

    .CodingRaceMessageCTA {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      border: solid 2px #e8344e;
      background-color: #e8344e;
      width: 264px;
      height: 62px;
      color: #f2f2f2;
      font-size: 20px;
      font-weight: bold;
      font-family: $spoqa-hans-neo;
      position: absolute;
      margin-top: 50px;
    }

    .CodingRaceMessageCTA:hover {
      cursor: pointer;
    }
  }

}
