@import '../../_styles/utils';

@import '../../_styles/utils';

.pageContainer {

  .pageTitleContainer {
    margin: 30px auto 0 auto;
    text-align: center;
    line-height: 45px;
    letter-spacing: -0.2em;

    @include mobile {

    }

    @include tablet {

    }

    @include desktop {

    }

    .subtitle {
      display: block;
      font-size: 30px;
      font-family: 'Black Han Sans', sans-serif;
      color: black;
      @include mobile {
        font-size: 30px;
      }
    }

    .maintitle {
      display: block;
      color: black;
      font-size: 46px;
      font-family: 'Black Han Sans', sans-serif;


      div.underline {
        font-size: 46px;
        font-family: 'Black Han Sans', sans-serif;
        display: inline-block;
        height: 40px;
        border-bottom: 10px solid #e8344e;
      }

      @include mobile {
        font-size: 46px;
      }
    }

    .description {
      display: block;
      color: black;
      font-family: 'Black Han Sans', sans-serif;
      @include mobile {
        font-size: 46px;
      }

      .prize {
        display: inline;
        font-family: 'Black Han Sans', sans-serif;
        color: #E8344E;
        @include mobile {
          font-size: 46px;
        }
      }
    }
  }


  .inputContainer {
    width: 350px;
    margin: 20px auto 0px auto;
    border-radius: 6px;
    border: solid 1px #c4c4c4;
    display: flex;
    flex-direction: row;

    @include mobile {
      width: 90%;
      height: 38px;
    }
    @include tablet {
      height: 46px;
    }
    @include desktop {
      height: 46px;
    }


    input[type=text].myInput {
      border: none;
      background-color: white;
      color: #959595;

      @include mobile {
        width: calc(100% - 80px);
        font-size: 14px;
      }
      @include tablet {
        width: calc(100% - 100px);
        font-size: 17px;

      }
      @include desktop {
        width: calc(100% - 100px);
        font-size: 17px;
      }
    }

    a {
      border-radius: 6px;
      color: #4f4f4f;
      background-color: #f5f5f5;
      font-size: 15px;
      font-weight: 500;
      display: flex;
      place-content: center;
      align-items: center;

      @include mobile {
        font-size: 14px;
        width: 120px;
      }
      @include tablet {
        width: 100px;
      }
      @include desktop {
        width: 100px;
      }
    }
  }

  .copyToClipboardContainer {

    margin: 20px auto 0 auto;
    color: black;
    width: 90%;
    font-size: 17px;
    letter-spacing: -1px;
    padding: 15px;

    @include desktop {
      width: 350px;
    }

    .buttonContainer {
      text-align: center;

      button {
        width: 180px;
        height: auto;
        font-size: 22px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: -0.66px;
        padding: 12px 0;
        border-radius: 6px;
        margin-top: 10px;
      }
    }
  }

  .numberContainer {
    margin: 0px auto 0 auto;
    color: black;
    width: 90%;
    font-size: 17px;
    letter-spacing: -1px;
    //padding: 15px;

    @include desktop {
      width: 350px;
    }
  }

  .rtanImageContainer {
    margin: 20px auto 10px auto;
    background-image: var(--bg-image);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 97%;
    height: 390px;

    @include desktop {
      width: 360px;
      height: 393px;
    }
  }

  .eventDescContainer {
    margin: auto;
    width: 350px;
    padding-left: 20px;
    letter-spacing: -1px;

    @include mobile {
      width: 100%;
    }
  }

  .rcmdDashboardContainer {
    margin: 0 auto;
    width: 90%;
    font-size: 13px;
    letter-spacing: -0.05em;
    padding-bottom: 150px;

    @include desktop {
      width: 360px;
    }

    .tableContainer {
      margin-top: 8px;
    }

    table {
      .desc {
        .prize {
          color: #E8344E;
          background-color: #aeb8c2;
          padding: 3px 5px;
          border-radius: 5px;
        }
      }


      .time {
        color: #868E96;
      }
    }


  }
}