@import "../../../_styles/utils.scss";

.RoadmapHomeworkWrapper {
  width: 100%;
  height: 68px;
  border-radius: 10px;
  box-shadow: 4px 5px 12px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 11px 67px;
  margin: 5px 0;

  @include adesktop {
    padding: 11px 17px;
    height: 45px;
  }

  &.disabled {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
    background-color: #dee2e6;
  }

  .RoadmapHomeworkRow {
    height: 100%;

    .RoadmapHomeworkCol {
      img {
        @include adesktop {
          width: 13px;
          height: 16px;
        }
      }

      .lock {
        color: #868e96;
        font-size: 20px;
        margin: 0 auto;
        cursor: not-allowed;

        @include adesktop {
          font-size: 13px;
        }
      }
    }

    .title {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -1.08px;
      color: #212529;

      @include adesktop {
        font-size: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .button {
      height: 100%;
      display: flex;
      align-items: inherit;

      button {
        width: 100%;
        height: 100%;
        border-radius: 10px;

        font-size: 17px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.51px;

        border: 2px solid #13c2c2;
        color: #212529;

        @include adesktop {
          font-size: 13px;
          position: relative;
          padding: 0px 3px;
          text-align: center;
          width: 47px;
        }
      }

      button:hover, button:active {
        background-color: $hover-color;
      }

      button.done {
        background-color: #13c2c2;
        color: #ffffff;
      }

      button.done:hover, button.done:active {
        background-color: $hover-color-deeper;
        border-color: $hover-color-deeper;
        color: #ffffff !important;
      }

      .checked{
        color: #ffffff;
        @include adesktop {
          display: none;
        }
      }
    }
  }
}
