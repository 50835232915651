@import "../_styles/utils.scss";

$color-background: #ffffff;
$color-gray: #f1f3f5;
$color-base: rgba(58, 174, 158, 1);
$color-black: rgba(0, 0, 0, 1);
$color-white: rgba(255, 255, 255, 1);
.navBg {
  width: 100%;
  background-color: $color-background;

  &.gray {
    background-color: $color-gray;

    .ant-menu-horizontal {
      border-bottom: 1px solid $color-gray;
    }

    .navContainer {
      background-color: $color-gray;
    }

    .ant-menu {
      background-color: $color-gray;
    }
  }
}

.navContainer {
  z-index: 999;
  background: $color-background;
  height: 56px;
  line-height: 62px;
  max-width: $maxWidth;
  width: 100%;
  margin: auto;
  border-bottom: 0.5px solid black;

  @include tablet {
    //height: 119px;
    //line-height: 119px;
    width: 90%;
  }

  @include desktop {
    height: 85px;
    line-height: 85px;
  }

  .btnBack {
    font-size: 22px;
  }

  @include mobile {
    padding-left: 30px;
    padding-right: 30px;

    a {
      height: 62px;
      line-height: 62px;
    }
  }

  @include mobile {
    padding-left: 10px;
    padding-right: 10px;

    a {
      height: 62px;
      line-height: 62px;
    }
  }

  .logo {
    padding: 0;
    border-bottom: none !important;
    margin-bottom: 20px;

    @include tablet {
    }

    .logoWrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;

      @include mobile {
        padding-left: 5px;
        margin-top: 14px;
      }

      .scc-logo {
        width: 22px;
        height: 35px;
        background-size: cover;
        background-image: var(--bg-image);

        @include mobile {
          width: 19px;
          height: 30px;
        }
      }

      .pixel-font {
        margin-left: 9px;
        width: 110px;
        height: 39px;
        font-family: "webpixel";
        font-size: 38px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        color: #e8344e;

        @include mobile {
          font-size: 28px;
          font-weight: 700;
          letter-spacing: -2px;
        }
      }
    }

    .logoImg {
      img {
        margin: 0;
        height: 50px;

        @include mobile {
          padding-left: 10px;
          height: 25px;
        }
      }
    }

    .btnBack {
      line-height: 62px;
    }

    @include desktop {
      .btnBack {
        line-height: 99.67px;
      }
    }
  }

  .logo.ant-menu-item-active {
    border: none;
  }

  .logo a {
    color: #495057;
    font-weight: bold;
  }

  .menuitems {
    float: right;
    border: none;
    @include desktop {
      margin-left: 1%;
      font-size: 15px;
      font-weight: bold;
    }

    @include mobile {
      margin-left: 1%;
      font-size: 14px;
      padding: 0px 10px;
    }
  }

  .menuitems_isActive {
    float: right;
    border: none;
    font-weight: bold;
    color: #e8344e !important;
    @include desktop {
      margin-left: 1%;
      font-size: 15px;
      font-weight: bold;
    }

    @include mobile {
      margin-left: 1%;
      font-size: 14px;
      padding: 0px 10px;
    }
  }

  .menuitems.ant-menu-item-active {
    border: none;
  }

  .userInfo {
    float: right;

    .doneCount {
      color: $color-base;
      font-size: 13pt;
      font-weight: 600;
    }

    .username {
      color: $color-black;
    }
  }

  .settings {
    float: right;
    color: $color-black;
    margin-right: 10px;

    .ant-menu-submenu-title {
      padding: 0 0 0 10px;
    }

    &.ant-menu-submenu > .ant-menu {
      background: $color-background !important;

      &.ant-menu-sub {
        &.ant-menu-vertical {
        }
      }
    }
  }
}

.ant-menu-horizontal {
  border-bottom: 1px solid $color-background;
}

.ant-menu-title-content {
  img {
    height: 40px;
  }
}

.settings {
  &.ant-menu-submenu-popup {
    background: $color-background;
  }

  &.ant-menu-submenu > .ant-menu {
    background: $color-background;

    .ant-menu-item-group {
      .ant-menu-item-group-title {
        color: #bebebe;
      }

      .ant-menu-item {
        a {
          color: #000000;
        }

        a:hover {
          color: $primary-color;
        }
      }
    }
  }
}
