@import "../../../_styles/utils";

.reg_no {
  width: 100%;
  max-width: $maxWidth;
  margin: 50px auto 120px;
  padding: 0 20px;

  @include desktop {
    padding: 0;
    width: 30%;
  }

  &__h2 {
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: -1.25px;
    color: #000000;
    margin: 0 auto 30px;
  }

  &__content {
    &__p {
      width: 80%;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: -0.6px;
      color: #333333;
      padding: 0;
    }

    &__input_container {
      margin: 30px auto;

      &__input {
        padding: 10px 15px;
        font-size: 16px;
        width: 100%;
        align-self: stretch;
        flex-grow: 0;
        border-radius: 100px;
        border: solid 1px #d4d4d4;
        background-color: #ffffff;
        color: $black;
      }
    }

    &__cta_group {
      &__agree {
        text-align: center;
        color: #868e96;
        font-size: 13px;

        a {
          text-decoration: none;
          color: #868e96;
        }
      }

      &__cta {
        width: 100%;
        padding: 18px 30px;
        border: none;
        border-radius: 100px;
        height: auto;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: -0.66px;
        margin-top: 8px;
        font-size: 18px;
        cursor: pointer;
        text-align: center;
        color: $white;
        background-color: $primary-color;
      }
    }
  }
}
