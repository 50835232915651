@import '../../../_styles/utils';

.VBankInfo-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .VBankInfo-title {
    font-size: 20px;
    font-weight: bold;
    color: $black;
    font-family: NotoSansCJKKR;
    letter-spacing: -0.4px;
    text-align: center;
    margin-top: 32px;
    margin-bottom: 16px;
    @include desktop {
      br {
        display: none;
      }
      font-size: 28px;
      letter-spacing: -0.56px;
      margin-bottom: 12px;
    }
  }

  .VBankInfo-sub {
    text-align: center;
    font-size: 14px;
    font-family: NotoSansCJKKR;
    color: #8b8b8b;
    font-weight: 500;
    @include desktop {
      font-size: 16px;
      margin-bottom: 12px;
      br {
        display: none;
      }
    }
  }

  .VBankInfo-notice {
    text-align: center;
    font-size: 13px;
    font-family: NotoSansCJKKR;
    color: $primary-color;
    font-weight: 500;
    @include desktop {
      font-size: 15px;
      margin-bottom: 32px;
      br {
        display: none;
      }
    }
  }

  .VBankInfo-container {
    width: 90%;
    background: $white;
    display: flex;
    flex-direction: column;
    padding: 24px 32px 4px;
    border-radius: 8px;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.15);
    margin-bottom: 24px;
    @include desktop {
      width: 582px;
      padding: 24px 72px;
      margin-bottom: 32px;
    }
    &__header {
      font-size: 16px;
      font-family: NotoSansCJKKR;
      letter-spacing: -0.32px;
      color: $black;
      font-weight: bold;
      margin-bottom: 16px;
      @include desktop {
        font-size: 20px;
        margin-bottom: 24px;
        text-align: center;
      }
    }

    &__row {
      display: flex;
      flex-direction: row;
      margin-bottom: 14px;

      &__header {
        width: 50%;
        font-size: 14px;
        color: #8b8b8b;
        font-weight: 500;
        font-family: NotoSansCJKKR;
        letter-spacing: -0.42px;
        @include desktop {
          font-size: 18px;
        }
      }

      &__detail {
        width: 50%;
        font-size: 14px;
        color: $black;
        font-weight: 500;
        font-family: NotoSansCJKKR;
        letter-spacing: -0.42px;
        @include desktop {
          font-size: 18px;
        }

        &.--isPrimary {
          color: $primary-color;
          font-weight: bold;
        }
      }
    }
  }

  .VBankInfo-footer {
    margin: 0 auto 77px;

    &__title {
      text-align: center;
      color: $primary-color;
      font-size: 14px;
      font-family: NotoSansCJKKR;
      font-weight: 500;
      letter-spacing: 0.18px;
      margin-bottom: 8px;
      @include desktop {
        font-size: 16px;
        margin-bottom: 12px;
      }
    }

    &__cta {
      padding: 12px 24px;
      border-radius: 24px;
      background-image: linear-gradient(221deg, #ff6c7a, #e8344e);
      border: none;
      color: $white;
      font-weight: bold;
      letter-spacing: 0.18px;
      font-family: NotoSansCJKKR;

      img {
        margin-left: 4px;
      }
      @include desktop {
        font-size: 16px;
      }
    }
  }
}