@import '../../../../../../_styles/utils';

.SpaCharacterContainer {
  display: flex;
  justify-content: center;
  position: absolute;
  margin-top: -30px;

  .SpaUserInfoContainer {
    position: absolute;
    margin-top: -100%;

    .SpaUserInfo {
      position: relative;
    }
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    position: relative;
    //margin-top: -100%;
  }
}