@import '../../_styles/utils';
.wrapper {
  margin: 0;
  padding: 0;
  width: 100%;
  padding-bottom: 500px;
  background-color: white;

  .pageContainer.signup {
    height: 100%;

    .leftContainer {

        .contentWrapper {
        padding: 65px 20px;

        @media (min-width: 1200px) {
            padding-top: 130px;
        }
        }
    }
  }

  .pageContainer {
    height: calc(100% - 48px);

    @media (min-width: 1200px) {
      display: flex;
      flex-direction: row;
    }

    .leftContainer {
      @media (min-width: 1200px) {
        margin-left: calc(50% - 450px);
      }

      .contentWrapper {
        padding: 98px 20px;

        @media (min-width: 1200px) {
          width: 360px;
          margin: auto;
          padding-top: 260px;
        }

        h3 {
          font-size: 25px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.58;
          letter-spacing: -1.25px;
          color: #000000;
          margin: 0;
        }

        .formWrapper {
          padding-top: 70px;
          label {
            font-size: 15px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: -0.6px;
            color: #333333;
            padding: 0;
          }

          button {
            width: 100%;
            height: auto;
            font-size: 22px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.36;
            letter-spacing: -0.66px;
            padding: 12px 0;
            border-radius: 6px;
            margin-top: 10px;
          }
        }
      }
    }

    .rightContainer {
      display: none;

      @media (min-width: 1200px) {
        display: block;
        background-color: $input-background-color;
        width: calc(100% - 1040px);
        z-index: -1;
      }
    }
  }
}


.errorMsg{
  color: #ff4d4f;
  clear: both;
  min-height: 24px;
  padding-top: 0px;
  font-size: 14px;
  line-height: 1.5715;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  margin-top: -15px;
  margin-bottom: 0;
}