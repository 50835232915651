@import "../../_styles/utils.scss";

li,
ul {
  padding: 0;
}

dd,
dl {
  margin-bottom: 0;
}

.footer {
  width: 100%;
  background: #000000;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(0.8px);

  @include desktop {
    padding: 24px 0;
  }

  &__wrap {
    width: 100%;
    display: block;
    @include desktop {
      width: $maxWidth;
    }
  }

  &__menu {
    width: 100%;
    @include desktop {
      display: flex;
      justify-content: space-between;
    }
  }

  &__main {
    width: 100%;
    font-size: 13px;
    color: #d4d4d4;
    display: flex;
    flex-wrap: wrap;
    row-gap: 6px;
    margin-bottom: 16px;

    @include desktop {
      font-size: 14px;
      height: inherit;
      align-items: center;
      margin: 0;
    }

    &__item {
      margin-right: 24px;
    }

    &__link {
      color: #d4d4d4;
      @include stop-hover-moving;
    }

    &__link:hover {
      color: #ffffff;
      font-weight: bold;
    }
  }

  &__sns {
    display: flex;

    &__link {
      display: inline-block;
      width: 30px;
      height: 30px;
      margin-right: 12px;
      background-size: 100% 100%;
      text-indent: -9999px;
    }
  }

  &__divider {
    width: 100%;
    border: 0.5px solid #333;
    margin: 23px 0;
  }

  &__info {
    width: 255px;
    @include desktop {
      width: 536px;
      display: flex;
    }

    &__list {
      display: flex;
      font-size: 12px;
      margin-bottom: 12px;

      @include desktop {
        margin-bottom: 0;
      }

      &__item {
        margin-right: 12px;
        @include desktop {
          margin-right: 24px;
        }
      }
    }

    &__link {
      color: #d4d4d4;
      @include stop-hover-moving;
    }

    &__link:hover {
      color: #ffffff;
      font-weight: bold;
    }
  }

  &__dropdown {
    &__title {
      height: 18px;
      display: flex;
      align-items: center;
      font-weight: normal;
      font-size: 12px;
      letter-spacing: normal;
      color: #d4d4d4;
      margin: 13px 0 9px 0;
      @include desktop {
        margin: 25px 0 9px 0;
      }
    }

    &__button {
      width: 18px;
      height: 18px;
      @include desktop {
        display: none;
      }
    }

    &__info {
      font-size: 12px;
      color: #8b8b8b;
      line-height: normal;
      display: block;

      @include desktop {
        display: block;
        width: 665px;
        white-space: normal;
      }

      &__list {
        display: flex;
        @include desktop {
          display: inline-flex;
        }
      }

      &__divider {
        display: none;
        @include desktop {
          display: inline-block;
          vertical-align: middle;
          height: 10px;
          margin: 0 5px 1px 5px;
          border-right: 1px solid #8b8b8b;
        }
      }
    }
  }

  &__copyright {
    font-size: 12px;
    color: #8b8b8b;
    padding-top: 16px;
    margin-bottom: 0;
    @include desktop {
      text-align: right;
    }
  }
}
