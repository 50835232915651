@import "../../../_styles/utils";

.roadmap-page-bg {
  width: 100%;
  //height: 100vh;
  padding-bottom: 100px;
  background-color: $pale-gray;

  &.pirates {
    background-color: #141617;
  }
}

.roadmap-page {
  width: 100%;
  max-width: 355px;
  margin: auto;
  background-color: $pale-gray;

  &.pirates {
    background-color: #141617;
  }
  
  @include tablet {
    width: 100%;
    max-width: 1192px;
    padding: 0 20px;
  }

  @include desktop {
    width: 100%;
    max-width: 1192px;
    padding: 0 20px;
  }

  &__header {
    padding: 38px 0 38px;
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin:0;

    &__container {
      display: flex;
      flex-direction: column;

      &.--reverse {
        flex-direction: column-reverse;
      }
    }

    &__main {
      width: 830px;
      max-width: 100%;
    }

    &__sub {
      width: 290px;

      &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;

        &--one {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-bottom: 16px;
          width: 100%;

          background-color: $white;
          border-radius: 25px;

          &__header {
            font-family: $spoqa-hans-neo;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: -0.6px;
            text-align: left;
            color: $black;
            margin: 32px 18px 0;
          }

          &__content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          }
        }
      }
    }
  }
}
