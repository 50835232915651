@import '../../../../_styles/utils';

.roadmap-monster-dashboard-container {
  background-size: 100% 100%;
  height: 240px;
  padding: 0 75px 0 28px;
  border-radius: 24px;
  border: solid 1.6px $black;
  overflow: hidden;
  margin-bottom: 11px;
  position: relative;

  &__versus {
    position: absolute;
    left: 50%;
    top: 50px;
    width: 386px;
    height: 32px;
    display: flex;
    transform: translate(-50%, -50%);
    background: $black;
    padding: 4px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
    border-radius: 24px;

    &__left {
      min-width: 25%;
      max-width: 45%;
      background: #E2422E;
      border-radius: 24px;
      font-size: 16px;
      font-weight: bold;
      font-family: NotoSansCJKKR;
      color: $white;
      display: flex;
      flex-direction: row;
      box-sizing: border-box;
      padding-right: 8px;
      justify-content: flex-end;
      align-items: center;
    }

    &__divider {
      font-family: NotoSansCJKKR;
      color: $white;
      font-size: 16px;
      font-weight: bold;
      line-height: 23px;
      margin: 0 8px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    &__right {
      flex: 1;
      background: #3DB23D;
      border-radius: 24px;
      font-size: 16px;
      font-weight: bold;
      font-family: NotoSansCJKKR;
      color: $white;
      display: flex;
      flex-direction: row;
      box-sizing: border-box;
      padding-left: 8px;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .go-roadmap-button {
    font-family: Pretendard;
    position: absolute;
    right: 30px;
    top: 19px;
    padding: 8px 14px;
    flex-direction: column;
    gap: 10px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.60);
    color: $white;
    @media (max-width: 498px) {
      display: none;
    }

    &:hover{
      color: $white;
    }
    &__icon{
      width: 16px;
      height: 100%;
      padding: 0 0 2px 0;

    }
  }

}