@import '../../../../_styles/utils';

.colleague-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 15px 36px;
  border-radius: 100px;
  background-color: $pale-gray;
  position: relative;

  &.pirates {
    background-color: #222425;
  }

  &__detail {
    font-family: $spoqa-hans-neo;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.96px;
    text-align: left;
    color: black;

    &.pirates {
      color: #9DA7AE;
    }

    &__num {
      margin: 0 5.7px 0 0;
      font-family: $spoqa-hans-neo;
      font-size: 28px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -1.68px;
      text-align: center;
      color: black;

      &.pirates {
        color: #EEF3F6;
      }
    }
  }

  &__profiles {
    position: absolute;
    top: -18px;
    right: 0;
    background-color: white;
    border-radius: 100px;

    &.pirates {
      background-color: rgba(0, 0, 0, 0);
    }

    img {
      margin-left: -5px;
      width: 32px;
      height: 32px;
    }
  }
}