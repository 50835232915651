@import '../../../../_styles/utils';

.nav-component-container {
  position: relative;
  display: flex;
  justify-content: center;
  margin-left: 24px;
  padding: 15px 0;

  .nav-component {
    display: flex;
    align-items: center;
    cursor: pointer;

    &__img {
      width: 30px;
      height: 30px;
      object-fit: contain;
      margin-bottom: 4px;
    }

    &__text {
      margin: auto 0 auto 16px;
      font-family: $spoqa-hans-neo;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.42px;
      color: black;
    }
  }

  .nav-component-detail {
    z-index: 999;
    position: absolute;
    width: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 24px;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px black;
    background-color: white;
    padding: 32px;

    top: 60px;

    &__header {
      font-family: $spoqa-hans-neo;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      font-size: 16px;
      letter-spacing: -0.48px;
      text-align: left;
      color: $primary-color;
      margin-bottom: 24px;
    }

    &__content {
      width: 100%;
    }

    &__cta {
      margin-top: 24px;
      cursor: pointer;
      font-family: $spoqa-hans-neo;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: black;

      padding: 21px;
      border-radius: 100px;
      background-color: white;
      border: solid 2px black;
    }
  }

  .nav-component-detail--left {
    left: 0;
  }

  .nav-component-detail--right {
    right: 0;
  }
}

