@import '../../../../_styles/utils';

.RtannyGroundContainer {
  position: relative;
  .dotted {
    margin-top: 16px;
    width: 100%;
    height: 2px;
    background-image: linear-gradient(to right, black 50%, rgba(255, 255, 255, 0) 0%);
    background-size: 10px 2px;
    background-repeat: repeat-x;
    @include desktop {
      margin-top: 32px;
    }
  }
}
