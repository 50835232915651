@import "../../../../../../_styles/utils";

.classCardSelect-wrapper {
  margin: 16px 0;
  width: 100%;

  .classCardSelect-selectWrapper {
    margin-bottom: 16px;
  }

  .classCardSelect__title {
    font-family: $spoqa-hans-neo;
    font-size: 16px;
    margin-left: 0;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .classCardSelect__detail {
    font-family: $spoqa-hans-neo;
    color: $primary-color;
    font-size: 14px;
    font-weight: 500;
  }
}