@import '../../../../_styles/utils';

.broadcast-list {
  width: 330px;
  height: 296px;
  border-radius: 16px;
  border: solid 1px $black;
  background-color: $white;

  overflow-y: scroll;
  position: absolute;
  top: 8px;
  z-index: 999;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    height: 84px;
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.15);
  }
}

.broadcast-list-bg {
  display: block;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}