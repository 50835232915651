@import '../../../_styles/utils';

.wil-page {
  max-width: 100%;
  margin: auto;
  background: #e9ecef;
  height: 100%;
  overflow: auto;
  padding-bottom: 187px;

  &__nav {
    max-width: calc((92% - 80px));
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 77px;
    margin: 0 auto;
    position: relative;
    width: 100%;
    z-index: 2;
    @include desktop {
      justify-content: left;
    }
    @include lectureNav {
      justify-content: space-around;
      max-width: 100%;
    }

    &__bg {
      width: 100vw;
      height: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;

      &.pirates {
        background-color: #222425;
      }
    }
  }
}