@import "../../../../_styles/utils";

.roadmap-dashboard {
  background-size: 100% 100%;
  height: 240px;
  padding: 0 75px 0 28px;
  border-radius: 24px;
  border: solid 1.6px $black;
  overflow: hidden;
  margin-bottom: 11px;
  @include mobile {
    height: 120px;
    width: 360px;
    background-size: 100% 100%;
    padding: 0 30px 0 28px;
    border-radius: 10px;
  }

  &__chars {
    height: 130px;
    position: relative;
    width: calc(100% + 56px);
    margin-left: -28px;
    margin-bottom: 10px;
    @include mobile {
      height: 55px;
    }

    &__dino {
      &__img {
        height: 90px;
        position: absolute;
        bottom: -1px;
        left: -82px;
        z-index: 1;
      }
    }
  }

  &__weeks {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;

    &__plain-bar {
      position: absolute;
      width: var(--full-length);
      height: 12px;
      background-color: #4b920c;
      bottom: 15px;
      left: 10px;
      z-index: 0;
      @include mobile {
        bottom: 2px;
        left: 25px;
        height: 8px;
      }

      &--active {
        position: absolute;
        width: var(--length);
        height: 12px;
        background-color: #e8505b;
        @include mobile {
          height: 8px;
        }

        &__rtan {
          position: absolute;
          bottom: 18px;
          left: var(--length);
          z-index: 19 !important;
          right: -18px;
          width: fit-content;
          @include mobile {
            padding-left: 15px;
            bottom: 0px;
          }

          &__img {
            height: 75px;
            @include mobile {
              height: 48px;
            }
          }
        }

        &__pass {
          position: absolute;
          left: var(--length);
          z-index: 19 !important;
          right: -18px;
          width: fit-content;
          bottom: 40px;

          &__week {
          }
        }
      }
    }

    &__week {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;

      &__dino {
        &__img {
          height: 90px;
          position: absolute;
          bottom: 18px;
          left: -70px;
          z-index: 10;
          @include mobile {
            height: 50px;
            bottom: 0px;
            left: -45px;
          }
        }
      }

      &__img {
        width: 62px;
        height: 75px;
        object-fit: contain;
        z-index: 9;
        @include mobile {
          height: 13px;
          width: 15px;
        }
        &__first {
          @include mobile {
            height: 42px;
          }
        }

        &__last {
          object-fit: contain;
          z-index: 9;
          @include mobile {
            height: 42px;
          }
        }
      }

      &__fin {
        display: none;
        &__done {
          display: block;
          width: 70px;
          height: 70px;
          position: absolute;
          top: -75px;
        }
        width: 70px;
        height: 70px;
        position: absolute;
        top: -75px;
      }

      &__title {
        font-family: $spoqa-hans-neo;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.54px;
        text-align: center;
        color: $black;
        padding: 7px 28px;

        &:hover {
          //padding: 7px 28px;
          border-radius: 100px;
          background-color: $pale-gray;
        }
      }

      &:hover {
        img {
          display: block;
        }
      }
    }

    &__benefit {
      position: absolute;
      right: 31px;
      top: -69px;

      @include mobile {
        width: 57px;
        height: 48.86px;
        right: 26px;
        top: -49px;
      }
    }

    &__desc {
      position: absolute;
      top: -120px;
      left: 50%;
      transform: translateX(-50%);
      padding: 4px 16px;
      border-radius: 100px;
      //box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.15);
      background-color: $black;
      z-index: 9;
      white-space: nowrap;

      font-family: NotoSansCJKKR;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.96px;
      text-align: center;
      color: $white;
    }

    &__chat {
      position: absolute;
      top: -115px;
      z-index: 8;
      display: flex;
      align-items: center;

      &.left {
        right: 0;
      }

      &.right {
        left: 0;
      }

      &__thumb {
        object-fit: contain;
        width: 36px;
        height: 36px;
      }

      &__text {
        margin-left: 14px;
        padding: 12px 16px 10px;
        border-radius: 100px;
        //box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        background-color: #7873e1;
        color: $white;
        font-family: $spoqa-hans-neo;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.48px;
        margin-top: 2px;
        width: 227px;
        text-align: center;
      }

      &__arrow {
        position: absolute;
        bottom: 11.5px;
        left: 44px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 15px solid #7873e1;
        transform: rotate(-19deg);
      }
    }
  }
}

.modal-content {
  &__text {
    font-family: $spoqa-hans-neo;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.96px;
    text-align: center;
    color: $black;
    white-space: pre-wrap;
  }

  &__textarea {
    width: 394px;
    height: 160px;
    margin: 24px 0 0;
    padding: 24px 18px;
    border-radius: 24px;
    border: solid 1px #adb5bd;
    background-color: $white;
    resize: none;
    font-family: $spoqa-hans-neo;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -0.96px;
    text-align: left;
    color: $black;

    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    &::placeholder {
      font-family: $spoqa-hans-neo;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.96px;
      text-align: left;
      color: #adb5bd;
    }
  }
}

.go-roadmap-button {
  font-family: Pretendard;
  position: absolute;
  right: -30px;
  top: 19px;
  padding: 8px 14px;
  flex-direction: column;
  gap: 10px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.60);
  color: $white;
  @media (max-width: 498px) {
    display: none;
  }

  &:hover{
    color: $white;
  }
  &__icon{
    width: 16px;
    height: 100%;
    padding: 0 0 2px 0;

  }
}
