@import '../../../../../_styles/utils';


.course_rcmd__question {
  margin-bottom: 8px;
  width: 100%;
  border-radius: 8px;
  border: solid 1px #eaebed;
  padding: 16px 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  &.pirates {
    border: solid 1px #81898F;
  }

  &__icon {
    margin-right: 10px;

    path {
      fill: #eaebed;
    }

    &.pirates {
      path {
        fill: #5F666B;
      }

      &.--selected,
      &:hover {
        path {
          fill: #EEF3F6;
        }
      }
    }
  }

  &__desc {
    font-size: 15px;
    font-weight: 500;
    color: #505254;
  }

  &.--no-icon {
    justify-content: center;
  }

  .course_rcmd__desc {
    &.pirates {
      color: #EEF3F6;
    }
  }

  &.--selected,
  &:hover {
    background: $primary-color;

    .course_rcmd__question__icon {
      path {
        fill: $white;
      }
    }

    &.pirates {
      border: solid 1px rgba(0, 0, 0, 0);
    }

    .course_rcmd__desc {
      color: $white;

      &.pirates {
        color: #EEF3F6;
      }
    }
  }
}