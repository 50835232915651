@import '../../../../_styles/utils';

.lec-player {
  max-width: 850px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: #e9ecef;

  &__iframe-div {
    position: relative;
    padding-top: 56%;

    &__iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  &.dual {
    // 100% 영역에서 왼쪽 fixed bar를 제외하고 92%
    max-width: calc(92% - 53px);
    max-height: calc(100vh - 100px);

    .lec-player__iframe-div {
      padding-top: calc(100vh - 100px);
    }

  }
}