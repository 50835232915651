@import "../../../../_styles/utils";

.bg {
  display: none;
  z-index: 99;
  left: 0;
  position: absolute;
  top: -100vh;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  @include mobile{
    position: fixed;
    top: 0;
    height: 100%;
  }
  @include tablet {
    position: fixed;
    top: 0;
    height: 100%;
  }
  @include desktop {
    position: fixed;
    top: 0;
    height: 100%;
  }
}

.--active {
  display: block !important;
}

.--active--flex {
  display: flex !important;
  flex-direction: column;
}

.modal {
  width: 96%;
  overflow: visible;
  max-height: 100vh;
  display: none;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%);
  z-index: 999;
  margin: auto;
  background-color: white;
  border-radius: 24px;
  border: none;
  padding: 32px;
  align-items: center;
  justify-content: center;

  &.square {
    border-radius: 8px;
    width: 322px;
    padding: 32px;
  }

  &.pirates {
    width: fit-content;
    padding: 20px 20px 28px;
    border-radius: 24px;
  }

  &.progress {
    height: 100vh;
    position: fixed;
    top: 50%;  /* 중앙에서부터 시작하도록 조정 */
    left: 50%;  /* 중앙에서부터 시작하도록 조정 */
    transform: translate(-50%, -50%);  /* 화면 중앙에 배치하도록 조정 */
    @include desktop {
      height: fit-content;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);  /* 주석 해제 */
    }
  }

  &.gpt {
    width: fit-content;
    padding: 20px;
    border-radius: 20px;
    @include desktop {
      padding: 40px;
    }
  }

  &.nb {
    width: fit-content;
    padding: 30px;
    border-radius: 20px;
    @include desktop {
      padding: 40px;
    }
  }

  @include tablet {
    width: 518px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
  }
  @include desktop {
    width: 518px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
  }

  &__header {
    font-family: $spoqa-hans-neo;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    white-space: pre-line;
    text-align: center;
    color: $black;
    margin-bottom: 24px;
    word-break: keep-all;
  }

  &__img {
    height: 156.6px;
    object-fit: contain;
    margin-bottom: 24px;

    &__fit {
      width: 100%;
      object-fit: contain;
      height: 200px;
    }

    &__fit-h {
      width: 30%;
    }

    &__fit-120 {
      width: 120px;
      margin-bottom: 20px;
    }

    &__fit-53 {
      width: 53px;
      margin-bottom: 20px;
    }

    &__fit-pirates {
      width: 280px;
      margin-bottom: 24px;
      @include desktop {
        width: 320px;
        margin-bottom: 32px;
      }
    }
    &__fit-gpt {
      width: 268px;
      margin-bottom: 24px;
      @include desktop {
        width: 268px;
        margin-bottom: 32px;
        border-radius: 12px;
      }
    }
    &__fit-nb {
      width: 154px;
      margin-bottom: 20px;
    }
  }

  &__video {
    width: 470px;
    margin-bottom: 24px;
  }

  &__content {
    width: 100%;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    text-align: center;
    color: $black;
    margin-bottom: 24px;
    white-space: pre-wrap;
    word-break: keep-all;
    @include desktop {
      font-size: 18px;
    }

    &.nb {
      width: fit-content;
      margin-bottom: 30px;
    }

    &__text {
      line-height: 1.5;
      p {
        font-family: NotoSansCJKKR;
        font-size: 20px;
        color: $black;
        font-weight: bold;
      }
    }

    &__sub_text {
      line-height: 1.5;
      span {
        background-color: #7873e1;
        padding: 4px 10px;
        border-radius: 24px;
        margin-right: 8px;
        margin-top: 24px;
        font-family: NotoSansCJKKR;
      }

      font-family: NotoSansCJKKR;
      font-size: 16px;
      font-weight: 500;
      color: #7873e1;
    }
  }

  &__cta-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 290px;

    &.pirates {
      width: 100%;
      gap: 8px;
    }

    &.nb {
      width: 268px;
    }
  }

  .white-square {
    width: 274px;
    white-space: nowrap;
    padding: 16px 24px;
    border-radius: 8px;
    border: solid 1px #eaebed;
    background-color: #fff;
    margin-top: 12px;
    font-family: "Pretendard";
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #3b3b3b;
  }

  .black-square {
    width: 274px;
    padding: 16px 24px;
    border-radius: 8px;
    background-color: #000;
    margin-top: 12px;
    border: solid 1px #000;
    white-space: nowrap;
    font-family: "Pretendard";
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #fff;
  }

  &__cta {
    cursor: pointer;
    font-family: $spoqa-hans-neo;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    text-align: center;
    color: $white;
    padding: 20px 31px;
    border-radius: 100px;
    border: solid 2px $black;
    background-color: $black;

    &.pirates-sub {
      border: none;
      font-family: Pretendard;
      font-weight: 700;
      line-height: 150%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #141617;
      padding: 0;
      width: 88px;
      background: #e4ebf0;
      border-radius: 100px;
      font-size: 14px;
      height: 53px;
      @include desktop {
        font-size: 16px;
        height: 56px;
      }
    }
    &.gpt_true {
      width: 164px;
      height: 54px;
      border-radius: 8px;
      background-color: #e8344e;
      border: none;
      font-family: Pretendard;
      font-size: 16px;
      font-weight: 700;
      line-height: 54px;
      color: #ffffff;
      text-align: center;
      padding: 0;
    }
    &.gpt_false {
      width: 94px;
      height: 54px;
      border-radius: 8px;
      background-color: #eef3f6;
      border: none;
      font-family: Pretendard;
      font-size: 16px;
      font-weight: 700;
      line-height: 54px;
      color: #141617;
      text-align: center;
      padding: 0;
    }
    &.nb {
      width: 268px;
      height: 54px;
      border-radius: 8px;
      background-color: #e8344e;
      border: none;
      font-family: Pretendard;
      font-size: 16px;
      font-weight: 700;
      line-height: 54px;
      color: #ffffff;
      text-align: center;
      padding: 0;
    }
    &.pirates-main {
      font-family: Pretendard;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      padding: 0;
      width: 180px;
      height: 56px;
      background: #000000;
      border: 2px solid #000000;
      border-radius: 100px;
      @include desktop {
        font-size: 16px;
        height: 56px;
      }
    }
  }

  &__subCta {
    margin-top: 16px;
    padding-top: 3px;
    font-family: $spoqa-hans-neo;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    text-align: center;
    color: $black;
    cursor: pointer;

    &__img {
      margin-right: 8px;
      width: 24px;
      height: 23.1px;
      object-fit: contain;
    }
  }

  &__tail-info {
    width: 100%;
    font-family: $spoqa-hans-neo;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    text-align: center;
    color: #8b8b8b;
    margin-top: 24px;
    white-space: pre-wrap;
  }
}

.modal.white {
  background-color: $white;
}
