@import '../../_styles/utils';

.wrapper {
  background-color: #f5f5f5;
}

.roadmapPageContainer {
  width: 1157px;
  margin: 40px auto 70px;

  @include adesktop {
    width: 90%;
    margin-top: 15px;
  }

  .event-banner-box {
    width: 100%;
    margin-top: 20px;
    border-radius: 5px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 20px;
    font-weight: bold;
    position: relative;

    img:first-child {
      display: block;
      border-radius: 10px;
      @include adesktop {
        display: none;
      }
    }

    img:nth-child(2) {
      display: none;
      border-radius: 5px;
      @include adesktop {
        display: block;
      }
    }

    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      span:first-child {
        font-weight: 500;
        font-size: 16px;
      }
    }

    .event-banner-price-box {
      position: absolute;
      top: 20%;
      left: 58%;
      white-space: nowrap;
      width: 30%;
      @import url('https://fonts.googleapis.com/css2?family=Yeon+Sung&display=swap');

      * {
        font-family: 'Yeon Sung', cursive;
      }

      div {
        text-align: left;
        display: inline-block;
        vertical-align: middle;
      }

      .minus {
        width: 10%;
        font-size: 49px;
        font-weight: normal;
      }

      .equal {
        width: 15%;
        font-size: 49px;
        font-weight: normal;
      }

      .price-detail {
        width: 48%;

        p {
          line-height: 1;
          margin-bottom: 5px;
          font-size: 18px;
          font-weight: normal;

          b {
            font-size: 24px;
            font-weight: normal;
          }
        }

        p.point {
          margin-left: 10px;
          font-size: 24px;
          margin-bottom: 0;

          b {
            font-size: 32px;
          }
        }
      }

      .final-price {
        width: 20%;

        p {
          margin-bottom: 0;
          font-size: 24px;
          font-weight: normal;

          b {
            font-size: 32px;
            font-weight: normal;
          }
        }

      }
    }

    img {
      width: 100%;
    }

    .right {
      img {
        width: 140px;
        height: 54px;
      }
    }
  }


  .weekContentWrapper {
    margin: 22px 0;
  }
}

.name-modal-body {
  width: 100%;

  .header {
    width: 100%;
    margin: 20px auto 0px auto;
    text-align: center;

    h2 {
      font-size: 25px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -1.5px;
      color: #212529;
    }

    h4 {
      font-size: 22px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -1.32px;
      color: #212529;
    }
  }

  .header.conversion {
    position: relative;

    h2 {
      font-size: 26px;
      font-weight: bold;
    }

    h4 {
      font-size: 18px;
      font-weight: 500;
    }

    img {
      width: 14px;
      position: absolute;
      top: -5px;
      right: 20px;
      cursor: pointer;
    }
  }

  .desc {
    width: 100%;
    margin: 10px auto 0px auto;
    text-align: center;

    p {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -1.12px;
      color: #495057;
      margin-bottom: 0.5em;
    }

    img {
      width: 100%;
    }

    button {
      padding: 20px 20px;
      margin-top: 20px;
      border-radius: 5px;
      font-size: 15px;
      line-height: 0;
      font-weight: bold;
      background: #e8344e;
    }
  }

  .body {
    .inputContainer {
      margin: 24px auto 11px auto;
      width: 100%;

      input {
        display: block;
        margin: auto;
        width: 336px;
        padding: 12px;
        border-radius: 6px;
        border: solid 1px #c4c4c4;

        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.76;
        letter-spacing: -0.68px;
        text-align: left;
        color: #333333;
      }
    }

    .descContainer {
      text-align: center;

      span {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.84px;
        text-align: left;
        color: #495057;
      }
    }
  }

  .buttonContainer {
    text-align: center;
    margin-top: 28px;

    span {
      cursor: pointer;
      display: block;
      margin: 14px auto;
      width: 120px;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.84px;
      text-align: left;
      color: #868e96;
    }

    button {
      cursor: pointer;

      width: 158px;
      height: 40px;

      font-size: 15px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 700;
      color: #ffffff;
      letter-spacing: normal;
      line-height: 1;

      border-radius: 5px;
      border: none;
      background-color: $primary-color;
    }

    button.inactive {
      background-color: #adb5bd;
    }

    button.active {
      background-color: $hover-color;
    }

    button.inactive:hover {
      background-color: #adb5bd;
    }

    button.active:hover {
      background-color: $hover-color-deeper;
    }

    button.isSending:hover {

    }

    button:hover {
      background-color: $primary-hover-color;
    }
  }

  .explanation {
    text-align: center;

    .exptitleWrapper {
      margin: 10px auto 5px auto;

      span.exptitle {
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.98px;
        color: #495057;
      }

      .expDetailWrapper {
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: -0.91px;
        color: #212529;
      }
    }
  }
}

.modal-body {
  width: 100%;
  @include adesktop {
    position: relative;
  }

  &.forever {
    padding: 20px;
    background-color: #2897fb;

    .header {
      margin-top: 0;

      h2 {
        font-size: 25px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.9px;
        text-align: center;
        color: #ffffff;
        line-height: 1.4;
        @include adesktop {
          font-size: 18px;
        }
      }
    }

    .desc {
      p {
        color: white;
      }

      img.ic-python-forever {
        width: 150.1px;
        height: 127.8px;
        object-fit: contain;
      }
    }

    .buttonContainer {
      button {
        width: auto;
        border-radius: 5px;
        background-color: #ffffff !important;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.42px;
        text-align: center;
        color: #212529 !important;
        padding: 14px 76px;
      }
    }
  }

  .close {
    display: none;
    @include adesktop {
      position: absolute;
      font-size: 14px;
      top: 20px;
      right: 27px;
      cursor: pointer;
      display: block;
    }
  }

  .header {
    width: 100%;
    margin: 20px auto 0px auto;
    text-align: center;

    h2 {
      font-size: 25px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -1.5px;
      color: #212529;
    }

    h4 {
      font-size: 22px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -1.32px;
      color: #212529;

      @include adesktop {
        font-size: 18px;
      }
    }
  }

  .header.conversion {
    position: relative;

    h2 {
      font-size: 26px;
      font-weight: bold;
    }

    h4 {
      font-size: 18px;
      font-weight: 500;
    }

    img {
      width: 14px;
      position: absolute;
      top: -5px;
      right: 20px;
      cursor: pointer;
    }
  }

  .desc {
    width: 100%;
    margin: 10px auto 0px auto;
    text-align: center;

    p {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -1.12px;
      color: #495057;
      margin-bottom: 0.5em;
    }

    img {
      width: 100%;
    }

    button {
      padding: 20px 20px;
      margin-top: 20px;
      border-radius: 5px;
      font-size: 15px;
      line-height: 0;
      font-weight: bold;
      background: #e8344e;
    }
  }

  .weekdays {
    .weekday {
      padding: 11px 120px;
      border-top: 1px solid #ced4da;
      @include adesktop {
        padding: 11px 45px;
      }

      label {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0px;

        .left {
          span.dayname {
            font-size: 17px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: -1.19px;
            color: #212529;
          }

          span.qnanow {
            margin-left: 10px;
            font-weight: 500;
            font-size: 14px;
            padding: 3px 4px;
            border-radius: 4px;
            border: solid 1px $hover-color;
            background-color: #e6fcf5;
            color: $hover-color;
          }
        }

        .right {

        }

        input[type=checkbox] {
          position: relative;
          cursor: pointer;
        }

        input[type=checkbox]:before {
          content: "";
          display: block;
          position: absolute;
          width: 17px;
          height: 17px;
          top: 0;
          left: 0;
          border: 2px solid #555555;
          border-radius: 3px;
          background-color: white;
          @include mobile {
            top: -2px;
            left: -2px;
          }
        }

        input[type=checkbox]:checked:before {
          content: "";
          display: block;
          position: absolute;
          width: 17px;
          height: 17px;
          top: 0;
          left: 0;
          border: 2px solid black;
          border-radius: 3px;
          background-color: black;
          @include mobile {
            top: -2px;
            left: -2px;
          }
        }

        input[type=checkbox]:checked:after {
          content: "";
          display: block;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          position: absolute;
          top: 2px;
          left: 6px;
          @include mobile {
            top: 0px;
            left: 4px;
          }
        }
      }
    }

    .weekday:last-child {
      border-bottom: 1px solid #ced4da;
    }
  }

  .buttonContainer {
    text-align: center;
    margin-top: 28px;

    button {
      cursor: pointer;

      width: 158px;
      height: 40px;

      font-size: 15px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 700;
      color: #ffffff;
      letter-spacing: normal;
      line-height: 1;

      border-radius: 5px;
      border: none;
      background-color: $primary-color;
    }

    button.inactive {
      background-color: #adb5bd;
    }

    button.active {
      background-color: $hover-color;
    }

    button.inactive:hover {
      background-color: #adb5bd;
    }

    button.active:hover {
      background-color: $hover-color-deeper;
    }

    button.isSending:hover {

    }

    button:hover {
      background-color: $primary-hover-color;
    }
  }

  .explanation {
    text-align: center;

    .exptitleWrapper {
      margin: 10px auto 5px auto;

      span.exptitle {
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.98px;
        color: #495057;
      }

      .expDetailWrapper {
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: -0.91px;
        color: #212529;
      }
    }
  }
}

.underline {
  text-decoration: underline;
}