@import '../../../../_styles/utils';

.step-cta {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  width: 268px;
  height: 60px;

  background: #e8344e;
  border-radius: 8px;

  cursor: pointer;

  @include desktop {
    width: 328px;
  }

  img {
    margin-right: 10px;
  }

  &.kakao {
    color: black;
    background-color: #fae301;

    img {
      height: 22px;
      position: relative;
      bottom: 2px;
    }
  }
}