.rc-slider .rc-slider-track {
    background-color: #E8344E;
}

.rc-slider .rc-slider-rail {
    background-color: #c7c9cb;
}

.rc-slider .rc-slider-handle {
    width: 24px;
    height: 24px;
    border: none;
    background: white;
    margin-top: -10px;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.08)
}

.rc-slider .rc-slider-step {
    display: none;
}

.rc-slider .rc-slider-mark {
    top: 25px;
    font-size: 14px;
    white-space: nowrap;
}