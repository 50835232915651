@import '../../../../_styles/utils';

.broadcast-list-elem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 20px;

  &__img-div {
    margin-right: 16px;
    width: 34px;

    &__img {
      width: 34px;
      height: 34px;
      object-fit: contain;
    }
  }

  &__content {
    width: calc(100% - 34px);
    &__title {
      font-family: $spoqa-hans-neo;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.48px;
      text-align: left;
      color: $black;
    }

    &__detail {
      margin-top: 8px;
      font-family: $spoqa-hans-neo;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.42px;
      text-align: left;
      color: $black;
    }
  }
}

.--gray {
  background-color: $pale-gray;
}

.--white {
  background-color: $white;
}