@import '../../../../_styles/utils';

.learntime-container{
  background-color: $white;
  position: relative;
  border-radius: 100px;
  color:#e8344e;
  border: solid 2px #e8344e;
  padding:3px 32px 3px 16px;

  font-size: 16px;
  font-weight: bold;
  img {
    image-rendering: pixelated;
    height: 55px;
    position: absolute;
    right: -12px;
    top: -17px;
  }
  @include lectureNav {
    display: none;
  }
}