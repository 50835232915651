@import "../../../_styles/utils.scss";

.roadmapLectureWrapper {

  &.disabled {
    .header {
      background-color: #dee2e6;
      color: #212529;
    }
  }

  .roadmapLectureCollapse {
    border-radius: 10px;
    box-shadow: 4px 5px 12px 0 rgba(0, 0, 0, 0.05);
  }

  .panel {
    border-radius: 10px;
  }

  .header {
    background-color: #13c2c2;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: -1.08px;
    color: #ffffff;
    padding: 10px 60px;

    display: flex;
    justify-content: space-between;

    @include adesktop {
      padding: 10px 17px;
      font-size: 13px;
    }

    .left {

    }

    .right {

    }
  }

  .body {
    background-color: #ffffff;

    .RoadmapLectureRow {
      padding: 10px 60px;

      @include adesktop {
        padding: 10px 17px;
      }

      .weekInfoWrapper {
        @include adesktop {
          width: 100%;
          flex: none;
          max-width: 100%;
        }
      }

      .title {
        font-size: 22px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.36;
        letter-spacing: -1.32px;
        color: #212529;

        @include adesktop {
          font-size: 20px;
        }
      }

      .info {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: -0.96px;
        color: #212529;
        margin: 6px;
        display: flex;

        @include adesktop {
          font-size: 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .iconContainer {
          display: flex;
          align-items: center;
        }

        span[role=img] {
          margin-right: 10px;
          color: #adb5bd;
          display: inline-block;
        }

        span {
          margin-right: 27px;
          @include adesktop {
            margin-right: 0;
          }
        }

        .material:hover {
          color: $hover-color;
        }

        .materialContainer {
          display: inline-flex;
          flex-direction: row;
          align-items: center;

          .outlinkImage {
            width: 12px;
            vertical-align: top;
            margin-left: 2px;
          }
        }
      }

      .openCollapse {
        font-size: 17px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 3.06;
        letter-spacing: -1.02px;
        color: #000000;
        display: flex;
        align-items: center;

        @include adesktop {
          font-size: 15px;
        }

        span[role=img] {
          margin-left: 13px;
        }

        span.iconContainer {
          font-size: 11px;
        }
      }

    }

    .scoreWrapper {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: -0.96px;
      color: #212529;

      div {
        margin: 10px 0;
      }

      span {
        margin: 0 4px;
      }

      img {
        width: 24px;
        height: 24px;
        margin-right: 4px;
      }
    }

    .whoStudyWithWrapper {
      border-radius: 8px;
      background-color: #f8f9fa;
      height: 100%;
      padding: 15px 22px;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -1.08px;
      color: #212529;

      @include adesktop {
        width: 100%;
        padding: 10px 17px;
        font-size: 13px;
      }

      strong {
        font-size: 30px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.73;
        letter-spacing: -1.8px;
        color: #13c2c2;
        @include adesktop {
          font-size: 18px;
        }
      }
    }

  }

  .lectureContentWrapper {
    border-radius: 5px;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.06);
    border: solid 1px #dee2e6;
    background-color: #ffffff;
    margin: 8px 0;
    padding: 6px 32px 6px 40px;
    cursor: pointer;

    &.disabled {
      background-color: #e9ecef;
      cursor: not-allowed;
    }

    @include adesktop {
      padding: 6px 16px 6px 16px;
    }


    img {
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }

    .order {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.89;
      letter-spacing: -1.08px;
      color: #495057;

      @include adesktop {
        flex: 0 0 9%;
        max-width: 9%;
        font-size: 12px;
      }
    }

    .title {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.89;
      letter-spacing: -1.08px;
      color: #212529;
      text-overflow: ellipsis;

      @include adesktop {
        flex: 0 0 85%;
        max-width: 85%;
        font-size: 13px;
      }
    }

    .time {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.67;
      letter-spacing: -0.9px;
      color: #495057;

      span[role=img] {
        margin-right: 13px;
      }

      @include adesktop {
        display: none;
      }
    }

    .score {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: -0.96px;
      color: #212529;

      span {
        margin: 0 4px;
      }

      @include adesktop {
        display: none;
      }
    }

    .lock {
      color: #868e96;
      font-size: 20px;
      float: right;

      @include adesktop {
        font-size: 13px;
      }
    }
  }
}

.rateContainer {

}
