@import "../../../../_styles/utils";

.lec-nav {
  width: 520px;
  @include desktop {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  @include lectureNav {
    width: 100%;
    max-width: 410px;
  }

  @include mobile {
    width: 80%;
    &__intro {
      width: 70%;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 520px;
    height: 48px;
    flex-grow: 0;
    border-radius: 24px;
    border: solid 1px #adb5bd;
    background-color: #fff;
    cursor: pointer;
    position: relative;
    padding-left: 12px;

    &.pirates {
      background-color: #3A3E41;
      border: 1px solid #81898F;
    }

    &__intro {
      cursor: default;
      border: none;
      justify-content: center;
      @include desktop {
        border-radius: 8px;
        background-color: #eaebed;
      }
    }

    &__not-clickable {
      cursor: default;
    }

    @include lectureNav {
      width: 100%;
      max-width: 410px;
    }

    @include mobile {
      width: 100%;
    }

    &__order {
      min-width: 130px;
      font-size: 18px;
      font-weight: 500;
      color: black;
      text-align: center;

      @include mobile {
        min-width: 40px;
      }

      &.pirates {
        color: #EEF3F6;
      }
    }

    &__text {
      font-family: $spoqa-hans-neo;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -1.08px;
      text-align: center;
      color: $black;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-top: 3px;
      max-width: 300px;

      &.pirates {
        color: #EEF3F6;
      }
    }

    &__bg {
      right: 3px;
      position: absolute;
      width: 42px;
      height: 42px;
      border-radius: 100px;
      background-color: #f1f1f1;
      display: flex;
      align-items: center;
      justify-content: center;

      &.pirates {
        background-color: #81898F;

        svg path {
          fill: #EEF3F6;
        }
      }

      &__img {
        width: 13.8px;
        height: 8.6px;
        object-fit: contain;
      }
    }
  }

  &__list {
    position: relative;

    &__bg {
      display: block;
      position: fixed;
      z-index: 99;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      background-color: rgba(0, 0, 0, 0);
    }
  }
}

.--rotate {
  transform: rotate(-180deg);
}
