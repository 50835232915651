@import "../../_styles/utils.scss";

.border {
  border: 1px solid #e9ecef;
  border-radius: 10px;
}

.wrapper {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  background-color: white;

  .pageContainer.signup {
    .leftContainer {
      margin-bottom: 0px;
      @include desktop {
        margin-bottom: 0;
      }

      .contentWrapper {
        @include desktop {
          //padding: 130px 20px 0;
        }
      }
    }
  }

  .pageContainer {
    margin-bottom: 150px;

    @media (min-width: 1200px) {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin: auto;
    }

    .leftContainer {
      @media (min-width: 1200px) {
        margin: auto;
        width: 50%;
      }

      .leftContentWrapper {
        padding: 100px 24px 0px;
        @media (min-width: 1200px) {
          border-radius: 20px;
          box-shadow: 0 4px 32px rgba(0, 0, 0, 0.12);
          padding: 64px;
          width: 486px;
          margin: 64px auto 64px auto;
        }

        .contentWrapper {
          @include desktop {
            //padding-bottom: 40px;
          }

          @media (min-width: 1200px) {
            width: 100%;
            margin: auto;
          }

          div.rtan_welcome {
            margin-bottom: 69px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            @include desktop {
            margin-bottom: 24px;
          }
            img {
              max-width: 59px;
              max-height: 107px;
            }
          }

          h3 {
            font-size: 25px;
            font-weight: 700;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.58;
            letter-spacing: -1.25px;
            color: #333333;
            margin: 0;
            text-align: center;

            @include mobile {
              font-size: 23px;
            }
          }

          .new__signup__sub {
            text-align: center;
            font-size: 16px;
            line-height: 1.5;
            margin-bottom: 24px;
            font-weight: 500;
          }

          .formWrapper {
            .form-item {
              margin-bottom: 24px;
            }

            .signup-tos {
              text-align: center;
            }

            .kkoLogin {
              img {
                margin-right: 8px;
              }

              button {
                width: 100%;
                padding: 18px 30px;
                border: none;
                border-radius: 8px;
                background-color: #ffe500;
                color: #000000;
                font-size: 16px;
                font-weight: 500;
                cursor: pointer;
                @include desktop {
                  font-size: 18px;
                }
              }
            }

            .start-email {
              margin: 20px auto;
              width: 100%;
              text-align: center;

              span {
                font-size: 15px;
                //text-decoration: underline;
                cursor: pointer;
                color: #000000;
              }
            }

            input {
              font-family: "Pretendard";
              font-size: 14px;
              font-weight: 500;
              color: $gray600;
              height: 53px;

              &::placeholder {
                color: $gray200;
              }
            }

            span.phoneInfo {
              display: block;
              color: $gray400;
              margin-bottom: 24px;
              margin-top: -16px;
            }

            span.nameInfo {
              display: block;
              color: $gray400;
              margin-bottom: 24px;
              margin-top: -16px;
            }

            label {
              font-size: 15px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 2;
              letter-spacing: -0.6px;
              color: #333333;
              padding: 0;
              margin: 0;
            }

            .privacy-form {
              width: 100%;
              margin: 0 auto;
              text-align: center;
              font-size: 14px;
              padding: 18px 0 1px 0;
              color: #000000;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: -0.34px;
              @include desktop {
                font-size: 16px;
                padding: 33px 0 14px 0;
              }
            }

            button {
              width: 100%;
              padding: 18px 30px;
              border: none;
              border-radius: 8px;
              height: auto;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.36;
              letter-spacing: -0.66px;
              margin-top: 40px;
              font-size: 16px;
              cursor: pointer;
              @include desktop {
                margin-top: 24px;
                font-size: 18px;
              }
            }

            div.marketing {
              color: #000000;

              div.row {
                margin-top: 32px;
                margin-bottom: 16px;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                letter-spacing: -0.06em;
                font-weight: 500;
                line-height: 130%;
                position: relative;

                span {
                  font-size: 20px;
                  @include desktop {
                    font-size: 16px;
                  }
                }

                button.marketing-switch {
                  width: 44px;
                  height: 24px;
                  border-radius: 24px;
                  padding: 0;
                  margin: 0;

                  &::after {
                    width: 18px;
                    height: 18px;
                    margin-top: 2px;
                    //left: 22px;
                    //top: 2px;
                    box-shadow: none;
                  }
                }
              }

              span {
                font-size: 11px;
                @include desktop {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }

    .rightContainer {
      display: none;

      @media (min-width: 1200px) {
        display: block;
        width: 50%;
        background-image: var(--bg-image);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
    }

    .rightNbContainer {
      display: none;

      @media (min-width: 1200px) {
        display: block;
        width: 50%;
        background-image: var(--bg-image);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-color: #F7FAF7;
      }
    }
  }
}

div.logo {
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @include desktop {
    margin-top: 0;
  }

  img {
    max-height: 48px;
    max-width: 108px;
  }
}

.errorMsg {
  color: #ff4d4f;
  clear: both;
  min-height: 24px;
  padding-top: 0px;
  font-size: 14px;
  line-height: 1.5715;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  margin-top: -3px;
  margin-bottom: 0;
}

.accountEditLinkWrapper {
  display: flex !important;
  place-content: center;

  a {
    color: #000000;
  }
}

.hanium-notice {
  &__img {
    width: 150px;
    margin-bottom: 15px;
  }

  &__p {
    margin-top: 20px;
    margin-bottom: -40px;

    &__span {
      text-decoration: underline;
      cursor: pointer;
      margin-left: 6px;
      font-size: 18px;
      color: #e8344e;
    }
  }
}

.welcomeBenefit {
  &__container {
    gap: 12px;
    width: 328px;
    height: 56px;
    background: #edf3ff;
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    margin-bottom: 24px;
    padding: 16px 12px;
    @include desktop {
      padding: 16px 20px;
      width: 358px;
    }
  }

  &__desc {
    color: $aqua-blue;
    line-height: 1.6;
    font-weight: 500;
    font-size: 14px;

    b {
      font-weight: bold;
    }

    @include desktop {
      line-height: 1.5;
      font-size: 15px;
    }
  }
}

.email-form {
  &__container {
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__confirmation__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    &.--confirming {
      .email-form__confirmation__button {
        color: $gray300;
        background: $gray100;
      }
    }

    &.--confirmed {
      .email-form__confirmation__button {
        color: $gray300;
        background: $gray100;
      }
    }
  }

  &__confirmation__input {
    width: 234px;
    border-radius: 8px;
    height: 53px;
    outline: none;
    border: 1px solid $gray150;
    padding: 16px;
    color: $gray600;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    @include desktop {
      width: 242px;
    }

    &:focus {
      border-color: $gray300;
    }

    &__container {
      position: relative;
    }
  }

  &__confirmation__remaining {
    position: absolute;
    color: $primary-color;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.5;
    top: 50%;
    right: 16px;
    transform: translate(-50%, -50%);
  }

  &__email__input {
    width: 234px;
    border-radius: 8px;
    height: 53px;
    outline: none;
    border: 1px solid $gray150;
    padding: 16px;
    color: $gray600;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    @include desktop {
      width: 242px;
    }

    &:focus {
      border-color: $gray300;
    }
  }

  &__validation__button {
    width: 86px;
    height: 53px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $black;
    color: $white;
    font-weight: 700;
    font-size: 13px;
    line-height: 150%;
    cursor: pointer;
    @include desktop {
      width: 108px;
    }
  }

  &__confirmation__button {
    width: 86px;
    height: 53px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $black;
    color: $white;
    font-weight: 700;
    font-size: 13px;
    line-height: 150%;
    cursor: pointer;
    @include desktop {
      width: 108px;
    }
  }
}
