@import "../../../../_styles/utils";

.bg {
  display: block;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  //height: 100%;
  //overflow: auto;
}

.message {
  color: $white;
  position: absolute;

  top: 16px;
  width: 300px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 10px;
  border-radius: 50px;
  text-align: center;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.15);

  @include desktop {
    width: 400px;
    top: 32px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 16px;
    border-radius: 50px;
  }

  &__span {
    font-family: $spoqa-hans-neo;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.36px;

    @include desktop {
      font-family: $spoqa-hans-neo;
      font-size: 16px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: -0.48px;
    }
  }
}

.--info {
  background-color: $info !important;
  color: $white !important;
}

.--warning {
  background-color: red !important;
  color: $white !important;
}

.--success {
  background-color: $black !important;
  color: $white !important;
}

.--visible {
  opacity: 1;
}

.--hidden {
  opacity: 0;
  transition: opacity 1s ease;
}
