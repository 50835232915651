@import '../../_styles/utils.scss';

.treeselect-li-width {
  width: 100%;

  :hover {
    border-color: $hover-color !important;
  }
}

.treeselect-li-width:hover {
  border-color: $hover-color;
}

.navFontSizeContainer {
  font-size: 16px;
  height: 40px;
  overflow: hidden;

  @include adesktop {
    font-size: 13px;
    height: 40px;
  }
}

.navFontSizeContaine:hover {
  border-color: $hover-color !important;
}

.flexColumnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modalBodyContainer {
  width: 90%;
  margin: auto;

  .modalScoreContainer {
    margin-top: 30px;

    .individualScoreContainer {
      margin: 10px auto;
      width: 240px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: row;

      font-size: 16px;
      font-weight: 500;

      img {
        width: 30px;
        height: 30px;
      }
    }
  }

  .modalOpinionContainer {
    margin-top: 20px;

    p {
      text-align: center;
      width: 340px;
    }
  }

  .buttonContainer {
    text-align: center;

    button {
      margin-top: 33px;
      color: black;
      font-weight: 500;
      //background-color: #13C2C2;
      border: 2px solid #13C2C2;
      border-radius: 5px;
      width: 200px;
      height: 45px;
      font-size: 18px;
    }

    button:hover {
      background-color: $hover-color;
    }
  }
}

.lectureDonePointContainer {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 10px;
  background-color: #dee2e6;
  border-radius: 5px;
  font-size: 14px;
  color: #212529;
  font-weight: 500;
  @include adesktop {
    position: relative;
    padding: 5px;
    margin-top: 17px;
    width: 118px;
    font-size: 11px;
    text-align: center;
    border-radius: 20px;
    float: right;
    margin-bottom: 15px;
  }
}

.reviewDonePointContainer {
  position: absolute;
  top: 49px;
  right: 35px;
  padding: 5px 10px;
  background-color: #E9ECEF;
  border-radius: 8px;
  border: 2px solid #E9ECEF;
  font-size: 13px;
  color: black;
}

.nextbutton {
  font-size: 16px;
  padding: 0 10px;
  color: $primary-color;
}