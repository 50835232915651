@import '../../_styles/utils.scss';

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.active {
  display: block !important;
}

.modal-background.submitted {
  height: 490px;
  @include desktop {
    height: 495px;
  }

  @include tablet {
    height: 495px;
  }

  @include mobile {
    height: 495px;
  }
}
.modal-background {
  overflow-y: hidden;
  display: none;
  position: fixed;
  z-index: 2;
  margin: auto;
  background-color: white;
  width: 95%;
  left: 2.5%;
  top: calc(50% - 240px);
  border-radius: 10px;
  //border: 2px solid #13C2C2;
  //padding: 20px;
  height: 690px;

  @include desktop {
    width: 550px;
    height: 695px;
    left: calc(50% - 275px);
    top: calc(50% - 340px);
  }

  @include tablet {
    width: 550px;
    height: 695px;
    left: calc(50% - 275px);
    top: calc(50% - 340px);
  }

  @include mobile {
    width: 330px;
    height: 695px;
    left: calc(50% - 165px);
    top: calc(50% - 340px);
  }

  .modal-content {
    //margin: 90px 0px;
    //height: 300px;
    vertical-align: center;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    .modal-body {
      width: 90%;
      margin: 0 auto;
      .header {
        padding-top: 30px;
        text-align: center;
        img {
          width: 235px;
          margin: 0 auto;
        }
      }
      .body {
        p {
          color: #212529;
          font-size: 16px;
          letter-spacing: -1.12px;
          text-align: center;
          font-weight: 500;
          line-height: 1.6;
          padding-bottom: 10px;
          padding-top: 10px;
        }
        textarea {
          width: 100%;
          border-radius: 10px;
          border: 1px solid #ced4da;
          font-size: 16px;
          line-height: 1.5;
          font-weight: normal;
          height: 157px;
          padding: 10px;
          color: #adb5bd;
          resize: none;
        }
      }
      .buttonContainer {
        padding-top: 25px;
        text-align: center;
        button {
          background-color: #e8344e;
          font-size: 15px;
          border-radius: 5px;
          color: white;
          line-height: 1;
          font-weight: bold;
          text-align: center;
          border: none;
          padding: 15px 20px;
          cursor: pointer;
        }
      }
    }
  }
}