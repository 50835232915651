@import '../../../_styles/utils';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff2ed;
  height: 100vh;

  .header {
    margin: 0 auto 5rem auto;
    display: flex;
    flex-direction: row;

    .rtan-image {
      width: 73px;
    }

    .header-text-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-left: 24px;

      .welcome-message {
        font-family: DungGeunMo;
        font-size: 2rem;
        margin: 0 0 -0.5rem 0;
        padding: 0;
        font-weight: bold;
        text-align: left;
        color: $black;
      }

      .welcom-description {
        font-family: DungGeunMo;
        font-size: 1rem;
        text-align: left;
        color: #8b8b8b;
      }

    }

  }

  .week-calendar {
    width: clamp(500px, 70%, 2000px);
    color: #8b8b8b;

    .weekdays-day {
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 20px;
      line-height: 1;
      margin-bottom: 36px;
    }

    .weekdays-date {
      display: flex;
      height: 112px;
      background-color: rgba(255, 255, 255, 0.7);
      border-radius: 56px;
      justify-content: space-around;
      align-items: center;
      font-size: 1.5rem;
    }
  }

  .go-to-classroom-btn {
    margin-top: 10vh;
    display: flex;
    font-family: NotoSansCJKKR;
    background-color: black;
    align-items: center;
    justify-content: center;
    color: white;
    width: 223px;
    height: 68px;
    border-radius: 34px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    img {
      margin-left: 8px;
    }
  }
}