@import '../../_styles/utils.scss';

.snippetContainer {
  //height: calc(53vh - 80px);

  .snippetsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .codeContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    //border-radius: 10px;
    border: 1px solid #E9ECEF;
    padding: 10px;


    .codeTitleContainer {
      width: 80%;
      padding-left: 5px;
      color: black;

      display: flex;

      .ellipsis {
        width: 280px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .snippetOrder {
        margin-right: 3px;
      }
    }

    .codeCopyButtonContainer {
      display: flex;
      align-items: center;
      padding-right: 5px;
      padding-left: 5px;
      width: 120px;
      height: 14px;
      margin: 2px 0 0 9px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.7px;
      color: #343a40;

      //a:hover {
      //  //color: $primary-color;
      //}
    }
  }

  .codeContainer:hover {
    cursor: pointer;
    border: 1px solid $hover-color;

    .copytext {
      color: $hover-color;
    }
  }
}

.ic_snippet {
  width: 22.7px;
  height: 11px;
  margin-right: 9px;
}
