@import '../../../_styles/utils';


.B2bForm {
  width: 100%;
  margin: 0 auto;
  background: rgba(247, 226, 228);

  &__wrapper {
    max-width: 990px;
    margin: 0 auto;
    padding: 80px 0;
    font-family: Pretendard;

    &__pad {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      background: $primary-color;
      height: 20px;
      width: 100%;
    }

    &__top {
      background: $white;
      padding: 16px 32px;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      margin-bottom: 40px;
    }
  }

  &__banner {
    width: 100%;
    margin-bottom: 40px;
    border-radius: 12px;
  }

  &__header {
    font-size: 32px;
    font-weight: bold;
    line-height: 40px;
    color: #202124;
    margin-bottom: 40px;
  }

  &__desc {
    margin-bottom: 40px;
    font-weight: 500;
    font-size: 14px;
  }

  &__form {
    select {
      width: 200px;
    }

    input {
      width: 300px;
      border: none;
      border-bottom: 1px solid $black;
    }

    &__inner_input {
      margin-bottom: 30px;
    }

    &__row {
      margin-bottom: 32px;
      background: $white;
      padding: 32px 20px;
      border-radius: 16px;

      &__radio {
        margin-bottom: 16px;

        input {
          width: inherit;
          position: relative;

          &:checked:before {
            content: "";
            display: block;
            position: absolute;
            width: 17px;
            height: 17px;
            top: -1px;
            left: -1px;
            border: 2px solid black;
            border-radius: 50%;
            background-color: $primary-color;
          }
        }

        label {
          font-size: 16px;
          font-weight: bold;
          margin-left: 12px;
        }
      }
    }

    &__title {
      margin-bottom: 12px;
      font-weight: bold;

      b {
        color: $primary-color;
        margin-left: 8px;
      }
    }

    &__cta {
      width: 263px;
      height: 51px;
      margin: 50px auto 100px;
      outline: none;
      display: block;
      padding: 20.5px 25px;
      border-radius: 43.3px;
      background-color: $primary-color;
      color: $white;
      cursor: pointer;
      border: none;
      font-size: 16px;
      font-weight: bold;
      line-height: 0;
    }


  }

  &__done {
    width: 100%;
    padding: 64px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: $black;
    background: white;
    border-radius: 12px;
  }
}