@import "../../../_styles/utils";

.mainContainer {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding-top: 13px;
  padding-bottom: 40px;
  @include newTablet {
    max-width: 1192px;
    padding: 28px 20px 0;
  }

  .friend_reco_banner {
    width: 100%;
    background: #000;
    // box-sizing: border-box;
    border-radius: 24px;
    padding: 32px 0;
    // height: 156px;
    text-align: center;
    max-width: 89%;
    margin: auto;

    @include newTablet {
      display: flex;
      height: 97px;
      text-align: left;
      padding: 0 45px;
      align-items: center;
      justify-content: space-between;
      max-width: 100%;
      margin-bottom: 40px;
    }

    .friend_reco_left_column {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      @include newTablet {
        flex-direction: row;
        align-items: flex-start;
        overflow: hidden;
      }
    }

    .friend_reco_img {
      margin-bottom: 16px;
      width: 182px;
      @include newTablet {
        width: 246px;
        margin-top: 12px;
        margin-left: 24px;
        margin-bottom: 0;
      }
    }

    .friend_reco_copy {
      display: inline-block;
      color: #ffffff;
      line-height: 1;
      font-weight: bold;
      letter-spacing: -0.6px;
      font-size: 18px;
      margin-bottom: 128px;
      font-family: $spoqa-hans-neo;
      @include desktop {
        margin: auto 0;
        font-size: 20px;
      }
    }

    .friend_reco_cta {
      height: 48px;
      background: white;
      color: #000;
      width: 149px;
      border-radius: 100px;
      font-size: 14px;
      line-height: 1;
      letter-spacing: 0;
      font-weight: bold;
      cursor: pointer;
      border: none;
      outline: none;
      font-family: $spoqa-hans-neo;
      @include desktop {
        height: 58px;
        width: 174px;
        font-size: 18px;
        float: right;
      }
    }
  }
}
