@import "../../../_styles/utils";

.share_page__container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $white;

  background: radial-gradient(
    72.14% 60.81% at 50% 49.92%,
    #260e01 0%,
    #141617 92.66%
  );

  .share_page__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: auto;
    text-align: center;
    width: 100%;
    padding: 60px 0px;

    background-image: var(--bg-image);
    background-position: center;
    background-size: contain;
    background-repeat: repeat;
    background-color: transparent;

    .share_page__header {
      // margin-bottom: 42px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: $maxWidth;
      width: 100%;

      img {
        width: 100%;
        max-width: 360px;
      }

      div {
        display: flex;
        flex-direction: column;
        // align-items: flex-start;

        span {
          font-size: 16px;
          font-weight: 500;
          color: #d7e0e6;
          font-family: "Pretendard";
        }

        h1 {
          font-size: 32px;
          margin-top: 10px;
          font-family: "Pretendard";
          font-weight: 700;
          line-height: 140%;

          color: $white;
          &.--hanghae {
            color: $white;
          }
        }

        p {
          font-family: "Pretendard";
          font-size: 18px;
          line-height: 1.5;
          color: #505254;
          font-weight: 500;
          margin-bottom: 0;
          &.--hanghae {
            color: #81898f;
            b {
              color: #c7d2d8;
            }
          }

          b {
            font-weight: bold;
          }

          &.notice {
            margin-top: 2px;
            font-size: 11px;
            @include desktop {
              font-size: 18px;
            }
          }
        }

        @include desktop {
          align-items: flex-start;
          .share_page__header {
            flex-direction: row;
          }
          span {
            font-size: 20px;
          }
          h1 {
            font-size: 40px;
            text-align: start;
          }
        }
      }
    }

    .share_page__body {
      max-width: $maxWidth;
      background-image: var(--bg-image-mobile);
      background-position: center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 92%;
      margin: 0 auto 40px auto;

      &.--isTrial {
        h2 {
          margin-top: 88px;
        }

        p {
          margin-bottom: 77px;
        }
      }

      @include desktop {
        width: 100%;
        background-image: var(--bg-image);
      }

      h2 {
        font-size: 24px;
        margin-bottom: 24px;
        color: $white;
        margin-top: 28px;
        font-family: "Pretendard";

        br {
          display: block;
        }

        @include desktop {
          font-size: 28px;
          br {
            display: none;
          }
        }

        strong {
          background-color: rgba(0, 0, 0, 0.7);
          padding: 8px 14px;
          border-radius: 8px;
          color: $primary-color;
          display: inline-block;
          font-family: "Pretendard";
          @include desktop {
            padding: 14px 20px;
            margin: 0 14px;
          }
        }
      }

      p {
        font-family: "Pretendard";
        font-size: 16px;
        line-height: 1.5;
        font-weight: 500;
        color: $white;

        &:last-child {
          margin-bottom: 44px;
        }

        span {
          font-family: "Pretendard";
          font-size: 13px;
          line-height: 1.5;
          font-weight: 500;
          color: $white;

          br {
            display: block;
          }

          @include desktop {
            font-size: 16px;
            br {
              display: none;
            }
          }
        }
      }
    }

    .share_page_medal__container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: $white;
      margin-bottom: 40px;
      width: 100%;

      .share_page_medal__box {
        display: flex;
        flex-direction: column;

        div:first-child {
          margin-bottom: 14px;
        }

        .share_page_medal__box-innerbox {
          display: flex;
          justify-content: space-between;
          padding: 14px 18px;
          width: 312px;

          background-color: rgba(255, 255, 255, 0.04);
          border: 1px solid rgba(255, 255, 255, 0.1);
          box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.04);
          border-radius: 8px;

          span {
            color: #d7e0e6;
            font-size: 14px;
            line-height: 21px;
            font-family: "Pretendard";
          }

          .share_page_medal__box-title {
            color: #d7e0e6;
            margin-bottom: 0px;
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
            font-family: "Pretendard";
          }

          img {
            position: relative;
            left: 9px;
          }

          div {
            display: flex;
            align-items: flex-end;
            border: none;
            box-shadow: none;
            background-color: rgba(255, 255, 255, 0);
            padding: 7px 0px;
            margin-bottom: 0px;
            gap: 8px;
            position: relative;
            top: 6px;

            p {
              margin-bottom: 0px;
              font-size: 18px;
              font-weight: 700;
            }

            span {
              color: $white;
              font-size: 40px;
              font-weight: 700;
            }

            @include desktop {
              p {
                font-size: 28px;
              }
              span {
                font-size: 50px;
              }
            }
          }
        }
      }

      @include desktop {
        .share_page_medal__box-innerbox {
          height: 118px;
        }
        .share_page_medal__box {
          flex-direction: row;
          gap: 41px;
        }
      }
    }

    .share_page__arrow {
      position: absolute;
      top: 85%;
    }

    @include desktop {
      padding: 100px 0px;
      height: 746px;
      .share_page__header {
        flex-direction: row;
      }
      .share_page__arrow {
        position: initial;
      }
    }
  }
}
