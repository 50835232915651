@import "../../../../_styles/utils";

.nps-next-btn {
  padding: 16px 20px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Pretendard";
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: auto;
  width: 250px;
  position: fixed;
  z-index: 99;

  background-color: #c7c9cb;
  color: #a4a6a9;

  &.pirates {
    color: #141617;
    background-color: #5F666B;
  }

  &.--active {
    background-color: $black;
    color: $white;

    &.pirates {
      color: #141617;
      background-color: #EEF3F6;
    }
  }

  &__img {
    transform: rotate(-90deg);
    margin-left: 9.2px;

    path {
      fill: #a4a6a9;
    }

    &.pirates {
      path {
        fill: #141617;
      }
    }
  }

  @include desktop {
    position: relative;
    width: auto;
    left: unset;
    right: unset;
    bottom: unset;
    margin: unset;
  }

  .wrapper {
    position: absolute;
    top: 65px;
    @media (max-width: 498px) {
      top: -80px;
    }
  }

  .nps-next-btn__tooltip {
    position: relative;
    padding: 18px 10px 10px 14px;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    width: 222px;
    height: 70px;
    z-index: 99;
    cursor: default;
    background-repeat: no-repeat;
    color: #3B3B3B;
    font-weight: 500;

    @media (max-width: 498px) {
      padding: 10px 10px 10px 14px;
    }

    &.pirates {
      color: #EEF3F6;
    }

    .nps-next-btn__tooltip__bg-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
    }

    .nextCheck {
      margin-right: 4px;
      margin-top: 2px;
      width: 16px;
      height: 16px;
    }

    .nextClose {
      margin-right: 4px;
      margin-top: 2px;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
}

.nps-next-btn-mainNPS {
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Pretendard";
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;

  width: 100%;
  height: 60px;
  opacity: 0.3;

  background-color: #E8344E;
  color: #FFFFFF;

  &.--active {
    background-color: #E8344E;
    opacity: 1;
    }

  @include desktop {
    width: 556px;
  }
}

