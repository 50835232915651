@import "../../../../../_styles/utils";

.card-wrap {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  margin: 16px 0;
  background: #f2f6f8;
  border-radius: 8px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 22px;
  @include desktop {
    padding: 24px 32px;
    border-radius: 12px;
  }

  .course-thumb {
    width: 125px;
    @include desktop {
      width: 163px;
    }

    img {
      width: 100%;
    }
  }

  .course-info {
    margin-left: 16px;
    width: calc(100% - 145px);
    @include desktop {
      margin-left: 34px;
      width: calc(100% - 510px);
    }

    .course-title {
      width: 100%;
      display: flex;

      font-family: Pretendard !important;
      flex-direction: column;
      word-break: keep-all;
      @include desktop {
        display: block;
      }

      .course-title-info {
        line-height: 1.4;
        letter-spacing: -0.72px;
        font-weight: bold;
        color: #000;
        font-size: 18px;

        @include desktop {
          font-size: 20px;
        }
      }

      .course-title-tutor {
        margin-left: 0;
        font-size: 14px;
        font-family: $spoqa-hans-neo;
        font-style: normal;
        font-weight: 500;
        color: #5f666b;
        margin-top: 8px;
        @include desktop {
          font-size: 16px;
          margin-left: 16px;
          margin-top: 0;
        }
      }

      .course-title-medal {
        width: 24px;
        margin-left: 8px;
        @include desktop {
          width: 30px;
        }
      }
    }
  }

  .course-action {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    width: 100%;
    gap: 20px;
    @include desktop {
      width: 305px;
      justify-content: center;
      margin-top: 0;
    }

    .enter-qa {
      position: absolute;
      left: -16px;
      border: 1px solid #d7e0e6;
      background-color: #fff;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      @include desktop {
        width: 52px;
        height: 52px;
      }
    }

    .enter-course {
      width: 205px;
      height: 48px;
      background: #e8344e;
      color: #f1f3f5;
      font-size: 14px;
      line-height: 1;
      letter-spacing: 0;
      font-family: $spoqa-hans-neo;
      font-weight: bold;
      border-radius: 100px;
      outline: none;
      border: none;
      @include desktop {
        width: 229px;
        height: 62px;
        font-size: 20px;
      }

      &.retry {
        background-color: #6e6bd2;
        position: relative;
        div {
          bottom: -12px;
          left: 50%;
          position: absolute;

          transform: translate(-50%, 100%);
          color: #000;
          font-size: 14px;
          font-weight: 500;
          b {
            color: #6e6bd2;
            font-weight: bold;
          }
        }
      }

      &.description {
        position: relative;
        div {
          bottom: -12px;
          left: 40%;
          position: absolute;

          transform: translate(-50%, 100%);
          color: #000;
          font-size: 14px;
          font-weight: 500;

          @include desktop {
            left: 50%;
          }
          b {
            color: #6e6bd2;
            font-weight: bold;
          }
        }
      }
    }
  }
}
