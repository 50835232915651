@import "../../../../_styles/utils";

.round-week-homework {
  cursor: pointer;
  margin-top: 8px;
  padding: 16px;
  border-radius: 24px;
  background-color: $white;
  width: 360px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &.pirates {
    background-color: #2B2D2E;
  }

  @include mobile {
    border-radius: 10px;
  }

  @include tablet {
    width: 100%;
    padding: 16px 42px;
  }

  @include desktop {
    width: 100%;
    padding: 16px 42px;
  }

  &__title {
    font-family: $spoqa-hans-neo;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.48px;
    text-align: left;
    color: $black;
    padding-top: 3px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 360px;
    @include desktop {
      width: 650px;
    }

    &.pirates {
      color: #EEF3F6;
    }
  }

  &__status {
    font-family: $spoqa-hans-neo;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.45px;
    text-align: center;
    padding: 10px 15px;
    border-radius: 20px;

    background-color: #e9ecef;
    color: $black;
    @include lectureNav {
      white-space: nowrap;
    }

    &.pirates {
      background-color: #2B2D2E;
      color: #EEF3F6;
    }
  }

  .--done {
    background-color: $black;
    color: $white;

    &.pirates {
      background-color: #2B2D2E;
      color: #B4BFC6;
    }
  ;
  }
}

.--small-padding {
  padding: 5px 30.5px 18px;
}
.--small-width {
  width: 330px;
}
