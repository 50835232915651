@import '../../../_styles/utils';

.CodingRaceMainContainer {
  height: 100vh;
  display: grid;

  .CodingRaceMainContainerBg {
    grid-column: 1;
    grid-row: 1;
    height: 100vh;

    .CodingRaceMainContainerBgSky__Day {
      height: 30%;
      background-color: #EBEAFA;
      width: 100%;
    }

    .CodingRaceMainContainerBgSky__Night {
      height: 30%;
      background-color: #f88404;
      width: 100%;
    }

    .CodingRaceMainContainerBgGround {
      height: 70%;
      background-color: #a8cc54;
      width: 100%;
    }
  }

  .CodingRaceMainContainerContent {
    grid-column: 1;
    grid-row: 1;
    height: 100vh;
  }

}

.CodingRaceUnderground {
  background-color: #8CCC53;
  //background-color: #a8cc54;
  height: 250px;
}
