.wrapper {
  background-color: white;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

  &.pirates {
    background-color: #222425;
  }

  .alert {
    color: black;

    &.alert-danger {
      color: #ff8f8f;
    }
  }
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

html, body {
  margin: 0;
  -webkit-font-smoothing: unset;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style-type: none;
}

.icon {
  display: inline-block;
  width: 16px;
  height: 16px;
}