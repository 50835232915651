@import '../../../../_styles/utils';

.sparthon_banner {
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  height: 64px;
  text-align: left;
  position: relative;
  margin-bottom: 18px;
  @include desktop {
    height: 97px;
    border-radius: 24px;
    max-width: 100%;
    margin-bottom: 16px;
  }
}

.sparthon_banner_bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: 0;
  border-radius: 8px;
  @include desktop {
    border-radius: 24px;
  }
}

.sparthon_banner_contents {
  position: relative;
  z-index: 1;
  padding: 0 16px;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  line-height: 1.5;
  @include desktop {
    padding: 0 48px 0 45px;
  }
}

.sparthon_banner_accent {
  font-size: 12px;
  color: #FF4646;
  margin-bottom: 2px;
  img {
    height: 12px;
  }

  @include desktop {
    font-size: 16px;
    margin-bottom: 8px;
    img {
      height: 16px;
    }
  }
}

.sparthon_banner_title {
  display: inline-block;
  color: #ffffff;
  line-height: 1;
  font-weight: 500;
  letter-spacing: -0.6px;
  font-size: 12px;
  margin: auto 0;
  font-family: $gmarketSans;
  cursor: default;
  @include desktop {
    font-size: 20px;
  }
}

.sparthon_banner_cta {
  height: 30px;
  width: 83px;
  background: white;
  color: #000;
  border-radius: 100px;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0;
  font-weight: bold;
  cursor: pointer;
  border: none;
  outline: none;
  font-family: "Pretendard";
  display: flex;
  justify-content: center;
  align-items: center;
  @include desktop {
    height: 58px;
    width: 121px;
    font-size: 18px;
    float: right;
  }
}
.custom-banner-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 24px;
  border-radius: 24px;
  background-color: #c9e5eb;
  margin-bottom: 15px;

  &__title {
    font-family: $spoqa-hans-neo;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.54px;
    text-align: left;
    color: $black;

    &__img {
      width: 36px;
      height: 28.3px;
      object-fit: contain;
      margin-right: 11px;
    }

    &.timer {
      font-family: $dungGeunMo;
      font-size: 24px;
      font-weight: normal;
      color: white;
      position: absolute;
      left: 135px;
      top: 48px;

      &.--timeover {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &__cta {
    cursor: pointer;
    font-family: $spoqa-hans-neo;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: $black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 9px;
    padding: 10px 16px;
    border-radius: 89.7px;
    border: solid 1.8px $white;
    background-color: $white;
  }

  &__timer {
    position: absolute;
    color: white;
    left: 455px;
    bottom: 11px;
    font-family: 'GmarketSans';
    font-weight: normal;
    font-size: 27px;

    span {
      font-weight: bold;
    }

    &.--timeover {
      left: 443px;
      top: 14px;
      font-family: 'GmarketSans';
      font-weight: normal;
      font-size: 25px;

    }
  }

  &.timer {
    background-image: var(--bg-image);
    background-size: contain;
    background-repeat: no-repeat;
    background-color: unset;
    height: 91px;
    position: relative;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
}

.banner__img {
  width: 828px;
  height: 91px;
  object-fit: contain;
  margin-bottom: 15px;
  &.--clickable {
    cursor: pointer;
  }
}
