@import "../../../_styles/utils";

.course_rcmd_online__wrapper {
  width: 100vw;
  background-color: #fff6f8;

  display: flex;
  flex-direction: column;
  align-items: center;

  .course_rcmd_online___header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 360px;
    margin-top: 60px;

    h1 {
      font-family: Pretendard;
      font-weight: 700;
      line-height: 33.6px;
      font-size: 24px;
    }

    .course_rcmd_online__header-title {
      margin-bottom: 14px;
    }
    .course_rcmd__header__desc {
      font-family: "Pretendard";
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: #81898f;
    }

    @include desktop {
      margin-top: 100px;
      width: auto;
      h1 {
        font-size: 40px;
        line-height: 56px;
      }
      .course_rcmd__header__desc {
        font-size: 20px;
      }
      .course_rcmd_online__header-title{
        margin-bottom: 20px;
      }
    }
  }

  .course_rcmd_online__card__wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    gap: 16px;
    padding: 40px 24px;

    white-space: nowrap;
    overflow-x: auto;
    width: 100%;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;

    -ms-overflow-style: none;

    @include desktop {
      padding: 60px 24px;
    }
  }

  .course_rcmd__notice {
    font-size: 13px;
    margin-bottom: 60px;
    font-weight: 400;
    color: #81898f;
    font-family: "Pretendard";
  }
  @include desktop{
    .course_rcmd_online__card__wrapper{
      justify-content: center;
    }
  }
}

.course_rcmd_online_coupon__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100vw;
  padding: 60px 0px;

  img {
    width: 260px;
  }
  button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 312px;
    height: 56px;

    background: #e8344e;
    border-radius: 8px;
    border: none;

    color: $white;
    font-size: 16px;
    font-weight: 600;

    cursor: pointer;
  }
  // height: 100vh;
  .course_rcmd_online_coupon__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;

    p {
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: #81898f;
    }
    h1 {
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 140%;
      color: #141617;
    }
    @include desktop {
      margin-bottom: 60px;
      p {
        font-size: 20px;
      }
      h1 {
        font-size: 40px;
      }
    }
  }

  .course_rcmd__notice {
    margin-bottom: 60px;
    font-weight: 400;
    font-size: 13px;
    color: #81898f;
    font-family: "Pretendard";
  }

  @include desktop {
    padding: 100px 0px;
    img {
      width: 419px;
    }
  }
}
