.ant-checkbox-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.ant-checkbox-group-item {
    display: block;
}

.ant-checkbox-wrapper {
    text-align: left;
}

.radio.date .ant-radio-group {
    justify-content: flex-start;
    align-items: flex-start;
}

.radio.date .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
    margin-top: 8px;
}

.radio.date .ant-checkbox-wrapper .ant-checkbox + span {
    font-size: 16px;
}