@import '../../_styles/utils.scss';

.checkinReadContainer {

  .checkinRead {
    width: 100%;

    @include desktop {
      //margin-top: 50px;
      width: 515px;
      float: right;
    }


    .checkinReadHeader {
      text-align: center;

      h4 {
        letter-spacing: -2px;
        font-size: 28px;
      }
    }

    .checkinReadBody {
      width: 100%;
      background-color: white;
      border-radius: 10px;

      @include desktop {
        width: 472px;
      }

      .checkinWrapper {
        width: 70%;
        margin: auto;
        background-color: white;
        padding: 20px 33px 20px 33px;

        color: black;
        line-height: 24px;
        letter-spacing: -1px;
        font-size: 16px;

        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;

        min-width: 435px;
        max-width: 475px;

        @include desktop {
          width: 472px;
          background-color: white;
          padding: 20px 33px 20px 33px;
        }

        @include adesktop {
          padding: 10px 13px;
          font-size: 14px;
          min-width: 300px;
        }

        .contentWrapper {

          .comment {

            @include desktop {
              width: 375px;
            }
          }

          .whowhen {
            margin-top: 9px;
            color: #495057;
          }

        }

        .likesWrapper {
          width: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          line-height: 30px;
          padding-top: 8px;

          color: #868E96;
        }

        .liked {
          color: $primary-color;
        }
      }
    }
  }
}

.spanNumber {
  margin-right: 1px;
}

.border_radius_bottom {
  border-radius: 0px 0px 10px 10px;
}