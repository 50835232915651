@import '../../../../_styles/utils';

.broadcast-container {

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;

    &__img-div {
      margin-right: 20px;

      &__img {
        margin-right: -10px;
        width: 32px;
        height: 32px;
        flex-grow: 0;
        object-fit: contain;
      }
    }

    &__content {
      width: 120px;
      border-radius: 100px;
      background-color: $black;

      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 7px;

      &.pirates {
        background-color: #3A3E41;
      }

      &__text {
        padding: 7px 15px 4px;
        border-radius: 100px;
        background-color: $primary-color;
        font-family: $spoqa-hans-neo;
        font-size: 18px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.5px;
        text-align: center;
        color: $white;
        position: relative;

        &__arrow {
          position: absolute;
          bottom: 1.5px;
          right: 49px;
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 15px solid #e8344e;
          transform: rotate(-51deg);
        }
      }

      &__number {
        display: flex;
        align-items: center;

        &__span {
          //padding-top: 3px;
          font-family: $spoqa-hans-neo;
          font-size: 18px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: -1.08px;
          text-align: center;
          color: $white;
        }

        &__bg {
          margin-left: 5px;

          &__img {
            width: 12px;
            height: 7px;
            margin-bottom: 1px;
          }
        }
      }
    }
  }

  &__content {
    position: relative;
  }

  @include lectureNav {
    display: none;
  }
}

.--rotate {
  transform: rotate(180deg);
}