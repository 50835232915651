@import "../../../../_styles/utils";

.roadmap-basic-component {
  width: 138px;
  height: 124px;
  border-radius: 24px;
  background-color: $white;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  &__bg {
    width: 70%;
    margin: auto;

    &__title {
      font-family: $spoqa-hans-neo;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.42px;
      color: $black;
    }

    &__content {
      margin-top: 8px;
      font-family: $spoqa-hans-neo;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.42px;
      color: $black;

      &--big {
        font-size: 32px;
        line-height: 1;
        letter-spacing: -0.96px;
        margin-right: 2px;
      }
    }
  }
}

.--plain-text {
  width: 93% !important;
  height: 60px !important;
}
