@import "../../../_styles/utils";

.course_rcmd__wrapper {
  .course_rcmd__header {
    background: #ebeaff;
    text-align: center;
    padding: 100px 0 80px 0;

    &__sub {
      font-family: "Pretendard";
      color: $black;
      font-size: 15px;
      line-height: 1.4;
      font-weight: bold;
      @include desktop {
        font-size: 24px;
      }
    }

    &__main {
      font-family: "Pretendard";
      color: $black;
      font-size: 24px;
      line-height: 1.4;
      font-weight: bold;
      margin-bottom: 8px;
      margin-top: 8px;
      @include desktop {
        font-size: 32px;
        margin-top: 0;
        br {
          display: none;
        }
      }
    }

    &__desc {
      font-family: "Pretendard";
      color: #2f3031;
      font-size: 12px;
      line-height: 1.4;
      font-weight: 500;

      b {
        font-weight: bold;
      }

      strong {
        color: #3b0dbf;
        background: #d9cdf9;
        margin: 0 2px;
        padding: 1px 4px;
        @include desktop {
          margin: 0 4px;
          padding: 2px 6px;
        }
      }

      @include desktop {
        font-size: 20px;
        line-height: 1.2;
        br {
          display: none;
        }
      }
    }

    &__notice {
      font-size: 15px;
      line-height: 24px;
      color: #878196;
      font-weight: 500;
      font-family: "Pretendard";
    }

    .course_rcmd__card__wrapper {
      display: flex;
      flex-direction: row;
      margin-top: 44px;
      margin-bottom: 24px;
      align-items: flex-end;
      overflow-x: auto;
      width: 100%;
      scroll-snap-type: x mandatory;
      -webkit-overflow-scrolling: touch;
      justify-content: flex-start;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
      @include desktop {
        margin-top: 65px;
        justify-content: center;
      }

      .course_rcmd__card {
        width: 205px;
        height: 380px;
        scroll-snap-align: center;
        margin-left: 70px;
        position: relative;
        @include desktop {
          height: auto;
        }

        @include desktop {
          margin-left: 0;
          margin-right: 85px;
          &:last-child {
            margin-right: 0;
          }
        }

        &:first-child {
          margin-left: calc(50% - 102px);

          .course_rcmd__card__rank {
            font-size: 38px;
            color: #3b0dbf;
            margin-bottom: 24px;
            @include desktop {
              margin-bottom: 40px;
            }
          }

          .course_rcmd__card__cta {
            background: #3b0dbf;
          }

          @include desktop {
            margin-left: 0;
          }
        }

        &:last-child::after {
          content: "";
          border-left: 80px solid transparent;
          @include desktop {
            border-left: 0 solid transparent;
          }
        }

        &__badge {
          position: absolute;
          right: -45px;
          top: 60px;
          width: 85px;
        }

        &__rank {
          font-weight: bold;
          color: #6e6bd2;
          line-height: 1;
          background: $white;
          padding: 10px 24px;
          border-radius: 100px;
          font-size: 30px;
          margin-bottom: 34px;
          display: inline-block;
          @include desktop {
            margin-bottom: 44px;
          }
        }

        &__body {
          img {
            width: 100%;
            margin-bottom: 12px;
          }

          p {
            font-size: 12px;
            line-height: 1.4;
            font-family: "Pretendard";
            color: $black;
            text-align: left;
            display: -webkit-box;
            margin-bottom: 24px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }

        &__cta {
          color: $white;
          font-weight: 500;
          font-size: 18px;
          line-height: 1;
          letter-spacing: -0.54px;
          height: 48px;
          outline: none;
          border: none;
          border-radius: 100px;
          background-color: #3b0dbf;
          width: 205px;
          cursor: pointer;
        }
      }
    }
  }

  .course_rcmd__body {
    text-align: center;
    background-image: var(--bg-image);
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 80px;
    .course_rcmd__bonus {
      &__label {
        color: $white;
        background-color: #6e6bd2;
        border-radius: 100px;
        padding: 6px 18px;
        display: inline-block;
        margin-bottom: 20px;
        font-family: "Pretendard";
        font-size: 20px;
        line-height: 1.4;
        font-weight: bold;
        @include desktop {
          font-weight: 500;
        }
      }

      &__header {
        font-size: 24px;
        line-height: 1.4;
        color: $black;
        font-weight: bold;
        font-family: "Pretendard";
        margin-bottom: 8px;
        @include desktop {
          font-size: 32px;
          margin-bottom: 6px;
          line-height: 1.2;
          br {
            display: none;
          }
        }
      }

      &__sub {
        font-size: 20px;
        line-height: 1.2;
        color: #505254;
        margin-bottom: 20px;
        font-family: "Pretendard";
        font-weight: bold;
        @include desktop {
          color: #2f3031;
          margin-bottom: 44px;
          br {
            display: none;
          }
        }
      }

      &__card {
        background: $white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 32px;
        border-radius: 24px;
        box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.15);
        width: 260px;
        height: 126px;
        @include desktop {
          width: 578px;
          height: 240px;
          margin-bottom: 44px;
        }

        &__img {
          margin-right: 33px;
          width: 131px;
          display: none;
          @include desktop {
            display: inline;
          }
        }

        &__detail {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          &__title {
            color: #a9a5e5;
            font-size: 15px;
            font-family: "Pretendard";
            line-height: 1.4;
            font-weight: bold;
            margin-bottom: 0;
            @include desktop {
              font-size: 24px;
            }
          }

          &__amount {
            color: #6e6bd2;
            font-size: 50px;
            font-family: "Pretendard";
            font-weight: bold;
            text-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
            @include desktop {
              font-size: 70px;
            }
          }
        }
      }

      &__cta {
        margin-bottom: 14px;
        color: $white;
        font-weight: 500;
        font-size: 18px;
        line-height: 1;
        letter-spacing: -0.54px;
        height: 48px;
        outline: none;
        border: none;
        border-radius: 100px;
        background-color: #3b0dbf;
        width: 205px;
        cursor: pointer;
        @include desktop {
          margin-bottom: 24px;
        }
      }

      &__notice {
        font-family: "Pretendard";
        font-size: 15px;
        font-weight: 500;
        line-height: 1.6;
        color: #878196;
        margin-bottom: 80px;
      }
    }

    .page__outro__title {
      &__img {
        width: 34px;
        margin-bottom: 16px;
        @include desktop {
          margin-bottom: 56px;
        }
      }

      &__main {
        font-size: 24px;
        line-height: 1.2;
        letter-spacing: -0.72px;
        color: $black;
        margin-bottom: 16px;
        font-family: "Pretendard";
        font-weight: bold;
        @include desktop {
          br {
            display: none;
          }
        }
      }

      &__sub {
        margin-bottom: 36px;
        font-family: "Pretendard";
        color: #838689;
        line-height: 24px;
        font-weight: bold;
        font-size: 15px;
        @include desktop {
          font-size: 20px;
          margin-bottom: 56px;
        }
      }
    }

    .page__outro__cta {
      color: $white;
      background: $black;
      font-size: 18px;
      line-height: 1;
      font-weight: bold;
      font-family: "Pretendard";
      width: 160px;
      height: 58px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      margin-bottom: 80px;
      cursor: pointer;
    }
  }
}
