@import '../../_styles/utils';

.lectureNavWrapper {
  width: 100%;
  max-width: 850px;
  position: relative;
  margin: 20px auto 0px auto;
  @include adesktop {
    width: 90%;
    margin: 10px auto;
  }

  .lectureNavContainer {
    margin: auto;
    width: 520px;
    @include adesktop {
      width: 100%;
    }
  }
}

.homeworkContainer {
  padding-top: 80px;
  width: 100%;
  max-width: 850px;
  margin: auto;
  @include adesktop {
    width: 100%;
    padding: 40px 20px 0;
  }

  h3 {
    font-size: 35px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.49;
    letter-spacing: -2.1px;
    color: #212529;
    margin-bottom: 30px;
    @include adesktop {
      font-size: 21px;
    }
  }

  .textContainer {
    margin-bottom: 29px;
    position: relative;

    p {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: -0.6px;
      color: #343a40;
      margin: 0;
    }

    p:nth-child(2) {
      font-size: 14px;
    }

    .questionTooltip {
      position: absolute;
      right: 0;
      top: 0;
    }

  }


  textarea:focus {
    outline: none;
    border: none;
  }


  .submitButton {
    margin-top: 30px;
    float: right;
    width: 361px;
    background-color: #13c2c2;
    font-family: NotoSansCJKKR;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    color: #ffffff;
    height: auto;
    font-size: 22px;
    line-height: 1.36;
    letter-spacing: -0.66px;
    padding: 12px 0;
    border-radius: 6px;
    border: none;
    outline: none;

    @media (max-width: 1200px) {
      width: 100%;
    }
  }

  .submitButton:hover {
    border-color: $hover-color-deeper;
    background-color: $hover-color-deeper;
  }

  .modal {
    text-align: center;

    h3 {
      font-family: NotoSansCJKKR;
      font-size: 25px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.08;
      letter-spacing: -1.5px;
    }
  }
}

.review-final {

  span.bold {
    font-weight: 700;
  }

  span.underline {
    text-decoration: underline;
  }

  .header {
    width: 100%;
    margin: 10px auto;
    font-size: 16px;
    letter-spacing: -1px;
  }

  .what {
    width: 100%;
    margin: 10px auto;
  }

  .how {
    width: 100%;
    margin: 10px auto;

    a {
      text-decoration: underline;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .prize {
    a {
      text-decoration: underline;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}
