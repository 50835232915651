@import '../../../../_styles/utils';

.round-week-detail {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    &__title {
      font-family: $spoqa-hans-neo;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.54px;
      text-align: left;
      color: $black;

      &.pirates {
        color: #EEF3F6;
      }
    }

    &__cta {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 24px;
      border-radius: 100px;
      background-color: $pale-gray;
      cursor: pointer;
      gap: 8px;

      &.pirates {
        background-color: #5F666B;
      }

      &__coming_soon {
        padding: 10px 24px;
        border-radius: 100px;
        background-color: #ff2424;
        color: #f2f2f2;

        &__span {
          font-weight: bold;
        }
      }

      &__span {
        font-family: $spoqa-hans-neo;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.48px;
        color: $black;

        &.pirates {
          color: #EEF3F6;
        }
      }

      .--rotate {
        transform: rotate(180deg);
      }
    }
  }
}