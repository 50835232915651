@import '../../../../_styles/utils';

.B2bNPSBannerWrapper {
  background-color: #F8F9FA;
  width: 100%;
  height: 320px;
  display: flex;
  justify-content: center;

  @include desktop {

  }
}

.B2bNPSBannerContainer {
  display: flex;
  flex-direction: column;
  max-width: 330px;
  text-align: center;

  @include desktop {
    max-width: 510px;
    padding-top: 40px;
  }
}

.B2bNPSBannerTitle {
  font-family: "Pretendard";
  font-size: 20px;
  color: #0a0a0c;
  font-weight: bold;
  padding-top: 12px;
  padding-bottom: 32px;
  @include desktop {
    font-size: 28px;
    padding-top: 0px;
  }
}

.B2bNPSBannerBody {
  font-family: "Pretendard";
  font-size: 16px;
  font-weight: bold;
  color: black;
  padding-bottom: 12px;
  @include desktop {
    font-size: 18px;
  }
}

.B2bNPSBannerContent {
  font-family: "Pretendard";
  font-size: 15px;
  font-weight: lighter;
  padding-bottom: 12px;
  @include desktop {
    font-size: 18px;
  }
}

.B2bNPSBannerIcon {
  width: 132px;
  height: 66px;
}