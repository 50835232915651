@import '../../../../../_styles/utils';

.LecMenuContentsContainer {
  width: 100%;
  margin: 0 auto;
  background: white;
}


.LecMenuContentsContainer.dual {
  position: absolute;
  left: 60px;
  width: 315px;
  z-index: 100;
  box-sizing: border-box;
  border: 2px solid #000;
  padding: 17px 10px 23px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.15);
  border-radius: 24px;
  max-height: 300px;
  overflow-y: scroll;

  & > span {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: -0.96px;
    color: #000;
    margin-left: 15px;
  }
}


.bg {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}