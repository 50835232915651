@import '../../../../_styles/utils';

.checkinFormContainer {
  padding-top: 15px;
  padding-bottom: 35px;
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 23px;

  .checkinContainer {
    display: grid;
    grid-column-gap: 10px;

    &.left {
      grid-template-columns: 1fr 8fr;
    }

    &.right {
      grid-template-columns: 8fr 1fr;
    }

    img {
      width: 50px;
      height: 50px;
    }
  }


  //.checkinForm-EnterRoadmap {
  //  width: 264px;
  //  height: 62px;
  //  border-radius: 100px;
  //  margin-top: 48px;
  //  margin-bottom: 60px;
  //  border: solid 2px #e8344e;
  //  background-color: #e8344e;
  //  outline: none;
  //  font-family: $spoqa-hans-neo;
  //  font-size: 20px;
  //  font-weight: bold;
  //  font-stretch: normal;
  //  font-style: normal;
  //  line-height: 1;
  //  letter-spacing: normal;
  //  text-align: center;
  //  color: #f2f2f2;
  //  cursor: pointer;
  //}
  .checkinFormInputContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //margin-left: 33px;

    .checkinFormInputContainerTextarea {
      width: 100%;
      height: 100%;
      border: 2px solid #000;
      border-radius: 24px;
      font-size: 16px;
      background: white;
      line-height: 1.6;
      font-weight: 500;
      font-family: $spoqa-hans-neo;
      letter-spacing: -0.96px;
      resize: none;
      box-sizing: border-box;
      outline: none;
      padding: 24px 18px 24px 24px;
      box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.15);
    }

    .button-wrapper {
      display: grid;
      grid-template-columns: 1fr 2fr;
      width: 100%;
      grid-column-gap: 8px;

      button {
        letter-spacing: 0;
        font-weight: bold;
        width: 100%;
        height: 58px;
        border-radius: 100px;
        box-sizing: border-box;
        margin-top: 16px;
        cursor: pointer;
        border: none;
        outline: none;
        font-size: 16px;
        font-family: $spoqa-hans-neo;

        &:hover {
          transform: scale(1.05);
        }
      }

      .checkinFormSkipBtn {
        border-radius: 50px;
        border: solid 2px #e8344e;
        background: white;
        color: #E8344E;
      }

      .checkinFormInputContainerCTA {
        background: #E8344E;
        color: #fff;
        line-height: 1;
      }
    }

  }

  .checkinFormUser {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      margin-top: 5px;
      font-size: 16px;
      font-family: $spoqa-hans-neo;
      font-weight: 500;
    }
  }
}

.checkinFormMainCopy-container {
  margin: 0 auto;

  .checkinFormMainCopy {
    text-align: center;
    margin-top: 24px;
    font-size: 16px;
    font-family: $spoqa-hans-neo;
    color: #000;
    letter-spacing: -1px;
    line-height: 1.6;
    font-weight: 500;
  }
}


