@import "../../../../_styles/utils";

.--point-out {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
  border: 2px solid #e8344e !important;
  background-color: #fff5f5 !important;
  color: $black !important;

  &.pirates {
    color: #EEF3F6 !important;
    border: 2px solid rgba(0, 0, 0, 0) !important;
    background-color: rgba(255, 108, 122, 0.4) !important;
  }
}

.--active {
  color: $black !important;

  &.pirates {
    color: #EEF3F6 !important;
  }
}

.round-week-detail-lecture__body__component {
  position: relative;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13.9px 30.5px;
  border-radius: 30px;
  background-color: $white;
  margin-bottom: 5px;
  cursor: pointer;
  margin-top: 5px;
  @include mobile {
    padding: 13.9px;
  }

  &.pirates {
    color: #EEF3F6;
    border: 2px solid rgba(0, 0, 0, 0);
    background-color: #2B2D2E;
  }

  &:hover {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.08);
    background-color: #f8f9fa;
    border: 2px solid #f8f9fa;

    &.pirates {
      background-color: #3A3E41;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
      border: 2px solid rgba(0, 0, 0, 0);
    }
  }

  &__num {
    font-family: $spoqa-hans-neo;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.48px;
    text-align: left;
    width: 40px;
    margin-right: 0;
  }

  &__title {
    width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: 3px;
    font-family: $spoqa-hans-neo;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.48px;
    text-align: left;
    @include mobile {
      width: 220px;
    }
  }

  &__playtime {
    width: 45px;
    font-family: $spoqa-hans-neo;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.48px;
    text-align: left;
  }

  &__seen {
    width: 22px;
    height: 22px;
    object-fit: contain;
    @include mobile {
      margin-left: 10px;
    }
  }
  &__bubbledesktop {
    position: absolute;
    top: -33px;
    left: 110px;
    width: 40px;
    @include mobile {
      display: none;
    }
  }
  &__bubblemobile{
    display: none;
    @include mobile {
      display: block;
      position: absolute;
      top: -25px;
      left: 50px;
    }
  }
}

.--small-width {
  width: 276px;

  @include mobile {
    width: 180px;
    margin-bottom: 3px;
  }
}
