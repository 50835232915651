@import "../../_styles/utils.scss";

@mixin default_container {
  padding: 16px;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: $gray50;
  cursor: pointer;
}

@mixin default_container_input {
  display: none;

  & + span {
    display: block;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    border: 2px solid $gray250;
    border-radius: 2px;
    position: relative;
  }

  &:checked + span:after {
    left: 1px;
    top: 2px;
    width: 13px;
    height: 10px;
    position: absolute;
    background-size: cover;
    background-image: url("../../assets/icons/ic_agreement_checkall.svg");
    content: "";
  }
}

.agreement {
  &__container {
    margin-top: 24px;
  }

  &__checkAll {
    &__container-- {
      @include default_container;

      &:hover,
      &.--checked {
        background-color: $sparta-pink;
      }

      input {
        @include default_container_input;

        &:checked + span {
          background-color: $primary-color;
          border-color: $primary-color;
        }
      }

      label {
        display: inline-block;
      }
    }

    &__container--swc {
      @include default_container;

      &:hover,
      &.--checked {
        background-color: #a8c5ff4d;
      }

      input {
        @include default_container_input;

        &:checked + span {
          background-color: #0f16ae;
          border-color: #0f16ae;
        }
      }

      label {
        display: inline-block;
      }
    }

    &__container--nbc {
      @include default_container;

      &:hover,
      &.--checked {
        background-color: #f0f8e6;
      }

      input {
        @include default_container_input;

        &:checked + span {
          background-color: #1f8648;
          border-color: #1f8648;
        }
      }

      label {
        display: inline-block;
      }
    }

    &__container--hanghae {
      @include default_container;

      &:hover,
      &.--checked {
        background-color: #fff5f5;
      }

      input {
        @include default_container_input;

        &:checked + span {
          background-color: #f50000;
          border-color: #f50000;
        }
      }

      label {
        display: inline-block;
      }
    }

    &__container--chang {
      @include default_container;

      &:hover,
      &.--checked {
        background-color: #e5eeff;
      }

      input {
        @include default_container_input;

        &:checked + span {
          background-color: #0f16ae;
          border-color: #0f16ae;
        }
      }

      label {
        display: inline-block;
      }
    }

    &__container--ddingdong {
      @include default_container;

      &:hover,
      &.--checked {
        background-color: #fff6ee;
      }

      input {
        @include default_container_input;

        &:checked + span {
          background-color: #ff773c;
          border-color: #ff773c;
        }
      }

      label {
        display: inline-block;
      }
    }
    &__container--intellipick {
      @include default_container;

      &:hover,
      &.--checked {
        background-color: #f4f2ff;
      }

      input {
        @include default_container_input;

        &:checked + span {
          background-color: #6e50ff;
          border-color: #6e50ff;
        }
      }

      label {
        display: inline-block;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 4px;
    padding: 11px 16px;

    svg {
      cursor: pointer;
    }

    &.--checked-- {
      path {
        stroke: $primary-color;
      }

      .agreement__item__desc__detail {
        color: $gray600;
      }
    }

    &.--checked--swc {
      path {
        stroke: #0f16ae;
      }

      .agreement__item__desc__detail {
        color: $gray600;
      }
    }

    &.--checked--nbc {
      path {
        stroke: #1f8648;
      }

      .agreement__item__desc__detail {
        color: $gray600;
      }
    }

    &.--checked--hanghae {
      path {
        stroke: #f50000;
      }

      .agreement__item__desc__detail {
        color: $gray600;
      }
    }

    &.--checked--chang {
      path {
        stroke: #0f16ae;
      }

      .agreement__item__desc__detail {
        color: $gray600;
      }
    }

    &.--checked--ddingdong {
      path {
        stroke: #ff773c;
      }

      .agreement__item__desc__detail {
        color: $gray600;
      }
    }

    &.--checked--intellipick {
      path {
        stroke: #6e50ff;
      }

      .agreement__item__desc__detail {
        color: $gray600;
      }
    }

    &__desc {
      margin-left: 8px;
      font-size: 13px;
    }

    &__desc__cta {
      margin-left: auto;
      cursor: pointer;
      text-decoration: underline;
      font-size: 12px;
      font-weight: 600;
    }

    &__desc__detail {
      color: $gray250;
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 0;
    }

    &__notice {
      color: $gray400;
      font-weight: 400;
      font-size: 13px;
      line-height: 1.5;
      margin-left: 46px;
      margin-right: 16px;
    }
    &__red {
      color: #e8344e;
      font-weight: 400;
      font-size: 13px;
      line-height: 1.5;
    }
  }

  &__item__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}
