@import '../../../../../_styles/utils';

.CodingRaceLayout{
    //border: solid 3px blue;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    grid-row: 1;
    grid-column: 1;

    //position: absolute;

}

.CharacterContainer{
    grid-row: 1;
    grid-column: 1;
    margin-top: 5%;
}