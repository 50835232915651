@import '../../../_styles/utils';

.course_rcmd__wrapper {
  width: 100vw;
  background-color: #1a1a1a;
  display: flex;
  flex-direction: column;
  @include desktop {
    align-items: center;
    text-align: center;
  }
  hr {
    border: none;
    height: 1px;
    color: #393a3b;
    background-color: #393a3b;
    width: 328px;
    margin: 0 auto;
    @include desktop {
      width: 1200px;
    }
  }
  .course_rcmd__header {
    padding-top: 48px;
    text-align: center;
    @include desktop {
      padding-top: 64px;
    }
    h1 {
      font-family: "Pretendard";
      font-weight: 600;
      line-height: 1.5;
      font-size: 13px;
      color: #c7c9cb;
      padding-top: 20px;
      @include desktop {
        font-size: 16px;
        padding-top:32px;
      }
    }
    img {
      width: 149px;
      @include desktop {
        width: 190px;
      }
    }
    .course_rcmd__title {
      padding-top: 10px;
      display: flex;
      justify-content: center;
      h2 {
        font-family: "Pretendard";
        padding-left: 9px;
        font-weight: 600;
        line-height: 1.5;
        font-size: 21px;
        color: white;
        @include desktop {
          font-size: 28px;
        }
      }
      img {
        width: 100px;
        height: 32px;
        @include desktop {
          width: 139px;
          height: 43px;
        }
      }
    }
  }

  .course_rcmd__body {
    padding-top: 32px;
    @include desktop {
      padding-top: 64px;
    }
    .course_rcmd__body___title {
      font-family: "Pretendard";
      line-height: 1.44;
      color: white;
      font-size: 18px;
      font-weight: 700;
      padding-bottom: 24px;
      @include desktop {
        padding-bottom: 40px;
        font-size: 24px;
      }
    }

    .course_rcmd__banner{
      background-color: #962222;
      color: white;
      min-width: 100vw;
      .course_rcmd__banner___context {
        justify-content: center;
        display: flex;
        img {
          padding-top: 10px;
          width: 100px;
          @include desktop {
            width: 110px;
          }
        }

      .course_rcmd__banner___text {
        padding: 24px 20px 7px 14px;
        text-align: left;
        @include desktop {
          padding-top: 26px;
        }
        span {
          font-size: 15px;
          color: #ffb6b6;
          font-weight: 700;
          font-family:"Pretendard";
          @include desktop {
            font-size: 22px;
          }
        }
        p {
          font-family: "Pretendard";
          font-size: 16px;
          font-weight:500;
          @include desktop {
            font-size: 24px;
            br {
              display: none;
            }
          }
          span {
            font-family: "Pretendard";
            font-weight:700;
            font-size: 16px;
            color: white;
            @include desktop {
              font-size: 24px;
              }
            }
          }
        }
      }

      }

    .course_rcmd__detail {
      padding: 40px 16px;
      @include desktop {
        padding: 64px 0;
      }
      .course_rcmd__detail___text {
        p {
          font-family: "Pretendard";
          line-height: 1.44;
          color: white;
          font-size: 32px;
          font-weight: 700;
          margin-bottom: 12px;
        }
      }

      .course_rcmd__detail___cto {
        padding: 24px 19px;
        background-color: #393a3c;
        border-radius: 4px;
        margin: 0 auto;
        display: flex;
        text-align: left;
        justify-content: center;
        align-items: center;
        @include desktop {
          border-radius: 8px;
          padding: 40px 109px;
          width: 840px;
          align-items: normal;
        }
        img{
          width: 92px;
          @include desktop {
            width: 140px;
          }
        }

        .course_rcmd__detail___pr {
          h2 {
            color: #eaebed;
            padding-left: 20px;
            font-size: 14px;
            font-weight: 700;
            @include desktop {
              font-size: 24px;
              padding-left: 35px;
              }
            }
          ul {
            color: #eaebed;
            padding-top: 8px;
            padding-left: 34px;
            font-size: 13px;
            text-align: left;
            line-height: 1.4;
            @include desktop {
              padding-top: 16px;
              font-size: 16px;
              padding-left: 60px;
            }

            li::before {
                content: "\2022";
                color: #c7c9cb;
                font-weight: bold;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
              }
            }
          }
        }
      }

    .course_rcmd__questions {
      width: 100%;
      overflow:hidden;
      padding: 40px 0px;
      div:nth-of-type(1) {
        padding-left:16px;
      }
      @include desktop {
        padding: 64px 0;
      }
    .course_rcmd__questions___cards {
      overflow-x :auto;
      overflow-y: hidden;
      width: 100%;
      padding-left: 16px;
      display: flex;
      justify-content: flex-start;
      scroll-snap-type: x mandatory;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
      @include desktop {
        justify-content: center;
      }
      .course_rcmd__questions___card {
        margin-right: 16px;
        flex: 0 0 auto;
        scroll-snap-align: center;
        padding: 18px 20px 20px 20px;
        text-align: left;
        width: 308px;
        height: 231px;
        border-radius: 4px;
        background-color: #393A3C;
        box-shadow: 0px 4px 24px 0 rgba(0, 0, 0, 0.48);
        @include desktop {
          margin-right: 24px;
          border-radius: 8px;
          width: 264px;
          height: 331px;
          padding: 38px 20px 27.67px 20px;
          }
        span {
          font-family: "Pretendard";
          color: $hanghae-primary-color;
          font-size: 18px;
          font-weight: 700;
          @include desktop {
            font-size: 20px;
          }
        }
        p {
          font-family: "Pretendard";
          padding: 4px 0 11px 0;
          color: white;
          font-size: 16px;
          font-weight: 700;
          line-height: 1.44;
          margin:0;
          @include desktop {
            padding: 8px 0 20px 0;
            font-size: 18px;
          }
        }
        .course_rcmd__questions___answer {
          font-family: "Pretendard";
          font-size: 13px;
          font-weight: 500;
          line-height: 1.4;
          color: #c7c9cb;
          white-space: pre-wrap;
          padding-left: 0 !important;
          margin-left: 0 !important;
          @include desktop {
            font-size: 15px;
          }
        }
      }
    }
    .course_rcmd__questions___dots {
      color: white;
      padding-top: 24px;
      display: flex;
      gap: 8px;
      justify-content: center;
      @include desktop {
        display: none;
      }
      .course_rcmd__questions___hidden {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: white;
        padding: 0;
      }
      .course_rcmd__questions___shown {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $hanghae-primary-color;
        padding: 0;
      }
    }
  }
    .course_rcmd__schedule {
      font-family: "Pretendard";
      padding: 40px 16px 110px 16px;
      @include desktop {
        padding: 64px 0 223px 0;
      }
      .course_rcmd__schedule___box {
        padding: 24px 0 24px 24px;
        font-family: "Pretendard";
        background-color: #393a3c;
        height: 224px;
        border-radius: 4px;
        margin: 0 auto;
        @include desktop {
          width: 840px;
          height: 332px;
          padding: 48px 0 0 228px;
          border-radius: 8px;
          }
        div:nth-of-type(3) {
          padding-top: 12px;
          @include desktop {
            padding-top: 24px;
          }
        }
        div:nth-of-type(4) {
          padding-top: 10px;
          @include desktop {
            padding-top: 0px;
          }
        }
        .course_rcmd__schedule___category {
          font-family: "Pretendard";
          display: flex;
          align-items: baseline;
          p {
            font-family: "Pretendard";
            padding-left: 15px;
            font-size: 14px;
            font-weight: 600;
            color: white;
            line-height: 1.44;
            margin: 0;
            @include desktop {
              font-size: 18px;
              padding-left: 24px;
            }
            span {
              font-family: "Pretendard";
              font-size: 12px;
              font-weight: 400;
              @include desktop {
                font-size: 18px;
              }
            }
          }

          .course_rcmd__schedule___category____title {
            font-family: "Pretendard";
            background-color: white;
            color: #1c1d1e;
            padding: 4px 0;
            text-align: center;
            border-radius: 4px;
            font-weight: 700;
            font-size: 13px;
            line-height: 1.4;
            width: 50px;
            margin-bottom: 16px;
            @include desktop {
              width: 74px;
              font-size: 18px;
              padding: 8px 0px;
            }
          }
        }
          .course_rcmd__schedule___section {
            display:flex;
            align-items: baseline;
            text-align: left;
            @include desktop {
              br {
                display: none;
              }
            }
          p {
            font-family: "Pretendard";
            padding-left: 15px;
            font-size: 14px;
            font-weight: 500;
            color: white;
            line-height: 1.44;
            margin: 0;
            @include desktop {
              font-size: 18px;
              padding-left: 24px;
            }
          }
          .course_rcmd__schedule___section____title {
            font-family: "Pretendard";
            background-color: #505254;
            color: #eaebed;
            padding: 4px 0;
            text-align: center;
            border-radius: 4px;
            font-weight: 700;
            font-size: 13px;
            line-height: 1.4;
            width: 50px;
            margin-bottom: 16px;
            @include desktop {
              width: 74px;
              font-size: 18px;
              padding: 8px 0px;
            }
          }
        }
      }
      .course_rcmd__footer {
        button {
          display: none;
          @include desktop {
            font-family: "Pretendard";
            margin: 40px auto 24px auto;
            color: white;
            background: $hanghae-primary-color;
            border-radius: 4px;
            width: 314px;
            height: 56px;
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            outline: none;
            border: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        p {
          color: #eaebed;
          font-family: 'Pretendard';
          line-height: 1.5;
          font-size: 13px;
          margin-bottom: 0;
          padding-top: 40px;
          text-align: center;
          @include desktop {
            font-size: 16px;
            padding-top: 0px;
          }
          span {
            font-family: "Pretendard";
            font-weight: 700;
            text-decoration: underline;
            text-underline-position: under;
            cursor: pointer;
          }
        }
      }
    }
  }
}


