@import '../../../../../../_styles/utils';

.RtannyCharacterContainer {
  display: flex;
  justify-content: center;
  -webkit-animation-name: running;
  -webkit-animation-duration: 3s;
  -webkit-animation-delay: 0.5s;
  -webkit-animation-fill-mode: forwards;

    @-webkit-keyframes running {
    0% {
      transform: translateX(var(--initialX));
    }
    100% {
      transform: translateX(var(--moveX));
    }
  }

  .UserInfoContainer {
    position: absolute;
    margin-top: -100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}