@import "../../../../_styles/utils";

.navbar-wrapper {
  @include mobile {
    background-color: $pale-gray;
  }
}

.navbar-container-wrapper {
  display: none;
  @include mobile {
    display: flex;
    flex-direction: row;
    gap: 24px;
  }
}

.navbar-container {
  max-width: $maxWidth;
  width: 100%;
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mobile {
    background-color: #f1f3f4;
    max-width: 360px;
  }
  &__classroom {
    display: none;
    @include mobile {
      display: flex;
      margin-top: 14px;
      font-size: 15px;
      font-weight: 500;
      margin-right: 0px;
    }
  }

  &__mypage {
    display: none;
    @include mobile {
      display: flex;
      margin-top: 14px;
      font-size: 15px;
      font-weight: 500;
    }
  }

  &__logo {
    cursor: pointer;
    display: flex;
    align-items: center;

    &__img {
      height: 40px;
      @include mobile {
        margin-top: 14px;
      }
    }

    &__font {
      margin-left: 9px;
      width: 110px;
      height: 39px;
      font-family: "webpixel";
      font-size: 38px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      color: #e8344e;

      @include mobile {
        font-size: 28px;
        font-weight: 700;
        letter-spacing: -2px;
      }
    }
  }

  &__contents {
    display: flex;
    align-items: center;
    justify-content: center;
    @include mobile {
      display: none;
    }
  }
}

.studyplan-component {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:first-child {
    margin-top: 0;
  }

  &__day {
    display: flex;
    align-items: center;
    font-family: $spoqa-hans-neo;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -0.96px;
    color: black;

    &__name {
      font-family: $spoqa-hans-neo;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.96px;
      color: black;
    }

    &__qna {
      margin-left: 4.7px;
      font-family: $spoqa-hans-neo;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.84px;
      text-align: left;
      color: $primary-color;
    }
  }

  &__checkbox {
    input[type="checkbox"] {
      position: relative;
      cursor: pointer;
    }

    input[type="checkbox"]:before {
      content: "";
      display: block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 0;
      left: 0;
      border: 2px solid #555555;
      border-radius: 3px;
      background-color: white;
      @include mobile {
        top: -2px;
        left: -2px;
      }
    }

    input[type="checkbox"]:checked:before {
      content: "";
      display: block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 0;
      left: 0;
      border: 2px solid black;
      border-radius: 3px;
      background-color: black;
      @include mobile {
        top: -2px;
        left: -2px;
      }
    }

    input[type="checkbox"]:checked:after {
      content: "";
      display: block;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      top: 2px;
      left: 6px;
      @include mobile {
        top: 0px;
        left: 4px;
      }
    }
  }
}

.erd-component {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
  cursor: pointer;

  &__img {
    width: 36.4px;
    height: 42px;
    object-fit: contain;
    margin-right: 11.1px;
  }

  &__text {
    width: 118px;
    font-family: $spoqa-hans-neo;
    padding-top: 1px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.48px;
    text-align: left;
    color: black;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.point-component {
  &__img {
  }

  &__text {
    font-family: $spoqa-hans-neo;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -0.96px;
    color: $black;
  }
}

.profile-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__el {
    cursor: pointer;
    font-family: $spoqa-hans-neo;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -0.96px;
    text-align: center;
    color: black;
    margin-top: 16px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__el--active {
    cursor: pointer;
    font-family: $spoqa-hans-neo;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -0.96px;
    text-align: center;
    color: #e8344e;
  }
}
