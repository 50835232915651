@import "../../../../../_styles/utils";

.classRow {
  .card_container {
    width: 100%;
    margin-bottom: 25px;

    .courseIcon {
      img,
      .coursePadding {
        width: 48px;
        height: 54.5px;
        margin-left: 25px;
      }
    }

    .card {
      margin-top: -25px;
      box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.15);
      padding: 36px 24px 24px;
      background-color: #ffffff;
      border-radius: 24px;

      .course_title {
        font-family: $spoqa-hans-neo;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.17;
        letter-spacing: normal;
        margin-bottom: 8px;
      }

      .divider {
        width: 100%;
        height: 2px;
        background-color: #d4d4d4;
      }

      .course_duration {
        display: flex;
        align-items: center;
        margin-top: 10px;
        font-size: 16px;
        font-family: $spoqa-hans-neo;
        font-weight: 500;
        @include desktop {
          font-size: 13px;
        }

        .course_weeks {
          background-color: #f1f1f1;
          border-radius: 100px;
          padding: 3.5px 10.5px;
          font-size: 12px;
          @include desktop {
            font-size: 14px;
          }
        }

        .course_days {
          line-height: 1.14;
          padding: 3px 5px;
          font-size: 12px;
          @include desktop {
            font-size: 14px;
          }

          &.done {
            padding: 2px 5px;
          }
        }

        .course_revision {
          padding: 3px 5px;
          color: #e8344e;
          line-height: 1.14;
          margin-left: auto;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .bottom_cta {
        margin-top: 20px;
      }
    }
  }

  .card_container:hover {
    -webkit-animation-name: grow;
    -webkit-animation-duration: 0.2s;
    animation-fill-mode: forwards;

    .card {
      border: 2px solid #e8344e;
      padding: 36px 20px 24px;
      cursor: default;
    }
  }
}

.catalog_button {
  margin: 0 auto;
  width: 95%;
  border-radius: 24px;
  text-align: center;
  padding: 10px 0px;
  font-family: $spoqa-hans-neo;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: none;
  background-image: linear-gradient(232deg, #ff6c7a, #e8344e);
  color: #ffffff;
}

@-webkit-keyframes grow {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}
