@import '../../_styles/utils.scss';

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.active {
  display: block !important;
}

.modal-background {
  overflow-y: hidden;
  display: none;
  position: fixed;
  z-index: 2;
  margin: auto;
  background-color: white;
  width: 95%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  border: none;
  padding: 20px 8px;

  @include desktop {
    width: 550px;
  }

  @include adesktop {
    width: 320px;
  }

  .modal-content {
    //margin: 90px 0px;
    //height: 300px;
    vertical-align: center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;

    .reimburse-main {
      .reimburse-body {
        img {
          height: 179px;
          width: auto;
          @include adesktop {
            height: 100px;
          }
        }

        h2 {
          font-weight: 700;
          font-size: 22px;
          margin: 25px auto 30px;
          letter-spacing: -1px;
          white-space: pre;
          @include adesktop {
            font-size: 18px;
          }
        }

        p {
          font-weight: 500;
          font-size: 16px;
          letter-spacing: -1px;
          color: #495057;
          white-space: pre;
          @include adesktop {
            font-size: 13px;
          }
        }

      }
    }
  }
}

.reimburse-buttons {
  margin: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include adesktop {
    margin: 30px 0px;
  }

  .review-and-nudge {
    display: flex;
    flex-direction: column;

    button {
      margin-bottom: 8px;
    }
  }

  button.cta {
    display: block;
    padding: 10px 70px;

    background-color: $hover-color;
    cursor: pointer;
    color: white;

    border: none;
    border-radius: 5px;

    font-size: 15px;
    font-weight: 700;
    letter-spacing: -0.5px;
  }

  button.cta:hover {
    background-color: $hover-color-deeper;
  }

  button.sub-cta {
    margin: 5px 0px;
    display: block;
    padding: 10px 70px;

    background-color: #fff;
    color: $hover-color;
    cursor: pointer;

    border: 1px solid $hover-color;
    border-radius: 5px;

    font-size: 15px;
    font-weight: 700;
    letter-spacing: -0.5px;
    @include adesktop {
      margin-top: 13px;
    }
  }

  button.sub-cta:hover {
    color: $hover-color-deeper;
    border: 1px solid $hover-color-deeper;
  }
}

.footer {
  padding-top: 15px;
  width: 100%;
  border-top: 1px solid #CED4DA;
  color: #495057;
  letter-spacing: -1px;
  font-weight: 500;
  .footer-body {
    width: 270px;
    margin: auto;
    text-align: left;
    ul {
      margin-left: 20px;
      li {
        list-style-type: circle;
      }
    }
  }
}