@import '../../../../_styles/utils';

.checkin-cards-wrap {
  width: 1123px;
  margin: 0 auto;
  box-sizing: border-box;
  margin-top: 28px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

}