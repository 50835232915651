@import '../../../../../_styles/utils';

.checkinCard {
  box-sizing: border-box;
  width: 348px;
  background: #ffffff;
  padding: 29px 15px 34.4px 24px;
  border-radius: 24px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;

  .checkinCardContent {
    width: 100%;

    p {
      font-size: 16px;
      line-height: 1.6;
      letter-spacing: -1px;
      color: #000;
      font-family: $spoqa-hans-neo;
    }

  }

  .checkinCardAuthor {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;

    .checkinCardAuthorDetail {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      img {
        width: 22px;
        height: 22px;
      }

      .author {
        font-size: 14px;
        line-height: 1;
        letter-spacing: -0.48px;
        color: #000;
        font-weight: 500;
        margin: 0 8px;
      }

      .before {
        font-size: 14px;
        line-height: 1;
        letter-spacing: -0.42px;
        color: #868E96;
        font-weight: 500;
      }

    }

    .checkinCardAuthorCourse {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 16px;

      img {
        width: 25px;
      }

      span {
        font-family: $spoqa-hans-neo;
        font-size: 14px;
        color: #000;
        line-height: 1.4;
        letter-spacing: -0.48px;
        font-weight: 500;
        margin-left: 8px;
      }
    }
  }

  &.hero {
    width: 100%;

    .checkinCardAuthor > .checkinCardAuthorCourse > img {
      height: 35px;
      width: 30px;
    }
  }

  &.mine {
    border: 2px solid #000;
  }

  .checkinCardHeart {
    position: absolute;
    width: 80px;
    height: 40px;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 30px;
    bottom: -20px;
    right: 24px;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;

    img {
      width: 20px;
    }

    span {
      font-family: $spoqa-hans-neo;
      color: #000;
      font-size: 14px;
      line-height: 1;
      font-weight: 500;
      letter-spacing: -0.42px;
    }

    &:hover {
      background: #f1f3f5;
    }

    &.liked {
      background: #000;
      cursor: initial;

      span {
        color: #fff;
      }
    }
  }
}