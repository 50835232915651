@import '../../_styles/utils.scss';


.tableContainer {
  margin-top: 8px;
}

table {
  .desc {
    .prize {
      font-weight: 500;
      color: #E8344E;
      background-color: #f5f5f5;
      padding: 3px 5px;
      border-radius: 5px;
    }
  }


  .time {
    color: #868E96;
  }
}