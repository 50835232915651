@import "../../../../../../_styles/utils";

.course-summary {
  display: flex;
  flex-direction: row;
  padding-top: 8px;
  width: 100%;
  @include desktop {
    padding-top: 16px;
  }

  .course-remain {
    width: 50%;
    position: relative;
    font-family: $spoqa-hans-neo;
    @include desktop {
      width: 145px;
    }

    @include mobile {
      margin-right: 25px;
    }

    .course-remain-title {
      margin-bottom: 0;
      font-size: 12px;
      font-family: $spoqa-hans-neo;
      color: #000;
      font-weight: 500;
      @include desktop {
        margin-bottom: 8px;
        font-size: 14px;
      }

      &.red {
        color: #e8344e;
      }
    }

    //.course-remain-title-red {
    //  margin-bottom: 0;
    //  font-size: 12px;
    //  font-family: $spoqa-hans-neo;
    //  font-weight: 500;
    //  @include desktop {
    //    margin-bottom: 8px;
    //    font-size: 14px;
    //  }
    //}
    .course-remain-detail {
      b {
        font-size: 18px;
        font-weight: bold;
        font-family: $spoqa-hans-neo;
        @include desktop {
          font-size: 28px;
        }
      }

      font-size: 12px;
      font-weight: 500;
      color: #000;
      @include desktop {
        font-size: 14px;
      }

      &.--isExpired {
        font-size: 20px;
        font-weight: bold;
      }
    }

    .course-gov-not::after {
      width: 1px;
      content: "";
      height: 48px;
      background: #dee2e6;
      position: absolute;
      top: 18px;
      right: 0;
      display: none;
      @include desktop {
        display: block;
      }
    }
  }

  .course-progress {
    width: 50%;
    @include desktop {
      margin-left: 55px;
    }

    .course-progress-title {
      margin-bottom: 0;
      font-size: 12px;
      color: #000;
      font-weight: 500;
      font-family: $spoqa-hans-neo;
      @include desktop {
        margin-bottom: 8px;
        font-size: 14px;
      }
    }

    .course-progress-detail {
      b {
        font-size: 20px;
        font-weight: bold;
        font-family: $spoqa-hans-neo;
        @include mobile {
          line-height: 1.7;
        }
        @include desktop {
          font-size: 28px;
        }
      }

      font-size: 16px;
      font-weight: 500;
      color: #000;
    }
  }
}
