@import "_styles/antd.scss";
@import "_styles/antd_custom.scss";
@import "_styles/editor.scss";
@import "fonts/webfont.normal.css";
@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);

* {
  font-family: "Noto Sans KR", "Source Sans Pro", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Noto Sans KR", "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //background-color: #f5f5f5;
}
#root {
  width: 100%;
  height: 100%;
}
::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.ant-card-body {
  padding: 20px 10px;
}
