@import '../../../_styles/utils.scss';

.dashboardWrapper {

  h2 {
    letter-spacing: -1.5px;

    @include adesktop {
      font-size: 16px;
    }
  }

  .dashboardContentWrapper {
    padding: 22px 20px 10px;
    background-color: white;
    border-radius: 10px;


    .myAchieveWrapper {
      width: 40%;
      display: inline-block;

      @include adesktop {
        display: none;
        width: 0;
      }

      .myAchiveContainer {
        position: relative;
        @include adesktop {
          display: none;
        }

        .myAchieveTitle {
          font-size: 17px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: -1px;

          color: #13c2c2;
          border: 1px solid #13c2c2;
          border-radius: 10px;
          padding: 10px;
          background: white;
          display: inline-block;
          vertical-align: top;
          position: absolute;
          bottom: 55px;
          left: 65px;
          z-index: 1;

          &.en {
            bottom: -8px;
            left: 48px;
          }
        }

        .myAchieveCircle {
          display: inline-block;
          width: 155px;
          margin-left: 27px;

          p {
            color: #13c2c2;
            font-size: 13px;

            span.percent {
              font-weight: bold;
              font-size: 32px;
            }
          }
        }

        .myAchiveRank {
          text-align: left;
          margin-bottom: 16px;
          margin-top: 25px;

          button {
            &.empty_rank {
              background: none;
              padding: 0;
            }

            background: #13c2c2;
            border-radius: 5px;
            color: white;
            font-size: 18px;
            line-height: 0;
            border: none;
            font-weight: 500;
            letter-spacing: -1px;
            padding: 20px 60px;

          }
        }
      }


    }

    .myInfoWrapper {
      width: 60%;
      display: inline-block;
      vertical-align: top;

      @include adesktop {
        width: 100%;
      }

      .todoLectureWrapper {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;

        @include adesktop {
          flex-direction: column;
          align-items: flex-start;
        }

        .weekInfo {
          font-size: 26px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: -1.56px;
          color: #343a40;
          margin-right: 10px;

          @include adesktop {
            font-size: 14px;
          }
        }

        .lectureTitleInfo {
          margin-left: 30px;
          font-size: 30px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.73;
          letter-spacing: -1.8px;
          color: #343a40;
          @include adesktop {
            font-size: 18px;
          }
        }

        .lectureButton {
          width: 208px;
          height: 55px;
          border-radius: 5px;
          background-color: #e8344e;
          font-size: 22px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.36;
          letter-spacing: -0.66px;
          color: #ffffff;

          @include adesktop {
            width: 100%;
            font-size: 16px;
            height: 42px;
            margin-top: 16px;
          }
        }

        .lectureButton:hover {
          background-color: #C9001D;
          border-color: #C9001D;

        }
      }

      .etcWrapper {
        margin-top: 40px;
        @include adesktop {
          margin-top: 15px;
        }

        .etcBlock {
          width: 33%;
          display: inline-block;

          &:last-child {
            position: relative;
          }

          @include adesktop {
            width: 35%;
            &:first-child {
              width: 26%;
            }
            &:last-child {
              width: 38%;
            }
          }


          .etcContainer {
            display: flex;
            flex-direction: row;

            .etcImgContainer {
              width: 20%;
              padding-top: 6px;
              @include adesktop {
                padding-top: 0;
              }

              img {
                width: 23px;
                @include adesktop {
                  width: 13px;
                }
              }
            }

            .etcTextContainer {
              width: 80%;

              .etcTextContent {
                display: block;
                color: #13c2c2;
                font-size: 22px;
                letter-spacing: -1.1px;

                @include adesktop {
                  font-size: 14px;
                }
              }

              .etcTextTitle {
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.25;
                letter-spacing: -0.8px;
                color: #212529;

                @include adesktop {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }


    }
  }
}

.studyplan_edit {
  position: absolute;
  top: 3px;
  right: 14px;
  background-image: var(--bg-image);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 28px;
  height: 28px;

  @include adesktop {
    width: 19px;
    height: 19px;
    top: 1px;
    right: -10px;
  }
}

.studyplan_edit:hover {
  object-fit: contain;
  background-color: rgba(116, 122, 122, 0.14);
  border-radius: 100px;
  cursor: pointer;
}

.edit_text {
  position: absolute;
  top: 35px;
  right: 1px;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.55px;
  color: #ffffff;
  padding: 5px 7px;
  border-radius: 1px;
  background-color: #868e96;
}