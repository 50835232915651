@import '../../_styles/utils';


.modal-body {
  width: 100%;

  .event-title {
    padding: 17px 20px 13px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #212529;
    letter-spacing: -1px;
    font-size: 20px;

    span:first-child {
      font-weight: 500;
      font-size: 14px;

      @include desktop {
        font-size: 20px;
      }
    }

    span {
      font-family: 'Black Han Sans', sans-serif;
      font-weight: 500;
      font-size: 20px;

      @include desktop {
        font-size: 28px;
      }
    }
  }

  .header {
    text-align: center;
    margin: 23px auto;
    @include desktop {
      text-align: center;
      margin: 42px auto;
    }

    h3 {
      font-size: 17px;
      font-weight: 500;
      letter-spacing: -1px;
      @include desktop {
        font-size: 21px;
      }

      span.pink-background {
        background-color: rgba(232, 52, 78, 0.2);
      }
    }

    p {
      line-height: 25px;
      font-size: 14px;
      color: black;
      font-weight: 500;
      letter-spacing: -0.5px;
      margin-top: 17px;
      @include desktop {
        margin-top: 40px;
      }
    }
  }

  .body {
    margin-top: 10px;
    @include desktop {
      margin-top: 34px;
    }

    .friend {
      padding: 4px 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 300px;
      margin: auto;
      @include desktop {
        width: 436px;
      }

      input {
        height: 30px;
        border: 1px solid #e2e3e4;
        border-radius: 5px;
        font-size: 14px;
        background-color: white;
        color: black;
        padding: 5px;
        text-align: center;

        @include desktop {
          font-size: 17px;
          height: 38px;
          padding: 10px;
        }
      }

      input::placeholder {
        text-align: center;
        color: #C3D0DD;
      }

      input.dark {
        background-color: #E9ECEF;
      }

      .number {
        background-color: #212529;
        font-weight: 700;
        border-radius: 30px;
        color: white;
        font-size: 12px;
        padding: 2px 7px;

        @include desktop {
          font-size: 21px;
          padding: 2px 25px;
        }
      }

      .number-inactive {
        background-color: #ADB5BD;
        font-weight: 700;
        border-radius: 30px;
        color: white;
        font-size: 12px;
        padding: 2px 7px;

        @include desktop {
          font-size: 21px;
          padding: 2px 25px;
        }
      }

      .label {
        input {
          width: 70px;

          @include desktop {
            width: 100px;
          }
        }
      }

      .phone {
        input {
          width: 170px;
          @include desktop {
            width: 235px;
          }
        }
      }
    }
  }

  .buttonContainer {
    text-align: center;
    margin-top: 23px;
    @include desktop {
      margin-top: 28px;
    }

    button {
      width: 234px;
      padding: 10px 33px;
      border-radius: 10px;
      border: none;
      font-size: 19px;
      font-weight: 500;
      background-color: $primary-color;
      color: white;
      //letter-spacing: -1px;
      @include desktop {
        width: 280px;
        padding: 10px 57px;
        font-size: 23px;
      }
    }

    button.isSending {
      width: 280px;
      padding: 10px 57px;
      border-radius: 10px;
      border: none;
      font-size: 23px;
      font-weight: 500;
      background-color: rgba(232, 52, 78, 0.2);
      color: white;
    }

    button.isSending:hover {
      background-color: rgba(232, 52, 78, 0.2);
    }

    button:hover {
      background-color: $primary-hover-color;
    }

    .subCTA {
      margin-top: 10px;
      span {
        cursor: pointer;
        font-size: 12px;
        border-bottom: 1px dotted;
      }
      span:hover {
        border-bottom: 1px solid;
      }
    }
  }
}