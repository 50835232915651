@import '../../../../_styles/utils';

.no-code-snippet {
  width: 100%;
  margin: 57px auto;
  text-align: center;

  img {
    width: 188px;
  }
}

.codeSnippetsContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 9px 30px 40px 15px;

  .codeSnippet {
    width: 394px;
    box-sizing: border-box;
    padding: 19px 24px 19px 24px;
    margin-top: 8px;
    font-size: 16px;
    line-height: 1;
    color: #000;
    letter-spacing: -1px;
    font-weight: 500;
    border: solid 1px #e9ecef;
    border-radius: 24px;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &.hover {
      background: #000;
      border: solid 1px #000;
      color: #fff;
      text-align: center;
      padding: 19px 24px;
    }
  }
}

.dual.codeSnippetsContainer {
  padding: 0;
  .codeSnippet {
    margin-top: 16px;
    width: 295px;
  }
}
