@import "../../_styles/utils.scss";

div.fireworks {
  width: 100vw;
  height: 100vh;
  background-image: var(--bg-image);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.treasure {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 25px;
  letter-spacing: -1px;

  img {
    width: 400px;
    height: 300px;
    @include adesktop {
      width: 300px;
      height: 225px;
    }
  }

  h2 {
    @include adesktop {
      font-size: 23px;
    }
  }

  h4 {
    @include adesktop {
      font-size: 18px;
    }
  }
}

.story {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 25px;
  letter-spacing: -1px;
  max-width: 1130px;
  width: 100%;

  &__letter {

  }

  &__intro {
    font-family: NotoSansCJKKR;

    h2 {
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.27;
      letter-spacing: -0.66px;
      text-align: center;
      color: $black;
      margin-bottom: 3px;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: -0.48px;
      text-align: center;
      color: #8b8b8b;
    }
  }

  &__treasure {
    width: 400px;
  }

  &__loading {
    width: 315px;
    border-radius: 40px;
    background-color: #d4d4d4;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    margin: 32px auto;

    &__text {
      font-family: NotoSansCJKKR;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 8px;


      p {
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: -0.54px;
        text-align: left;
        color: $black;
        margin-bottom: 3px;
      }

      span {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: -0.42px;
        text-align: left;
        color: #8b8b8b;
      }
    }
  }
}