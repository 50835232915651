@import "../../../../_styles/utils";

.round-week-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 23px;

  @include tablet {
    flex-direction: row;
  }

  @include desktop {
    flex-direction: row;
  }

  &__summary {
    @include mobile {
      width: 100%;
    }

    &__title {
      margin: 0 0 16px 0.3px;
      font-family: $spoqa-hans-neo;
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.72px;
      text-align: left;
      color: black;
      padding: 4px 0;
      width: 445px;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: flex;
      @include mobile {
        width: 100%;
        font-size: 20px;
        align-items: center;
      }

      &.pirates {
        color: #EEF3F6;
      }

      &__text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 20px;
        max-width: 100%; /* 또는 필요한 값 */

        @include mobile {
          width: 100%;
          font-size: 18px;
          align-items: center;
          max-width: 100%; /* 또는 필요한 값 */
        }
      }

      &__label {
        font-family: $gmarketSans;
        font-size: 12px;
        font-weight: bold;
        color: $white;
        padding: 6px 10px;
        background-color: $primary-color;
        border-radius: 100px;
        margin-right: 8px;
        @include mobile {
          display: none;
        }
      }
    }

    &__detail {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 360px;
      @include mobile {
        display: none;
      }

      &__obj {
        font-family: $spoqa-hans-neo;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: -0.84px;
        text-align: left;
        color: black;
        display: flex;
        align-items: center;
        gap: 16px;

        &.pirates {
          color: #B4BFC6;
        }
      }

      &__material {
        a {
          font-family: $spoqa-hans-neo;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: -0.96px;
          text-align: left;
          color: black;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-left: 13.6px;
          padding: 10px 25px;
          border-radius: 100px;
          background-color: $pale-gray;
          cursor: pointer;
          gap: 8px;
        }

        &.pirates {
          a {
            color: #EEF3F6;
            background-color: #5F666B;
          }
        }
      }
    }
  }

  &__colleague {
    @include mobile {
      display: none;
    }
  }
}
