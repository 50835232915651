@import '../../_styles/utils';

.backgroundWrapper {
  //background-color: #e3e3e3;
  @include desktop {
    //padding-bottom: 30px;
  }


  .contentWrapper {
    padding: 0;
    background-color: white;
    padding-bottom: 60px;

    @include mobile {
      width: 100%;
    }
    @include tablet {
      width: 680px;
      margin: 0 auto;
    }
    @include desktop {
      width: 680px;
      margin: 0 auto;
    }

    h3 {
      font-size: 25px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.58;
      letter-spacing: -1.25px;
      color: white;
      margin: 0;
    }

    .headerWrapper {
      background-color: #333333;
      padding: 7px 20px;
      color: white;

      &.friend_reco h3 {
        font-size: 14px;
      }
    }

    .formWrapper {

      .titletWrapper {
        padding: 7px 20px;
        background-color: #f5f5f5;

        label {
          margin-top: 10px;
          width: 254px;
          font-size: 17px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.53;
          letter-spacing: -0.75px;
          color: #000000;

        }
      }

      .content {
        background-color: white;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        position: relative;

        .promo-basic {
          padding: 0px;
          font-size: 14px;
          @include mobile {
            font-size: 12px;
          }
        }
      }

      .course-title {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.53;
        letter-spacing: -1px;
        color: #000000;

        display: flex;
        flex-direction: row;
        /* justify-content: center; */
        align-items: center;


        .urgentContainer {
          margin-left: 5px;
          background-color: #E8344E;
          padding: 2px 6px;
          border-radius: 50px;
          color: white;
          letter-spacing: -0.5px;
          font-weight: 700;
          font-size: 14px;
        }
      }

      .course-date {
        margin-top: 7px;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: -0.34px;
        color: #828282;
        margin-bottom: 20px;
        display: flex;
      }

      .course-tutor {
        display: none;
      }

      .info-label {
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: -0.34px;
        color: #828282;
      }

      .info-detail {
        margin-top: 7px;
        margin-bottom: 20px;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: -0.34px;
        color: #333333;
      }

      .info-more {
        cursor: pointer;
        position: absolute;
        top: 52px;
        right: 20px;
      }

      table {
        width: 100%;

        .finalContainer {
          border-top: 1px solid;
        }
      }

      img.right-now {
        width: 90px;
        margin-left: 12px;
      }

      table td {
        padding: 0.75rem 0;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: -0.34px;
        color: #828282;

        &.friend-reco {
          color: #e8344e
        }

        span {
          color: #e8344e;
          font-size: 14px;
          line-height: 14px;
          margin-left: 6px;
          @include mobile {
            font-size: 12px;
            line-height: 12px;
          }

          br {
            display: none;
            @include mobile {
              display: block;
            }
          }
        }

        span.installment {
          color: #828282;
          font-size: 13px;
          line-height: 13px;
          letter-spacing: -1px;
          margin-left: 12px;
          cursor: pointer;
          text-decoration: underline;
        }

        div.installment_info {
          width: 226px;
          position: absolute;
          top: 45px;
          z-index: 1;

          img {
            width: 100%;
          }
        }
      }

      td:last-child {
        text-align: right;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: -0.34px;
        color: #333333;
      }

      td.final {
        font-size: 17px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: -0.51px;
        color: #333333;
        position: relative;

        span.discount-ratio {
          position: absolute;
          right: 93px;
          font-size: 17px;
          top: 17px;
        }
      }

      tr.couponContainer > td {
        color: #e8344e;
      }

      td.highlight-text {
        color: #e8344e;
      }

      td.coupon-highlight {
        color: #e8344e;
      }

      .gold-residue {
        padding: 0px 0px 12px 0px !important;
        font-size: 14px !important;
      }

      .promo {
        margin: 15px 0 20px 0;

        input {
          width: 100%;
          height: 38px;
          border: 1px solid #e2e3e4;
          border-radius: 5px;
          font-size: 14px;
          background-color: white;
          color: #959595;
          text-align: right;
        }

      }

      .payment {
        input[type=radio]:checked + label:before {
          //background-image: url("");
        }
      }

      ul li {
        list-style: none;
        float: left;
        margin-left: 18%;
        width: 30%;
      }

      label {
        font-size: 17px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: -0.6px;
        color: #333333;
        padding: 0;
      }

      .privacy-form {
        padding: 10px 0;
        width: 100%;
        margin: 0 auto;
        text-align: center;
      }

      .privacy {
        padding: 0.75rem 0;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.34px;
        color: #828282;
      }


      button {
        width: 100%;
        margin: 10px auto;
        display: block;
        height: auto;
        font-size: 22px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: -0.66px;
        padding: 12px 0;
        border-radius: 6px;
      }

      .bottomForm {
        padding: 0 20px;

      }
    }
  }
}

.backgroundWrapper.revised {
  .contentWrapper {
    .formWrapper {
      > div {
        border-bottom: 2px solid #f1f3f5;
      }

      > div:last-child {
        border-bottom: none;
      }

      .titletWrapper {
        background-color: inherit;

        label {
          font-size: 20px;
          @include mobile {
            font-size: 18px;
          }
        }
      }

      table td {
        padding: 0.5rem 0;
        @include mobile {
          font-size: 14px;
        }
      }

      .content {
        padding-top: 0;
        padding-bottom: 12px;
        @include mobile {
          padding-bottom: 5px;
        }

        .course-title {
          font-size: 20px;
          @include mobile {
            font-size: 17px;
          }
        }

        .course-thumb {
          width: 102px;
          display: inline-block;
          @include mobile {
            width: 68px;
            vertical-align: top;
          }

          img {
            width: 100%;
          }
        }

        .course-detail {
          display: inline-block;
          vertical-align: middle;
          margin-left: 30px;
          width: 75%;
          @include mobile {
            vertical-align: top;
            margin-left: 9px;
          }

          .course-date {
            display: inline-block;
            margin-left: 10px;
            font-size: 16px;
            color: #212529;
            letter-spacing: -1.1px;
            @include mobile {
              font-size: 15px;
              margin-bottom: 8px;
            }
          }

          .course-tutor {
            display: inline-block;
            font-size: 16px;
            color: #212529;
            letter-spacing: -1.1px;
            @include mobile {
              font-size: 15px;
            }
          }
        }

        .feature {
          padding-top: 4px;
          padding-bottom: 4px;
        }
      }

      .info-label {
        font-size: 16px;
        @include mobile {
          font-size: 14px;
          margin-bottom: 7px;
        }
      }

      .info-detail {
        margin-bottom: 14px;
        font-size: 16px;
        @include mobile {
          font-size: 14px;
          margin-bottom: 10px;
        }
      }

      div.price-detail {
        width: 100%;
        background-color: #f1f3f5;
        padding: 6px 0 20px 13px;
        @include mobile {
          padding-left: 5px;
          padding-bottom: 8px;
        }

        div {
          display: inline-block;
          width: 45%;
          color: black;
          font-size: 16px;
          line-height: 1;
          letter-spacing: -0.8px;
          font-weight: 500;
          padding-top: 14px;
          @include mobile {
            width: 50%;
            font-size: 14px;
            padding-top: 7px;
          }

          img {
            margin-right: 10px;
            width: 18px;
          }
        }
      }
    }
  }
}

.userinfoContainer {
  input[type=text].userInfo {
    border-radius: 6px;
    border: solid 1px #c4c4c4;
    padding: 10px;
    background-color: white;
    width: 100%;
    font-size: 17px;
    color: #959595;
  }
}


@media only screen and (max-width: 600px) {
  .contentWrapper {
    width: 100%;
    margin: 0 auto;
    padding: 0;

    h3 {
      font-size: 25px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.58;
      letter-spacing: -1.25px;
      color: #000000;
      margin: 0;
    }

    .formWrapper {

      .titletWrapper {
        padding: 7px 20px;

        label {
          margin-top: 10px;
          width: 254px;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.53;
          letter-spacing: -0.75px;
          color: #000000;
        }
      }

      .content {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
      }

      .course-title {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.53;
        letter-spacing: -1px;
        color: #000000;
      }

      .course-date {
        margin-top: 7px;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: -0.34px;
        color: #828282;
        margin-bottom: 20px;
      }

      .info-label {
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: -0.34px;
        color: #828282;
      }

      .info-detail {
        margin-top: 7px;
        margin-bottom: 20px;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: -0.34px;
        color: #333333;
      }

      table {
        width: 100%;
      }

      table td {
        padding: 0.75rem 0.75rem 0.75rem 0;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: -0.34px;
        color: #828282;
      }

      td:last-child {
        text-align: right;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: -0.34px;
        color: #333333;
      }

      td.final {
        font-size: 17px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: -0.51px;
        color: #333333;
      }

      .promo-label {
        display: none;
      }


      input[type=text] {
        width: 100%;
        border: 1px solid #e2e3e4;
        border-radius: 5px;
        font-size: 14px;
        background-color: white;
        color: #959595;
      }

      .promo {
        margin: 15px 0 20px 0;

        label {
          display: none;
        }

        input {
          width: 100%;
          height: 38px;
          border: 1px solid #e2e3e4;
          border-radius: 5px;
          font-size: 14px;
          background-color: white;
          color: #959595;
        }
      }

      .payment {
        input[type=radio]:checked + label:before {
          //background-image: url("");
        }
      }

      ul li {
        list-style: none;
        float: left;
        width: 50%;
        margin-left: 0px;
        margin-bottom: 30px;
      }

      label {
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: -0.6px;
        color: #333333;
        padding: 0;
      }

      .privacy-form {
        line-height: 20px;
        width: 100%;
        margin: 0 auto;
        text-align: center;
      }

      .privacy {
        padding: 0.75rem 0.75rem 0.75rem 0;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.34px;
        color: #828282;
      }


      button {
        display: block;
        width: 90%;
        margin: 10px auto;
        height: auto;
        font-size: 22px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: -0.66px;
        padding: 12px 0;
        border-radius: 6px;
      }
    }
  }
}

.revised .inputContainer {
  height: 38px;
  @include mobile {
    height: 38px;
  }
  @include tablet {
    height: 38px;
  }
  @include desktop {
    height: 38px;
  }

  input[type=text].myInput {
    @include mobile {
      width: calc(100% - 80px);
      font-size: 14px;
    }
    @include tablet {
      width: calc(100% - 100px);
      font-size: 16px;

    }
    @include desktop {
      width: calc(100% - 100px);
      font-size: 16px;
    }
  }
}

.inputContainer {
  border-radius: 6px;
  border: solid 1px #c4c4c4;
  display: flex;
  flex-direction: row;

  &.coupon {
    border: none;
  }

  @include mobile {
    height: 38px;
  }
  @include tablet {
    height: 46px;
  }
  @include desktop {
    height: 46px;
  }


  input[type=text].myInput {
    border: none;
    border-radius: 6px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: white;

    color: #959595;

    @include mobile {
      width: calc(100% - 80px);
      font-size: 14px;
    }
    @include tablet {
      width: calc(100% - 100px);
      font-size: 17px;

    }
    @include desktop {
      width: calc(100% - 100px);
      font-size: 17px;
    }
  }

  a {
    border-radius: 6px;
    color: #4f4f4f;
    background-color: #f5f5f5;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    place-content: center;
    align-items: center;

    @include mobile {
      width: 80px;
    }
    @include tablet {
      width: 100px;
    }
    @include desktop {
      width: 100px;
    }
  }
}

.display-price {
  text-decoration: line-through;
}

.todaydiscount-top {
  background-color: #13C2C2;
  padding: 10px 0px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;

  @include mobile {
    font-size: 18px;
  }
}

.todaydiscount-tooltip {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  text-align: center;
  border-radius: 5px;
  width: 300px;
  padding: 5px;
  margin: 0 auto;
}

.timer {
  background-color: #212529;
  color: white;
  padding: 18px 20px 20px 20px;

  .timerFirstRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .timerTitle {
      font-size: 24px;
      font-weight: 700;
      letter-spacing: -1.5px;
    }

    .timerClock {
      text-align: center;

      img {
        width: 18px;
        height: 18px;
        margin-right: 10px;
        margin-bottom: 6px;
      }

      span {
        font-size: 21px;
        font-weight: 700;
        letter-spacing: -1.5px;
      }
    }
  }

  .timerSecondRow {
    margin-top: 5px;
    font-size: 15px;
    letter-spacing: -0.5px;
    font-weight: 500;
  }
}

.featureContent {
  width: 100%;
  border: 1px solid #e2e3e4;
  border-radius: 5px;


  .feature {
    width: 100%;
    border-bottom: 1px solid #e2e3e4;
    padding: 10px 10px 8px 10px;

    img {
      width: 25px;
      height: 25px;
      margin-bottom: 3px;
      margin-right: 8px;
    }

    span {
      color: black;
      font-size: 17px;
      letter-spacing: -1px;
    }
  }


  .feature:last-child {
    border: none;
  }
}

table {
  input, input::placeholder {
    text-align: right;
  }
}

.paying {
  border: none;
  background-color: rgba(232, 52, 78, 0.2);
}

.paying:hover {
  border: none;
  background-color: rgba(232, 52, 78, 0.2);
}

select {
  width: 100%;
  height: 44px;
  border: 1px solid #e2e3e4;
  padding: 0 16px;
  font-size: 14px;
  color: black;
  outline: 0;
  border-radius: 0;
  background-color: white;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

select:focus {
  border: 1px solid $primary-color;
}

.blur {
  color: #828282 !important;
  font-weight: 500 !important;
}

span.isCrmOn {
  position: relative;;
  font-size: 14px;
  padding: 7px;
  color: white !important;
  border-radius: 3px;
  background-color: #e8344e;
  @include mobile {

  }
}

span.isCrmOn:after {
  content: "";
  position: absolute;
  left: -18%;
  margin-left: 5px;
  top: 50%;
  transform: translateY(-50%);
  border: 10px solid #e8344e;
  border-color: transparent #e8344e transparent transparent;
  display: block;
}

span.isCrmOn-title {
  position: relative;
  font-size: 17px;
  //padding: 7px;
  color: #e8344e !important;
  border-radius: 3px;
  //background-color: #212529;
  margin-left: 10px;
  font-weight: 500;
  @include mobile {
    //font-size: 12px;
  }
}

.pointTitle {
  @include mobile {
    font-size: 18px !important;
  }
}

.whole-hr {
  width: calc(100% + 44px);
  height: 1px;
  background-color: #ced4da;
  margin: 21px auto 21px -22px;
}


.modal-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;

  .modal-container {
    opacity: 1;
    background-color: white;
    position: relative;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 22px;
    width: 325px;
    margin: auto;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);

    @include desktop {
      width: 517px;
      padding: 32px;
    }

    .modal-sec {
      width: 100%;
      position: relative;
    }

    img.modal-close {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      width: 13px;
      height: 13px;
      z-index: 99;
      @include desktop {
        width: 19.4px;
        height: 19.4px;
      }
    }

    .title-group {
      display: flex;
      align-items: center;

      img.modal-python-ic {
        width: 34px;
        height: 34px;
        object-fit: contain;
        border-radius: 9.7px;
        background-image: linear-gradient(to bottom, #2897fb, #2897fb), linear-gradient(to top, #ff0044, #ffb199);

        @include desktop {
          width: 42px;
          height: 42px;
        }
      }

      h2 {
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: -1.2px;
        text-align: center;
        color: #212529;
        margin-left: 15px;
        @include desktop {
          font-size: 26px;
        }
      }
    }

    .desc {
      margin-top: 21px;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.84px;
      text-align: left;
      color: #212529;
      @include desktop {
        font-size: 16px;
      }

      span.underline {
        text-decoration: underline;
      }
    }

    .desc-group {
      .desc-sub {
        h3 {
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: -0.98px;
          text-align: left;
          color: #212529;
          @include desktop {
            font-size: 16px;
          }
        }

        h2 {
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          text-align: left;
          color: #212529;
          font-size: 16px;
          line-height: 1.6;
          letter-spacing: -1.12px;
          @include desktop {
            font-size: 18px;
          }
        }
      }

      .desc-pointout {
        margin-top: 18px;
        width: 80%;

        li {
          margin-top: 5px;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: -0.84px;
          text-align: left;
          color: #212529;

          display: flex;
          align-items: flex-start;

          &:first-child {
            margin-bottom: 12px;
          }

          @include desktop {
            font-size: 16px;
          }

          img.checkpoint {
            width: 15px;
            height: 15px;
            margin-right: 15px;
            margin-top: 6px;
            @include desktop {
              width: 18px;
              height: 18px;
            }
          }
        }
      }

      .desc-outro {
        margin-top: 47px;
      }
    }
  }
}

.python-course {
  display: flex;
  align-items: center;
  position: relative;

  .course-detail {
    margin-left: 15px;
  }

  .course-date {
    margin-bottom: 1px !important;
  }

  .more {
    position: absolute;
    bottom: 10px;
    right: 0px;
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.42px;
    text-align: left;
    color: #e8344e;
  }
}

.python-price {
  td:first-child {
    display: flex;
    align-items: center;

    img {
      margin-top: 1px;
      width: 17px;
      height: 17px;
    }

    span {
      margin-left: 8px;
      font-size: 17px !important;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -1.19px;
      text-align: left;
      color: #212529 !important;
    }
  }

  td.price {
    span:first-child {
      font-size: 17px !important;;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -1.19px;
      text-align: left;
      color: #828282 !important;
      text-decoration: line-through;
    }

    span:last-child {
      font-size: 17px !important;;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -1.19px;
      text-align: left;
      color: #343a40 !important;
    }
  }
}

.coupon-select {
  position: relative;
  width: 100%;

  .select-placeholder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 18px 12px 25px;
    border-radius: 5px;
    border: solid 1px #dee2e6;
    background-color: #ffffff;
    cursor: pointer;

    .select-text {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.84px;
      text-align: left;
      color: #212529;

      @include desktop {
        font-size: 16px;
        letter-spacing: -0.96px;
      }
    }
  }

  .arrow-box {
    position: absolute;
    width: 41px;
    height: 40px;
    background-color: #e9ecef;
    top: 1px;
    right: 1px;
    text-align: center;
    border-radius: 5px;

    img.ic-arrow-down {
      width: 10.4px;
      height: 6.5px;
      vertical-align: bottom;
    }
  }


  .retro-courses {
    position: absolute;
    width: 100%;
    padding: 6px 0;
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    bottom: -42px;
    cursor: pointer;
    @include desktop {
      bottom: -61px;
    }

    .retro-course {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.84px;
      text-align: left;
      color: #212529;
      padding: 6px 26px;
      @include desktop {
        font-size: 16px;
        padding: 14px 26px;
      }

      span {
        color: #e8344e;
        font-size: 16px;
      }

      &:hover {
        background-color: #f1f3f5;
      }
    }
  }
}


.review-period {
  padding: 2.5px 3.5px;
  border-radius: 5px;
  background-color: #ffe3e3;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.37;
  letter-spacing: -0.84px;
  text-align: center;
  color: #e8344e;
  display: inline-block;
  margin-left: 10px;

  @include desktop {
    font-size: 12px;
  }
}