@import '../../../_styles/utils';

.page {
  width: 100%;
  margin: auto;
  max-width: $maxWidth;
  //overflow-x: hidden;

  &__intro {
    &__header {
      background-image: var(--bg-image);
      height: 660px;
      background-size: cover;
      background-repeat: no-repeat;

      display: flex;
      flex-direction: column;
      align-items: center;
      //justify-content: center;

      &__top {
        margin-top: 56px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &__img {
          width: 34px;
          height: 34px;
          object-fit: contain;
          margin-bottom: 23px;
        }

        &__main {
          font-family: $spoqa-hans-neo;
          font-size: 32px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.19;
          letter-spacing: -0.96px;
          text-align: center;
          color: $white;
          margin-bottom: 8px;
        }

        &__sub {
          font-family: $spoqa-hans-neo;
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: -0.6px;
          text-align: center;
          color: $white;
        }
      }

      &__body {
        margin-top: 3.7px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &__img {
          width: 161.5px;
          height: 160px;
          object-fit: contain;
          margin-bottom: 11.7px;
        }

        &__sub {
          font-family: $pixelSix00;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: -0.42px;
          text-align: center;
          color: $black;
          margin-bottom: 10px;
        }

        &__clock {
          font-family: $spoqa-hans-neo;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: -0.3px;
          text-align: left;
          color: $white;
          padding: 10px 24px;
          border-radius: 100px;
          background-color: $black;
        }
      }

      &__bottom {
        margin-top: 118px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &__span {
          font-family: $spoqa-hans-neo;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.17;
          text-align: center;
          color: $black;
          font-size: 24px;
          letter-spacing: -0.72px;

          &--big {
            font-family: $spoqa-hans-neo;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.17;
            text-align: center;
            color: $black;
            font-size: 28px;
            letter-spacing: -0.84px;
          }
        }

        &__img {
          margin-top: 16px;
          width: 22px;
          height: 12px;
        }
      }
    }
  }

  &__desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto 16.8px;

    &__img {
      width: 685.8px;
      height: 446.7px;
      object-fit: contain;
    }

    &__emoji {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }
  }


  &__banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 0;
    background-color: $black;
    width: 200%;
    margin-left: -50%;


    &__title {
      font-family: $gmarketSans;
      font-size: 26px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: -0.78px;
      text-align: center;
      color: $white;
      margin-bottom: 8px;

      display: flex;
      align-items: center;


      &__img {
        width: 34px;
        height: 34px;
        object-fit: contain;
        margin-bottom: 3px;
        margin-left: 8.7px;
      }
    }

    &__sub {
      font-family: $spoqa-hans-neo;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: -0.6px;
      text-align: center;
      color: #d4d4d4;
    }
  }

  &__outro {
    padding-bottom: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &__img {
        width: 34px;
        height: 34px;
        object-fit: contain;
      }

      &__main {
        margin-top: 56px;
        font-family: $spoqa-hans-neo;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: -0.72px;
        text-align: center;
        color: $black;
      }

      &__sub {
        margin-top: 16px;
        font-family: $spoqa-hans-neo;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: -0.6px;
        text-align: center;
        color: $black;
      }
    }

    &__options {
      margin-top: 56px;
      display: flex;
      align-items: center;
      justify-content: center;

      &__span {
        display: inline-block;
        margin: 0 9px;
        font-family: $spoqa-hans-neo;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.39;
        letter-spacing: -1.08px;
        color: $black;
      }
    }

    &__cta {
      margin-top: 57px;
      padding: 20px 31px;
      border-radius: 100px;
      border: solid 2px $black;
      background-color: $black;
      font-family: $spoqa-hans-neo;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: $white;
      cursor: pointer;
    }
  }
}

.reco-header {
  &__title {
    font-family: $spoqa-hans-neo;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: -0.72px;
    text-align: center;
    color: $black;
  }

  &__sub {
    margin-top: 16px;
    font-family: $spoqa-hans-neo;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.6px;
    text-align: center;
    color: $black;
  }
}

.divider {
  width: 80px;
  height: 8px;
  background-color: #f1f1f1;
  margin: 65px auto;
}

.modal-content {
  &__img {
    width: 22px;
    height: 22px;
    object-fit: contain;
    margin-left: 4.8px;
    margin-bottom: 3px;
  }
}
