@import "../../../../_styles/utils";

.section_title {
    background-color: #f1f1f1;
    font-family: $spoqa-hans-neo;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    height: 42px;
}

.card_wrapper {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    @include newTablet {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 15px;
        justify-content: space-between;
        padding-top: 10px;
    }

    .course_revision {
        display: flex;
        font-family: $spoqa-hans-neo;
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 30px;
        margin-top: -20px;
        color: #e8344e;
        justify-content: center;
        text-decoration: underline;
        cursor: pointer;
    }
}

.voucher_only_text_wrapper {
    margin-bottom: 80px;
    .voucher_only_text {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    img {
        margin-left: 5px;
        width: 24px;
        height: 24px;
        object-fit: contain;
    }
    .package_guide_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}
