@import '../../../../_styles/utils';

.B2bNPSRadioWrapper {
  max-width: 580px;
  height: 200px;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  widht: 100%;

}

.B2bNPSRadioNumber {
  width: 40px;
  height: 25px;
  background-color: #FFE3E3;
  margin-left: auto;
  margin-right: auto;
  color: #E8344E;
  vertical-align: center;
  border-radius: 10px;
  margin-bottom: 15px;
}

.B2bNPSRadioQuestion {
  font-family: Pretendard;
  font-size: 20px;
  font-weight: normal;
  padding-bottom: 16px;
  white-space: pre-line;
}

.B2bNPSRadioButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include desktop {
    justify-content: flex-start;
    margin: 0 auto 12px;
  }
}

.B2bNPSRadioButtonWrapper {
  display: flex;
  flex-direction: row;
  width: 80px;
  position: relative;
  left: 7px;
  @include desktop {
    widht: 100px;
    left: 15px;

  }
}

.B2bNPSRadioPlaceholder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  font-family: Pretendard;
  font-weight: normal;
  color: #ADB5BD;
}

.B2bNPSRadioButton {
  width: 55px;
  height: 30px;
  border: 2px solid #CED4DA;
  border-radius: 50%;
  cursor: pointer;
  @include desktop {
    width: 60px;
    height: 35px;
  }
}

.B2bNPSRadioLine {
  height: 2px;
  width: 100%;
  content: " ";
  background: #CED4DA;
  @include desktop {

  }

}
