@import "../../../_styles/utils";

.finish_page__container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $white;
  background: radial-gradient(
                  72.14% 60.81% at 50% 49.92%,
                  #260e01 0%,
                  #141617 92.66%
  );

  &.--online {
    background-color: #ebeaff;
  }

  &.--chang {
    background-color: #fffcf8;
  }

  &.--ddingdong {
    background-color: #fef9f2;
  }

  &.--hanghae {
    background-color: #1a1a1a;
  }

  .finish_page__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: auto;
    text-align: center;
    width: 100%;
    padding: 60px 0px;

    background-image: var(--bg-image);
    background-position: center;
    background-size: contain;
    background-repeat: repeat;
    background-color: transparent;

    .finish_page__header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: $maxWidth;
      width: 100%;

      img {
        width: 100%;
        max-width: 360px;
      }

      div {
        display: flex;
        flex-direction: column;

        span {
          font-size: 16px;
          font-weight: 500;
          color: #d7e0e6;
          font-family: "Pretendard";
        }

        h1 {
          font-size: 24px;
          margin-top: 10px;
          font-family: "Pretendard";
          font-weight: 700;
          line-height: 140%;

          color: $white;

          &.--hanghae {
            color: $white;
          }
        }

        p {
          font-family: "Pretendard";
          font-size: 18px;
          line-height: 1.5;
          color: #505254;
          font-weight: 500;
          margin-bottom: 0;

          &.--hanghae {
            color: #81898f;

            b {
              color: #c7d2d8;
            }
          }

          b {
            font-weight: bold;
          }

          &.notice {
            margin-top: 2px;
            font-size: 11px;
            @include desktop {
              font-size: 18px;
            }
          }
        }

        @include desktop {
          align-items: flex-start;
          .finish_page__header {
            flex-direction: row;
          }
          span {
            font-size: 20px;
          }
          h1 {
            font-size: 40px;
            text-align: start;
          }
        }
      }
    }

    .finish_page__body {
      max-width: $maxWidth;
      background-image: var(--bg-image-mobile);
      background-position: center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 92%;
      margin: 0 auto 40px auto;

      &.--isTrial {
        h2 {
          margin-top: 88px;
        }

        p {
          margin-bottom: 77px;
        }
      }

      @include desktop {
        width: 100%;
        background-image: var(--bg-image);
      }

      h2 {
        font-size: 24px;
        margin-bottom: 24px;
        color: $white;
        margin-top: 28px;
        font-family: "Pretendard";

        br {
          display: block;
        }

        @include desktop {
          font-size: 28px;
          br {
            display: none;
          }
        }

        strong {
          background-color: rgba(0, 0, 0, 0.7);
          padding: 8px 14px;
          border-radius: 8px;
          color: $primary-color;
          display: inline-block;
          font-family: "Pretendard";
          @include desktop {
            padding: 14px 20px;
            margin: 0 14px;
          }
        }
      }

      p {
        font-family: "Pretendard";
        font-size: 16px;
        line-height: 1.5;
        font-weight: 500;
        color: $white;

        &:last-child {
          margin-bottom: 44px;
        }

        span {
          font-family: "Pretendard";
          font-size: 13px;
          line-height: 1.5;
          font-weight: 500;
          color: $white;

          br {
            display: block;
          }

          @include desktop {
            font-size: 16px;
            br {
              display: none;
            }
          }
        }
      }
    }

    .finish_page_medal__container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: $white;
      margin-bottom: 40px;
      width: 100%;

      .finish_page_medal__box {
        display: flex;
        flex-direction: column;

        div:first-child {
          margin-bottom: 14px;
        }

        .finish_page_medal__box-innerbox {
          display: flex;
          justify-content: space-between;
          padding: 14px 18px;
          width: 320px;

          background-color: rgba(255, 255, 255, 0.04);
          border: 1px solid rgba(255, 255, 255, 0.1);
          box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.04);
          border-radius: 8px;

          span {
            color: #d7e0e6;
            font-size: 14px;
            line-height: 21px;
            font-family: "Pretendard";
          }

          .finish_page_medal__box-title {
            color: #D7E0E6;
            margin-bottom: 0px;
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
            font-family: "Pretendard";
          }

          img {
            position: relative;
            left: 9px;
          }

          div {
            display: flex;
            align-items: flex-end;
            border: none;
            box-shadow: none;
            background-color: rgba(255, 255, 255, 0);
            padding: 7px 0px;
            margin-bottom: 0px;
            gap: 8px;
            position: relative;
            top: 6px;

            p {
              margin-bottom: 0px;
              font-size: 18px;
              font-weight: 700;
            }

            span {
              color: $white;
              font-size: 40px;
              font-weight: 700;
            }

            @include desktop {
              p {
                font-size: 28px;
              }
              span {
                font-size: 50px;
              }
            }
          }
        }
      }

      @include desktop {

        .finish_page_medal__box-innerbox {
          height: 118px;

        }
        .finish_page_medal__box {
          flex-direction: row;
          gap: 41px;
        }
      }
    }

    .finish_page__medal-sns {
      display: flex;
      gap: 20px;
      margin-bottom: 30px;

      button {
        display: block !important;
      }
    }

    .finish_page__medal-cta {
      display: flex;
      align-items: flex-start;

      font-family: 'Pretendard';
      font-weight: 500;
      font-size: 14px;
      color: #B4BFC6;
      cursor: pointer;

    }

    .finish_page__100million_banner {
      height: auto;
      margin: 70px auto 0 auto;
      background: $primary-color;
      padding: 32px 24px;
      border-radius: 12px;
      border: 1px solid #FF9EA9;
      display: flex;
      align-items: center;
      flex-direction: column;
      @include desktop {
        width: 1000px;
        height: 188px;
        flex-direction: row;
        justify-content: space-between;
        padding: 60px 50px;
        margin: 40px auto 0 auto;
      }

      &__title {
        font-weight: 700;
        font-size: 22px;
        line-height: 1.4;
        font-family: 'Pretendard';
        margin-bottom: 0;
        color: $white;
        text-align: left;
        white-space: nowrap;
        display: none;
        @include desktop {
          display: block;
        }
      }

      &__img {
        width: 278px;
        margin-bottom: 40px;
        @include desktop {
          margin-bottom: 0;
        }
      }

      &__button {
        width: 214px;
        height: 56px;
        background: $white;
        border: 1px solid $white;
        border-radius: 8px;
        font-size: 16px;
        font-family: 'Pretendard';
        line-height: 57px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        color: $primary-color;
        cursor: pointer;
      }

      &__button_wrapper {
        position: relative;
      }

      &__tooltip {
        position: absolute;
        font-weight: 600;
        font-size: 13px;
        font-family: 'Pretendard';
        line-height: 1.5;
        color: $white;
        background-image: var(--bg-image);
        background-size: 100% 100%;
        white-space: nowrap;
        background-repeat: no-repeat;
        top: -10%;
        left: 50%;
        padding: 6px 10px 10px 10px;
        transform: translate(-50%, -50%);
      }
    }

    .finish_page__arrow {
      position: absolute;
      top: 85%
    }

    .finish_page__nocodeBanner {
      margin-top: 40px;
    }

    .finish_page__nocodeImage {
      width: 328px;
      height: 152px;
      border-radius: 8px;
      @include desktop {
        width: 872px;
        height: 185px;
        cursor: pointer;
      }
    }

    .finish_page__scrollDown {
      position: fixed;
      bottom: 30px;
      width: 40px;
      height: 54px;
      @include desktop {
        margin-top: 32px;
        bottom: 0;
        cursor: pointer;
        position: relative;
      }
    }

    .finish_page__footer {
      button {
        color: $white;
        background: $primary-color;
        border-radius: 100px;
        width: 320px;
        height: 48px;
        font-family: "Pretendard";
        font-size: 18px;
        font-weight: bold;
        line-height: 1.22;
        letter-spacing: -0.54px;
        text-align: center;
        outline: none;
        border: none;
        cursor: pointer;
        @include desktop {
          border-radius: 40px;
          height: 70px;
        }
      }
    }

    @include desktop {
      padding: 100px 0px;
      .finish_page__header {
        flex-direction: row;
      }
      .finish_page__arrow {
        position: initial;
      }
    }

  }
}

.imageDown__container {
  height: 0px;
  overflow: hidden;

  .finish_page__container {
    width: 360px;
    height: 689px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $white;

    background: radial-gradient(
                    72.14% 60.81% at 50% 49.92%,
                    #260e01 0%,
                    #141617 92.66%
    );

    .finish_page__wrapper {
      margin-top: 30px;
      width: 100%;

      background-image: var(--bg-image);
      background-position: center;
      background-size: contain;
      background-repeat: repeat;
      background-color: transparent;

      .finish_page__header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: $maxWidth;
        width: 100%;

        img {
          width: 100%;
          max-width: 360px;
        }

        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-family: "Pretendard";

          span {
            font-size: 16px;
            font-weight: 500;
            color: #d7e0e6;
            font-family: "Pretendard";
          }

          h1 {
            font-size: 24px;
            margin-top: 10px;
            font-family: "Pretendard";
            font-weight: 700;
            line-height: 140%;
            color: $white;
            text-align: center;
          }

          @include desktop {
            align-items: flex-start;
            .finish_page__header {
              flex-direction: row;
            }
            span {
              font-size: 20px;
            }
            h1 {
              font-size: 40px;
              text-align: start;
            }
          }
        }
      }

      .finish_page_medal__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: $white;
        margin-bottom: 40px;
        width: 100%;

        .finish_page_medal__box {
          display: flex;
          flex-direction: column;

          div:first-child {
            margin-bottom: 14px;
          }

          .finish_page_medal__box-innerbox {
            display: flex;
            justify-content: space-between;
            padding: 14px 18px;
            width: 312px;

            background-color: rgba(255, 255, 255, 0.04);
            border: 1px solid rgba(255, 255, 255, 0.1);
            box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.04);
            border-radius: 8px;

            span {
              color: #d7e0e6;
              font-size: 14px;
              line-height: 21px;
              font-family: "Pretendard";
            }

            .finish_page_medal__box-title {
              color: #d7e0e6;
              margin-bottom: 0px;
              font-size: 14px;
              line-height: 21px;
              font-weight: 500;
              font-family: "Pretendard";
            }

            img {
              position: relative;
              left: 9px;
            }

            div {
              display: flex;
              align-items: flex-end;
              border: none;
              box-shadow: none;
              background-color: rgba(255, 255, 255, 0);
              padding: 7px 0px;
              margin-bottom: 0px;
              gap: 8px;
              position: relative;
              top: 6px;

              p {
                margin-bottom: 0px;
                font-size: 18px;
                font-weight: 700;
              }

              span {
                color: $white;
                font-size: 40px;
                font-weight: 700;
              }

              @include desktop {
                p {
                  font-size: 28px;
                }
                span {
                  font-size: 50px;
                }
              }
            }
          }
        }

        @include desktop {
          .finish_page_medal__box-innerbox {
            height: 118px;
          }
          .finish_page_medal__box {
            flex-direction: row;
            gap: 41px;
          }
        }
      }

      .finish_page__footer {
        button {
          color: $white;
          background: $primary-color;
          border-radius: 100px;
          width: 320px;
          height: 48px;
          font-family: "Pretendard";
          font-size: 18px;
          font-weight: bold;
          line-height: 1.22;
          letter-spacing: -0.54px;
          text-align: center;
          outline: none;
          border: none;
          cursor: pointer;
          @include desktop {
            border-radius: 40px;
            height: 70px;
          }
        }
      }

      @include desktop {
        height: 545px;
        margin-top: 60px;
        .finish_page__header {
          flex-direction: row;
        }
      }
    }

    @include desktop {
      height: 545px;
      width: 865px;

      .finish_page__header {
        flex-direction: row;
      }
    }
  }
}
