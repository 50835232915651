@import "../../../_styles/utils";

.nps-page {
  max-width: 100%;
  margin: auto;
  background: white;
  height: 100%;
  overflow: auto;
  padding-bottom: 200px;

  &__nav {
    max-width: calc((92% - 80px));
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 77px;
    margin: 0 auto;
    width: 100%;
    @include desktop {
      position: relative;
      justify-content: left;
    }
    @include lectureNav {
      justify-content: space-around;
      max-width: 100%;
    }
  }

  &__content {
    padding-top: 20px;
    max-width: 850px;
    width: 92%;
    margin: auto;
    @include desktop {
      width: 100%;
      padding-top: 57px;
    }

    &__header {
      max-width: 94%;
      margin: auto;
      @include desktop {
        max-width: unset;
        margin: unset;
        &__title {
          br {
            display: none;
          }
        }
      }

      &__title {
        font-family: GmarketSans;
        font-size: 28px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: -1.68px;
        text-align: left;
        color: $black;
        word-break: keep-all;
      }

      &__subtitle {
        margin-top: 17px;
        font-family: $spoqa-hans-neo;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: -0.48px;
        text-align: left;
        color: $black;
        word-break: keep-all;
      }
    }

    &__hr {
      width: 100%;
      height: 2px;
      border-bottom: dashed 2px $black;
      margin: 36px 0;
      display: none;
      @include desktop {
        display: block;
      }
    }

    &__body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 32px;
      margin-top: 35px;

      @include desktop {
        display: grid;
        gap: unset;
        grid-template-columns: 78px 1fr;
        grid-row-gap: 24px;
        margin: 0;
      }

      &__num {
        width: 30px;
        @include desktop {
          width: 39px;
        }
      }


      &__question {
        font-family: $spoqa-hans-neo;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.8px;
        text-align: center;
        color: $black;
        max-width: 92%;
        width: 100%;
        word-break: keep-all;
        @include desktop {
          text-align: left;
        }

        &.--isRadioGroup {
          text-align: left;
          .nps-page__content__body__question__title {
            text-align: center;
          }
          .ant-radio-group {
            width: 300px;
            margin: auto;
          }
          @include desktop {
            text-align: left;
            .nps-page__content__body__question__title {
              text-align: left;
            }
          }
        }

        &__title {
          margin-top: -16px;
          margin-bottom: 16px;
          @include desktop {
            margin-top: 13px;
          }
        }

        &__textarea {
          width: 100%;
          border-radius: 8px;
          border: none;
          padding: 16px 24px;
          resize: none;
          height: 99px;
          -webkit-appearance: none;
          outline: none;
        }

        &__rcmd {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 50px;

          &__span {
            border-bottom: 1px dotted grey;
            margin-left: 5px;

            @include adesktop {
              margin-top: 10px;
            }

            &:hover {
              cursor: pointer;
              border-bottom: 1px solid grey;
            }
          }
        }
      }
    }
  }
}
.nps-page__content__body__question__rcmd__wrapper {
  background: $white;
  padding: 14px 20px;
  border-radius: 8px;
}

.course_rcmd__question {
  margin-bottom: 8px;
  width: 100%;
  border-radius: 8px;
  border: solid 1px #eaebed;
  padding: 16px 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  &__icon {
    margin-right: 10px;

    path {
      fill: #eaebed;
    }
  }

  &__desc {
    font-size: 15px;
    font-weight: 500;
    color: #505254;
  }

  &.--selected,
  &:hover {
    background: $primary-color;

    .course_rcmd__question__icon {
      path {
        fill: $white;
      }
    }

    .course_rcmd__desc {
      color: $white;
    }

  }
}