@import '../../../../_styles/utils';

.CodingRaceBot {
  display: grid;
  margin-top: -3%;
  max-width: 100vw;

  .CodingRaceFieldContainer {
    grid-column: 1;
    grid-row: 1;
    height: 100%;
    width: 100%;

    img {
      width: 100%;
      height: 110%;
    }
  }

  .CodingRaceRank {
    grid-column: 1;
    grid-row: 1;

    .CodingRaceWrapper {
      padding-top: 30px;
      display: grid;
      grid-template-columns: 0.1fr 1fr 0.2fr;
      height: 95%;

      .CodingRace {
        margin-top: 70px;
        display: grid;

      }

      .CodingRaceCastleContainer {
        img {
          height: 70%;
          object-fit: contain;
          right: -17px;
          position: absolute;

        }
      }
    }
  }
}