@import "../../../../_styles/utils";

.class_card__wrapper {
  width: 280px;

  display: flex;
  flex-direction: column;

  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.04);
  border-radius: 8px;

  &.--pointer {
    cursor: pointer;
  }

  .class_card__img__wrapper {
    position: relative;
    img {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      width: 280px;
      height: 160px;
    }
  }
  .class_card__content__container {
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 0px 0px 8px 8px;
    border: 1px solid #eef3f6;
    width: 100%;
    min-height: 190px;
    background-color: $white;
  }
  .class_card__tag__container {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
  .class_card__tag-best {
    padding: 4px 8px;
    background-color: #fff1f4;
    border-radius: 4px;
    font-family: Pretendard;
    font-weight: 500;
    font-size: 12px;
    color: #e8344e;
  }
  .class_card__tag {
    padding: 4px 8px;
    background-color: #f2f6f8;
    border-radius: 4px;
    font-family: Pretendard;
    font-weight: 500;
    font-size: 12px;
    color: #81898f;
  }
  .class_card-title {
    font-family: Pretendard;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    margin-top: 16px;
    white-space: pre-wrap;
    color: #141617;
    text-align: left;
    word-break: keep-all;
  }
  .class_card-body {
    font-family: Pretendard;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    margin-top: 8px;

    color: #81898f;
    white-space: pre-wrap;
    text-align: left;
    
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

  }
  .class_card__cta__container {
    img {
      width: 169px;
      position: relative;
      top: 16px;
    }
    .class_card__cta {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 56px;

      font-family: "Pretendard";
      color: #ffffff;
      font-size: 16px;
      font-weight: 600;

      background: #e8344e;
      border-radius: 8px;
      border: none;
      cursor: pointer;
      &.--share {
        margin-top: 24px;
      }
    }
  }
}
