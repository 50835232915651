@import "../../../../_styles/utils";

.lec-nav-list {
  width: 520px;
  height: 440px;
  padding: 16px 7px;
  border-radius: 24px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.15);
  background-color: $white;
  overflow-y: scroll;
  border-width: 11px 8px 11px 11px;
  border-style: solid;
  border-color: $white;
  position: absolute;
  top: 8px;
  z-index: 999;
  @include lectureNav {
    width: 416px;
  }
  @include mobile {
    width: 100%;
  }

  &.pirates {
    background-color: #2B2D2E;
    border-color: #2B2D2E;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    height: 84px;
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.15);
  }
}
