@import "../../../../_styles/utils";

.bg {
  min-width: 100%;
  min-height: 100%;
  background-color: #f1f3f5;

}

.wrapper {
  width: 320px;
  margin: auto;
  padding-top: 19px;
  padding-bottom: 60px;

  @include desktop {
    width: 826px;
  }
}

.header {
  .title {
    font-family: "Pretendard";
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    color: #838689;

    @include desktop {
      display: none;
    }
  }

  .msg {
    margin-top: 10px;
    font-family: "GmarketSans";
    font-size: 22px;
    font-weight: bold;
    line-height: 1;
    color: #3b3b3b;

    @include desktop {
      font-size: 28px;
    }
  }
}

.modal-point {
  margin-top : 30px;
  .modal-point__content{
    font-family: "Pretendard";
  }
}

.modal-no-card-received {
    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 2px;
    background: #EEF3F6;
    border-radius: 8px;
    margin-top : 10px;
    letter-spacing: 0.05px;
  .box-inner {
    display: flex;
    align-items: flex-start;
    padding: 1px;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
  }
  .gray-text {
    display: block;
    text-align: left;
    padding: 0px;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: #5F666B;

    span{
      font-weight: 600;
      font-family: "Pretendard";
    }
  }
}


.status-window {
  margin-top: 30px;
  width: 100%;
  height: 119px;
  border-radius: 8px;
  background-color: #fff;
  padding: 20px;

  @include desktop {
    height: 174px;
    padding: 30px 74px;
  }
  .rooms {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .status-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;

    @include desktop {
      margin-top: 10px;
    }
    .line {
      z-index: 1;
      width: 88%;
      height: 2px;
      background-color: #f1f3f5;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 6.5px;

      @include desktop {
        width: 90%;
        top: 9.5px;
      }

      .done {
        position: relative;
        height: 2px;
        background-color: #ed5d71;
        top: 0;
        left: 0;

        @include desktop {
          height: 4px;
        }
      }

      @include desktop {
        height: 4px;
      }
    }


    .status {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      width: 40px;

        @include desktop {
          width: 48.13px;
        }

      .label {
        z-index: 2;
        background-color: #f1f3f5;
        border: #f1f3f5 1px solid;
        text-align: center;
        border-radius: 20px;
        width: 16px;
        height: 16px;
        font-size: 8px;
        color: #b6b6b6;
        line-height: 1.3;

        @include desktop {
          width: 26px;
          height: 26px;
          font-size: 14px;
          font-weight: bold;
          line-height: 1.5;
          border: #f1f3f5 2px solid;
        }
      }

      .value {
        margin-top: 4px;

        text-align: center;
        white-space: nowrap;

        font-family: "SpoqaHanSansNeo";
        font-size: 11px;
        font-weight: 500;
        color: #b6b6b6;

        @include desktop {
          font-size: 14px;
          margin-top: 10px;
        }
      }

      &.done {
        .label {
          background-color: #ed5d71;
          border: #ed5d71 1px solid;
          color: #ffffff;

          @include desktop {
            border: #ed5d71 2px solid;
          }
        }

        .value {
          color: #000;
        }
      }

      &.active {
        .label {
          background-color: #ffffff;
          border: #ed5d71 1px solid;
          color: #ed5d71;

          @include desktop {
            border: #ed5d71 2px solid;
          }
        }

        .value {
          color: #ed5d71;
          font-weight: bold;
        }
      }
    }

  }

  .rooms {
    .room {
      width: 40.95px;

      @include desktop {
        width: 50px;
      }
      .rtan {
        text-align: center;

        img {
          width: 34px;
          transform: scaleX(-1);
          @include desktop {
            width: 50px;
          }
        }
      }

      .open {
        font-family: 'SpoqaHanSansNeo';
        font-size: 10px;
        letter-spacing: -1px;
        text-align: center;
        color: #3b3b3b;


        @include desktop {
          font-size: 10.7px;
        }

        img {
          width: 10px;
          height: 10px;
          margin: 1px 0 5px 0;
        }

        .date {
          text-align: center;
        }

        .postfix {
          text-align: center;
          white-space: nowrap;
        }
      }
    }
  }
}

.lecture-container {
  margin-top: 30px;
  position: relative;

  .vertical-line {
    position: absolute;
    top: 12px;
    left: 3.5px;
    width: 2px;
    background-color: #eaebed;
    z-index: 0;
  }
}

.lecture-wrapper {
  position: relative;
  z-index: 2;
  margin-top: 20px;

  &.ready {
    .lecture-card {
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
      background-color: #fff;
      color: #000;

      img {
        opacity: 1 !important;
      }

      .right {
        color: #b6b6b6;
        img {
          opacity: 0.1 !important;
        }
      }
    }
  }


  &.active {
    .title {
      .point {
        background-color: #ed5d71;
      }

      .text {
        color: #ed5d71;
        font-weight: 600;
      }
    }

    .lecture-card {
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
      background-color: #fff;
      color: #000;

      img {
        opacity: 1 !important;
      }

      .right {
        color: #b6b6b6;
        img {
          opacity: 0.1 !important;
        }
      }
    }
  }

  &.done {
    .title {
      .point {
        background-color: #000;
      }

      .text {
        color: #000;
      }
    }

    &:last-child {
      .point {
        background-color: #ed5d71;
      }

      .text {
        color: #ed5d71;
        font-weight: 600;
      }
    }

    .lecture-card {
      background-color: #fff;
      color: #000 !important;
      .right {
        color: #000 !important;
      }
      img {
        opacity: 1 !important;
      }
    }
  }

  .title {
    .point {
      margin: 2px 10px 2px 0;
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 100px;
      background-color: #b6b6b6;


      @include desktop {
        width: 10px;
        height: 10px;
      }
    }

    .text {
      display: inline-block;
      font-family: "Pretendard";
      font-size: 13px;
      font-weight: 500;
      color: #b6b6b6;
      @include desktop {
        font-size: 16px;
      }
    }
  }

  .card-wrapper {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 14px;
    cursor: pointer;
    &.alert {
      .lecture-card {
        justify-content: start;
        background-color: rgba(0, 0, 0, 0.02);
        box-shadow: none;
        font-family: "Pretendard";
        font-size: 13px;
        font-weight: 500;
        line-height: 1.5;
        text-align: left;
        color: #3b3b3b;
        padding: 18px;
        @include desktop {
          font-size: 15px;
        }

        img {
          height: 16px;
          margin-right: 6px;
        }
      }
    }
  }

  .lecture-card:hover{
    background-color: #E8344E;
  }

  .lecture-card {
    width: 100%;
    margin-left: 22px;
    height: 56px;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    background-color: #eaebed;

    align-self: stretch;
    flex-grow: 0;
    flex-direction: row;
    align-items: center;
    padding: 10px 10px 10px 10px;

    color: #b6b6b6;
    font-weight: 500;
    font-size: 14px;
    transition: background 0.5s ease-in-out;
    @include desktop {
      padding: 20px 30px;
      font-size: 16px;
    }
    .left {
      display: flex;
      align-items: center;

      img {
        height: 24px;
        vertical-align: sub;
        margin-right: 4px;
        opacity: 0.1;
      }
    }

    .right {
      display: flex;
      align-items: center;
      color: #b6b6b6;
      img {
        height: 22px;
        vertical-align: sub;
        margin-left: 10px;
        opacity: 0.1;
      }
    }
  }
}