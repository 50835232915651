@import '../../_styles/utils.scss';

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.active {
  display: block !important;
}

.modal-background {
  overflow-y: hidden;
  display: none;
  position: fixed;
  z-index: 2;
  margin: auto;
  background-color: white;
  width: 95%;
  left: 2.5%;
  top: calc(50% - 240px);
  border-radius: 10px;
  //border: 2px solid #13C2C2;
  //padding: 20px;
  height: 690px;

  @include desktop {
    width: 764px;
    height: 695px;
    left: calc(50% - 382px);
    top: calc(50% - 340px);
  }

  @include tablet {
    width: 764px;
    height: 695px;
    left: calc(50% - 382px);
    top: calc(50% - 340px);
  }

  @include mobile {
    width: 764px;
    height: 695px;
    left: calc(50% - 382px);
    top: calc(50% - 340px);
  }

  .modal-content {
    //margin: 90px 0px;
    //height: 300px;
    vertical-align: center;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    .modal-body {
      width: 100%;
      margin: 0 auto;
      .header {
        padding-top: 30px;
        text-align: center;
        h3 {font-size: 24px; font-weight: 500; letter-spacing: -1px;}
        p {
          color: black;
          font-size: 17px;
          letter-spacing: -1px;
          font-weight: 500;
          b {
            font-size: 28px;
            letter-spacing: -1.7px;
            color:#13c2c2;
            margin: 0 5px;
          }
        }
      }
      .summary {
        height: 200px;
        width: 100%;

        span {
          margin-left:5%;
          font-size: 14px;
          letter-spacing: -0.7px;
          color: #343a40;
          font-weight: 500;
          span {
            border: 1px solid #13c2c2;
            color: #13c2c2;
            border-radius: 3px;
            padding: 1px 5px;
            margin-left: 10px;
          }
        }
        .pg-bar {
          margin-top: 130px;
          position: relative;
          .player {
            text-align: center;
            position: absolute;
            top: -94px;
            width: 64px;
            z-index: 3;
            -webkit-animation: fade-in 3s;
            -moz-animation: fade-in 3s;
            animation: fade-in 3s;
            .current_lecture {
              width: 64px;
              height: 76px;
              padding: 15px 16px;
              display: inline-block;
              border-radius: 50%;
              background-size: cover;
              color: #495057;
              font-size: 14px;
              letter-spacing: -1px;
              line-height: 1.3;
              font-weight: 500;
            }
            .node {
              background: white;
              margin-top: 10px;
              font-size: 13px;
              font-weight: 500;
              letter-spacing: -1px;
              width: 27px;
              height: 27px;
              display: inline-block;
              border-radius: 50%;

            }
          }

          .player.top-milestone {
            .node {
              color: #e8344e;
              width: 35px;
              border-radius: 16px;
              border: 2px solid #e8344e;

            }
          }

          .player.my-milestone {
            .node {
              color: #13c2c2;
              border: 2px solid #13c2c2;
            }
          }

          .bg-pg-bar {
            width: 648px;
            background-color: #dee2e6;
            height: 10px;
            margin: 0 auto;
            border-radius: 10px;
            white-space: nowrap;
            .my-pg-bar {
              background: #13c2c2;
              z-index: 1;
              height: 10px;
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
              display: inline-block;
              vertical-align: top;
              position: absolute;
              -webkit-animation: progress-bar 1.5s;
              -moz-animation: progress-bar 1.5s;
              animation: progress-bar 1.5s;
            }
            .milestone {
              display: inline-block;
              width: 2px;
              height: 10px;
              background: white;
              position: relative;
              vertical-align: top;
              z-index: 1;
              span {
                position: absolute;
                top: 18px;
                right: -10px;
              }
            }
            .milestone:first-child,
            .milestone:last-child {
              background: none;
            }
          }
        }
      }
      .body {
        height: 360px;
        width: 100%;
        overflow-y: auto;
        padding-right: 17px;
        box-sizing: content-box;

        .rank_row.my {
          position: relative;
          .my_rank_wrap {position: absolute; width: 100%; height: 100%; background: rgba(19, 194, 194, 0.3)}
          .rank_pg_rate .pg-bar {
            &::after {
              border: 2px solid #13c2c2;
              box-sizing: border-box;
              background: white;
            }
            background: linear-gradient(to right, rgba(19, 194, 194, 0), #13c2c2);
            .pg-bar-rank {
              background: #13c2c2;
              visibility: visible;
            }
          }
        }
        .rank_row.top .rank_pg_rate .pg-bar {
          &::after {border: 2px solid #e8344e; box-sizing: border-box; background: white;}
          background: linear-gradient(to right, rgba(235, 52, 78, 0), #e8344e);
          .pg-bar-rank {background: #E8344E; visibility: visible;}
        }
        .rank_row {
          &:hover .rank_pg_rate .pg-bar .pg-bar-rank {visibility: visible;}
          height: 30px;
          width: 100%;
          white-space: nowrap;
          .rank_name {
            background: #f1f3f5;
            display: inline-block;
            width: 15%;
            height: 100%;
            text-align: center;
            font-size: 12px;
            color: #495057;
            letter-spacing: -1px;
            font-weight: 500;
            padding: 5px 0;
            text-overflow: ellipsis;
          }
          .rank_pg_rate {
            display: inline-block;
            width: 82%;
            margin-left: 3%;
            //margin-left: 15%;

            .pg-bar {
              display: inline-block;
              vertical-align: middle;
              height: 2px;
              position: relative;
              background: linear-gradient(to right, rgba(173, 181, 189, 0), #adb5bd);
              -webkit-animation: progress-bar 2s;
              -moz-animation: progress-bar 2s;
              animation: progress-bar 2s;
              .pg-bar-rank {
                display: inline-block;
                vertical-align: middle;
                position: absolute;
                right: 30px;
                top: -10px;
                background: #868e96;
                color: white;
                font-weight: 500;
                padding: 1px 7px;
                border-radius: 2px;
                visibility: hidden;
                &.low {right: -70px;}
              }
            }
            .pg-bar::after {
              width: 15px;
              height: 15px;
              display: inline-block;
              right: 0;
              top: -6px;
              position: absolute;
              content: '';
              border-radius: 50%;
              background: #CED4DA;
            }
          }
        }
      }
    }
  }
}

@-webkit-keyframes progress-bar {
   0% { width: 0; }
}
@-moz-keyframes progress-bar {
   0% { width: 0; }
}
@keyframes progress-bar {
   0% { width: 0; }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
}

@-moz-keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }

}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }


}
