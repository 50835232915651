$primary-color: #e8344e;
$primary-hover-color: #cf2d46;
$background-color: #ffffff;
$input-background-color: #f5f5f5;
$link-color: #02d47a;
$hover-color: #13c2c2;
$hover-color-deeper: #009696;
$success-color: #52c41a;
$warning-color: #faad14;
$error-color: #ec625d;
$font-size-base: 14px;
$heading-color: rgba(0, 0, 0, 0.85);
$text-color: rgba(0, 0, 0, 0.65);
$text-color-secondary: rgba(0, 0, 0, 0.45);
$disabled-color: #ffffff;
$disabled-background-color: #cecece;
$border-radius-base: 4px;
$border-color-base: #d9d9d9;
$border-focused: #a4a6a9;
$box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);
$breakpoint-mobile: 100px;
//$breakpoint-tablet: 758px;
$breakpoint-tablet: 499px;
//$breakpoint-desktop: 1240px;
$breakpoint-desktop: 499px;
$breakpoint-newTablet: 820px;
$breakpoint-lecture-nav: 1050px;
$breakpoint-lecture-nav-dual: 750px;
$maxWidth: 1230px;
$pre-primary-color: #e8344e;
$main-background: #f8f9fa;
$pre-primary-color-hover: #cf2d46;

$chang-primary-color: #0f16ae;
$ddingdong-primary-color: #ff773c;
$hanghae-primary-color: #f50000;
$post-primary-color: #13c2c2;
$post-primary-color-hover: #009696;
$pale-gray: #f1f3f5;
$tone-down: #868e96;
$black: #000000;
$ddingdong-black: #2f3031;
$white: #ffffff;
$info: #7062e0;
// figma 색상 용어 통일
$aqua-blue: #0085FF;
$gray5: #F2F6F8;
$gray50: #F4F5F6;
$gray80: #5F666B;
$gray100: #EAEBED;
$gray150: #DBDDE0;
$gray200: #C7C9CB;
$gray250: #b4b6b8;
$gray300: #A4A6A9;
$gray400: #838689;
$gray450: #6B6E72;
$gray500: #505254;
$gray600: #1C1D1E;
$sparta-pink: #FBECEF;
//
$spoqa-hans-neo: "Spoqa Han Sans Neo", "sans-serif";
$pixelSix00: "PixelSix00";
$webpixel: "webpixel";
$gmarketSans: "GmarketSans";
$dungGeunMo: "DungGeunMo";

@mixin mobile {
  @media (min-width: #{$breakpoint-mobile}) and (max-width: #{$breakpoint-tablet - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$breakpoint-tablet}) and (max-width: #{$breakpoint-desktop - 1px}) {
    @content;
  }
}

@mixin tablet-desktop {
  @media (min-width: #{$breakpoint-tablet}) {
    @content;
  }
}

@mixin adesktop {
  @media (max-width: #{$breakpoint-desktop - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$breakpoint-desktop}) {
    @content;
  }
}

@mixin newTablet {
  @media (min-width: #{$breakpoint-newTablet}) {
    @content;
  }
}

@mixin lectureNav {
  @media (max-width: #{$breakpoint-lecture-nav}) {
    @content;
  }
}

//a tag 안에 data-text attribute를 a tag의 innertext랑 똑같게 설정!
@mixin stop-hover-moving() {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  &:after {
    content: attr(data-text);
    content: attr(data-text)/"";
    height: 0;
    visibility: hidden;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
    font-weight: bold;
  }
}