@import "../../../../_styles/utils";

.roadmap-header {
  font-family: "GmarketSans";
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: $black;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 8px;
  word-break: keep-all;

  &.pirates {
    color: #EEF3F6;
  }

  @include mobile {
    margin-bottom: 20px;
  }

  &__main {
    font-family: "GmarketSans";
    @include mobile {
      font-size: 22px;
    }
  }
  &__sub {
    font-family: "GmarketSans";
    font-size: 16px;
    font-weight: 500;
    display: none;
    flex-direction: row;
    @include desktop {
      display: flex;
    }
    &__splitter {
      margin: 0 12px;
    }
    &__detail {
      font-family: "GmarketSans";
      b {
        font-family: "GmarketSans";
        margin-left: 6px;
      }
    }
  }
}
