@import "../../../../../../_styles/utils";

.no_course {
  font-family: $spoqa-hans-neo;
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 15px;

  @include desktop {
    //flex-direction: row;
    //justify-content: space-between;
    width: 100%;
    height: 50vh;
    text-align: center;
  }
  .no_course_bot_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include desktop {
      flex-direction: row;

    }
    img {
      margin-left: 5px;
      width: 32px;
      height: 100%;
      margin-top: 10px;
      margin-bottom: 15px;
      @include desktop {
        margin-top: -3px;
        margin-bottom: 0;
      }
    }
  }

  .no_course_btn {
    width: 210px;
    height: 60px;
    border-radius: 100px;
    justify-content: space-between;
    align-items: center;
    border: solid 2px #000000;
    display: flex;
    padding: 0 30px;
    margin-bottom: 20px;
    word-break: keep-all;

    cursor: pointer;

    @include desktop {
      margin: 20px;
    }

    .no_course_btn_content_wrapper {
      height: 100%;
    }
    img {
      object-fit: contain;
      width: 10px;
      height: 100%;
    }
  }
}

.package_guide_wrapper {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include newTablet {
    flex-direction: row;
    align-items: normal;
    justify-content: normal;
  }

  .package_guide_msg {
    @include newTablet {
      margin-right: 10px;
    }
  }
}
