@import '../../../_styles/utils';

.course_rcmd__wrapper {
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 64px;
  margin-bottom: 80px;

  .course_rcmd__header {
    margin-bottom: 30px;

    img {
      width: 338px;
      margin-bottom: 25px;
    }

    h1 {
      font-family: "Pretendard";
      margin-top: 25px;
      margin-bottom: 12px;
      color: #505254;
      font-size: 24px;
      font-weight: bold;
      @include desktop {
        br {
          display: none;
        }
      }
    }

    p {
      font-family: "Pretendard";
      font-size: 18px;
      color: #505254;
      line-height: 1.5;
      font-weight: 500;

      b {
        font-weight: bold;
      }
    }
  }

  .course_rcmd__body {
    margin-bottom: 65px;

    .course_rcmd__question {
      margin-bottom: 8px;
      border-radius: 8px;
      border: solid 1px #eaebed;
      padding: 16px 10px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      width: 100%;
      @include desktop {
        width: 443px;
      }

      &__icon {
        margin-right: 10px;

        path {
          fill: #eaebed;
        }
      }

      &__desc {
        font-size: 15px;
        font-weight: 500;
        color: #505254;
      }

      &.--selected,
      &:hover {
        background: $primary-color;

        .course_rcmd__question__icon {
          path {
            fill: $white;
          }
        }

        .course_rcmd__desc {
          color: $white;
          font-weight :bold;
        }

      }
    }
  }

  .course_rcmd__footer {
    margin-bottom: 45px;
    button {
      border-radius: 40px;
      width: 325px;
      height: 48px;
      font-family: "Pretendard";
      font-size: 18px;
      font-weight: bold;
      line-height: 1.22;
      letter-spacing: -0.54px;
      text-align: center;
      outline: none;
      border: none;
      cursor: unset;
      background: #f1f1f1;
      color: #a4a6a9;
      @include desktop {
        height: 70px;
      }

      &.--active {
        cursor: pointer;
        color: $white;
        background: $primary-color;
      }

    }
  }
}
