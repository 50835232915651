@import '../../../_styles/utils';


.stampContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  cursor: pointer;
  height: 80px;

  &.--isToday:after {
    width: 160px;
    height: 120px;
    position: absolute;
    content: '';
    border-radius: 100px;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    z-index: 1;
  }

  &__img {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    z-index: 2;

    &.--isCodingDay {
      width: 97px;
      height: 97px;
    }
  }

  &__coding_day_container {
    font-family: DungGeunMo;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -1px;
    text-align: center;
    color: #ff8f43;
    position: absolute;
    left: 50%;
    top: -30px;
    transform: translate(-50%, -50%);
    white-space: nowrap;
  }

  &__help_text_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 50%;
    bottom: -100px;
    align-items: center;
    transform: translate(-50%, -50%);

    span {
      margin-top: 8px;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.48px;
      color: #8b8b8b;
      white-space: nowrap;
    }
  }

  &__text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;

    &.--attended {
      font-family: DungGeunMo;
      color: $black;
      font-weight: bold;
    }
  }
}