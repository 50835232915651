@import "../../_styles/utils.scss";
.genderInfo {
  &__container {
    margin-top: 24px;
  }
  &__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__label span {
    margin-left: 4px;
    color: $gray400;
    font-size: 14px;
    line-height: 1.5;
  }
  &__option {
    cursor: pointer;
    border: 1px solid $gray150;
    color: $gray600;
    width: 160px;
    height: 53px;
    padding: 16px 20px;
    text-align: center;
    border-radius: 8px;
    background: $white;
    line-height: 16px;

    @include desktop {
      width: 175px;
    }
    &.--selected-- {
      background: $sparta-pink;
      border-color: $primary-color;
      color: $primary-color;
    }

    &.--selected--swc {
      background: #A8C5FF4D;
      border-color: #3A19FF;
      color: #0D1F7A;
    }
    &.--selected--nbc {
      background: #F0F8E6;
      border-color: #1F8648;
      color: #0D1F7A;
    }
    &.--selected--hanghae {
      background: #FFF5F5;
      border-color: #FA8080;
      color: #F50000;
    }

    &.--selected--chang {
      background: #E5EEFF;
      border-color: #0F16AE;
      color: #0D1F7A;
    }

    &.--selected--ddingdong {
      background: #FFF6EE;
      border-color: #FF773C;
      color: #FF773C;
    }

    &.--selected--intellipick {
      background: #F4F2FF;
      border-color: #AB9BF9;
      color: #5029D3;
    }
  }
}