@import "../../_styles/utils";

.retro-container {
  .retro-bg-gray {
    background-color: #f8f9fa;
    width: 100%;
    @include desktop {
      width: 500%;
      margin-left: -200%;
      padding: 0 200%;
    }

    .retro-head {
      padding: 32px 28px;

      .head-intro {
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.9px;
        text-align: center;
        color: #000000;
        @include desktop {
          font-size: 26px;
        }
      }

      .head-body {
        margin-top: 22px;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -1.2px;
        text-align: center;
        color: #000000;
        @include desktop {
          font-size: 33px;
        }
      }

      .head-outro {
        margin-top: 25px;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: -0.6px;
        text-align: center;
        color: #000000;
        @include desktop {
          font-size: 16px;
        }
      }
    }

    .submit-group {
      width: 90%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      .url {
        width: 264.4px;
        padding: 11px 10px;
        border-radius: 3px;
        border: solid 0.8px #adb5bd;
        background-color: #ffffff;

        @include desktop {
          width: 400px;
        }

        &::placeholder {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: -0.7px;
          color: #868e96;
        }
      }

      .submit {
        cursor: pointer;
        margin-left: 10px;
        padding: 15px 13px;
        object-fit: contain;
        border-radius: 3px;
        background-color: #e8344e;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
      }
    }

    .retro-desc-group {
      margin-top: 18px;
      padding-bottom: 14px;

      .retro-desc {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: -0.6px;
        text-align: center;
        color: #000000;
        margin-bottom: 12px;
        @include desktop {
          font-size: 16px;
        }
      }

      .retro-image {
        display: block;

        width: 210.3px;
        height: 128.7px;
        margin: 12px auto 14px;
        object-fit: contain;

        @include desktop {
          width: 328.5px;
          height: 200.9px;
          object-fit: contain;
        }
      }
    }
  }

  .howto-write {
    margin-bottom: 44px;

    .guide {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.9px;
      text-align: center;
      color: #000000;
      margin: 20px auto 26px;
      @include desktop {
        font-size: 26px;
        letter-spacing: -1.3px;
      }
    }

    .row {
      @include desktop {
        display: flex;
        justify-content: center;
      }

      .step {
        width: 265px;
        margin: auto;
        margin-bottom: 28px;
        display: flex;
        align-items: flex-start;

        @include desktop {
          width: 291px;
          height: 247px;
          margin: 0 21px 33px 0;
          padding: 30px 27px 36px;
          border-radius: 10px;
          box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.08);
          background-color: #ffffff;
          flex-direction: column;
          align-items: center;
        }

        img.retro-step {
          width: 43px;
          height: auto;
          margin-top: 6px;
          @include desktop {
            width: 61.7px;
            margin-bottom: 20px;
          }
        }

        img.retro-step.large {
          width: 48px;
          margin-left: -3px;
          @include desktop {
            width: 63px;
            margin-left: 0;
            margin-bottom: 20px;
          }
        }

        .step-text {
          margin-left: 25px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          @include desktop {
            align-items: center;
            margin-left: 0;
          }

          &.large {
            margin-left: 23px;
            @include desktop {
              margin-left: 0;
            }
          }

          .step-title {
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: -0.8px;
            text-align: left;
            color: #000000;

            @include desktop {
              font-size: 22px;
              letter-spacing: -1.1px;
              text-align: center;
            }
          }

          .step-desc {
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: -0.7px;
            text-align: left;
            color: #212529;

            @include desktop {
              font-size: 17px;
              margin-top: 2px;
              letter-spacing: -0.9px;
              text-align: center;
            }
          }
        }
      }
    }
  }

  .bg-darkblue {
    width: 100%;
    background-color: #455a64;
    @include desktop {
      width: 500%;
      margin-left: -200%;
      padding: 0 200%;
    }

    .hashtag-header {
      padding: 18px 0;

      .hashtag-header-desc {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: -0.6px;
        text-align: center;
        color: #ffffff;
        @include desktop {
          margin-top: 13px;
          font-size: 18px;
          letter-spacing: -0.9px;
        }
      }

      .hashtag-header-title {
        font-size: 21px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: -1.1px;
        text-align: center;
        color: #ffffff;
        width: 90%;
        margin: 8px auto;
        @include desktop {
          margin-top: 35px;
          font-size: 35px;
          line-height: 1;
          letter-spacing: -1.75px;
        }
      }
    }

    .hashtags {
      margin-top: 22px;
      @include desktop {
        margin: 54px auto 0;
        display: flex;
        width: 100%;
        max-width: 1152px;
      }

      .hashtag {
        width: 335px;
        margin: -8px auto;
        text-align: center;
        color: white;
        font-weight: 500;
        padding: 12px;
        border-radius: 5px;
        letter-spacing: -0.8px;
        background-color: #263238;
        @include desktop {
          padding: 3px 50px;
          font-size: 21px;
          line-height: 2.98;
          letter-spacing: -1.2px;
        }
      }
    }

    .hashtag-present {
      margin-top: 27px;
      padding-bottom: 27px;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.6px;
      text-align: center;
      color: #ffffff;
      @include desktop {
        font-size: 18px;
        letter-spacing: -0.9px;
      }
    }
  }

  .retro-submit {
    padding-top: 33px;

    .title {
      font-size: 22px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -1.1px;
      text-align: center;
      color: #212529;
      @include desktop {
        font-size: 33px;
      }
    }

    .submit-group {
      width: 336px;
      margin: 24px auto 0;
      @include desktop {
        width: 400px;
      }

      .url {
        width: 100%;
        border-radius: 3px;
        border: solid 1px #adb5bd;
        padding: 15.5px 0;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.65px;
        text-align: center;
        color: #495057;
        @include desktop {
          font-size: 18px;
          font-weight: 500;
          line-height: 1;
          letter-spacing: -0.9px;
        }
      }

      .submit {
        margin-top: 8px;
        width: 100%;
        object-fit: contain;
        border-radius: 3px;
        background-color: #e8344e;
        text-align: center;
        padding: 14.5px 0;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #ffffff;
        cursor: pointer;

        @include desktop {
          font-size: 20px;
          margin-top: 23px;
        }
      }
    }

    .submit-next {
      cursor: pointer;
      margin-top: 11px;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #e8344e;
      @include desktop {
        font-size: 16px;
        margin-top: 15px;
      }
    }
  }

  .bg-gray {
    padding: 32px 11px 21px;

    @include desktop {
      width: 500%;
      margin-left: -200%;
      padding: 0 200% 30px;
    }

    .rcmd-content {
      @include desktop {
        width: 100%;
        padding-top: 35px;
      }

      .rcmd-intro {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: -0.9px;
        text-align: center;
        color: #000000;
        margin-top: 10px;
        @include desktop {
          font-size: 30px;
          padding-top: 5px;
        }
      }

      .rcmd-title {
        margin: 10px auto;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -1.2px;
        text-align: center;
        color: #000000;
        @include desktop {
          font-size: 35px;
          margin-top: 30px;
        }
      }

      .rcmd-outro {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.7px;
        text-align: center;
        color: #000000;
        @include desktop {
          margin-top: 36px;
          font-size: 18px;
        }
      }

      img.ic-rcmd {
        width: 353px;
        height: 188px;
        margin: 28px auto 0;
        object-fit: contain;
        display: block;
        @include desktop {
          width: 569.1px;
          height: 303.1px;
          margin: 47px auto;
          object-fit: contain;
        }
      }
    }
  }

  .howto {
    h3 {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.9px;
      text-align: center;
      color: #000000;
      margin: 36px 0 27px;

      @include desktop {
        font-size: 30px;
        letter-spacing: -1.5px;
        margin-bottom: 45px;
      }
    }

    .steps {
      display: flex;
      flex-direction: column;
      align-items: center;
      @include desktop {
        flex-direction: row;
        justify-content: center;
      }

      .step {
        width: 332px;
        height: 245px;
        padding: 20.3px 44.6px 23px 44px;
        border-radius: 5px;
        box-shadow: 0 1.7px 43.6px 0 rgba(0, 0, 0, 0.13);
        background-color: #ffffff;
        margin-bottom: 20px;

        @include desktop {
          width: 381px;
          height: 371px;
          padding: 36px 28px 23px 27px;
          border-radius: 3px;
        }

        &:last-child {
          @include desktop {
            margin-left: 30px;
          }
        }

        .step-number {
          width: 30px;
          height: 30px;
          margin: 2.7px 106.4px 10px 107px;
          border-radius: 22.2px;
          background-color: #343a40;
          font-size: 17px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: -0.85px;
          text-align: center;
          color: #ffffff;
          @include desktop {
            width: 42px;
            height: 42px;
            margin: 0 142px 24px;
            padding: 4.2px 16.3px 4.2px 16.3px;
            border-radius: 21px;
            background-color: #343a40;
            font-size: 21px;
          }
        }

        .step-title {
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: -0.8px;
          text-align: center;
          color: #212529;
          margin: 10px auto;
          @include desktop {
            font-size: 24px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: -1.2px;
            text-align: center;
            color: #212529;
          }
        }

        .step-desc {
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: -0.6px;
          text-align: center;
          color: #212529;
          margin: 10px auto;
          @include desktop {
            margin-top: 24px;
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: -0.9px;
            text-align: center;
            color: #212529;
          }
        }

        .el {
          margin: 15px auto;
        }

        .step-cta {
          margin-top: 8px;
          font-size: 17.1px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
          object-fit: contain;
          border-radius: 5px;
          background-color: #e8344e;
          padding: 16px 17px;
          @include desktop {
            margin-top: 40px;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
            cursor: pointer;
          }
        }
      }
    }
  }

  .rcmd-outro {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -0.9px;
    text-align: center;
    color: #000000;
    @include desktop {
      margin-top: 53px;
      font-size: 30px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -1.5px;
      text-align: center;
      color: #212529;
    }
  }

  .hrdiv {
    width: 500%;
    margin-left: -200%;
    height: 1.1px;
    background-color: #ced4da;
    margin-top: 51px;
    margin-bottom: 21px;
  }

  .caution {
    width: 254px;
    margin: auto;
    @include desktop {
      width: 560px;
      margin: 40px auto 80px;
    }

    p.notice {
      font-size: 12.5px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.88px;
      text-align: left;
      color: #495057;
      @include desktop {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -1.4px;
      }
    }

    p {
      font-size: 12.5px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.88px;
      text-align: left;
      color: #495057;
      @include desktop {
        font-size: 18px;
        letter-spacing: -1.26px;
      }
    }
  }

  span.highlight {
    color: $primary-color;
  }

  a.outlink {
    color: #495057;
    text-decoration: underline;
  }
}
