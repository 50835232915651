@import '../../../../_styles/utils';

.lecture-page__hrd {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 30px;

  span {
    font-family: "Pretendard";
    font-size: 14px;
    line-height: 1;
    color: #3b3b3b;
    text-decoration-line: underline;
    text-underline-position: under;
  }

  img {
    height: 14px;
  }
}

.link-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.hrd-container2 {
  padding: 0 20px !important;
}
.hrd-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px;
  padding: 20px 20px;
  border-radius: 8px;
  background-color: #fff;

  @include desktop {
    width: 811px;
    height: auto;
    padding: 0;
    margin: 0;
  }

  .title {
    width: 115px;
    height: 19px;
    flex-grow: 0;
    font-family: "Pretendard";
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    color: #3b3b3b;
  }

  .container {
    margin-top: 20px;
    margin-bottom: 8px;
    width: 100%;
    .button-row {
      width: 100%;
      height: 45px;
      flex-grow: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 12px;
      padding: 0;
      margin-bottom: 12px;

      @include desktop {
        width: 100%;
      }

      .button {
        cursor: pointer;
        height: 45px;
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        border: solid 1px #e3e3e3;

        font-size: 13px;
        font-weight: 600;
        line-height: 1;
        text-align: center;
        color: #000;

        @include desktop {
          width: 398px;
        }
      }
    }
  }
}

.hrd-wrapper {
  width: 100%;
  height: 88px;
  margin: 0 20px 72px;
  padding: 20px;
  border-radius: 8px;
  background-color: #e4e6e9;

  @include desktop {
    width: 811px;
    background-color: #F1F3F5;
  }

  .title {
    flex-grow: 0;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    color: #3b3b3b;
    margin-bottom: 8px;
  }

  .content {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.5;
    text-align: left;
    color: #3b3b3b;
  }
}
