.cta {
  cursor: pointer;
  width: 100%;
  background-color: #f1f3f5;
  border-radius: 5px;
  text-align: center;
  padding: 18px 0;
  color: #212529;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subCta {
  width: 100%;
  text-align: center;
  margin-top: 2px;
  font-size: 14px;
  letter-spacing: -0.84px;
  font-weight: 500;
  cursor: pointer;
}