@import "../../../../_styles/utils";

.select {
  //width: 100vh;
  //height: 100vh;

  position: relative;
  display: inline-block;

  &__selected {
    width: 300px;
    padding: 11px 28.4px;
    border-radius: 24px;
    border: solid 0.9px #adb5bd;
    background-color: $white;
    cursor: pointer;
    text-align: center;

    font-family: $spoqa-hans-neo;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: -0.96px;
    color: #000000;
    word-break: keep-all;
    &__img {
      position: absolute;

      width: 13.8px;
      height: 8.6px;

      right: 20px;
      top: 19px;
    }
  }

  &__options {
    position: absolute;
    top: 53px;
    z-index: 99;

    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    padding: 16px 0;
    border-radius: 24px;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.15);
    border: solid 0.9px #adb5bd;
    background-color: $white;
    max-height: 230px;
    overflow-y: scroll;

    &__option {
      font-family: $spoqa-hans-neo;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -0.84px;
      text-align: center !important;
      color: #adb5bd;
      cursor: pointer;

      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        color: #e8344e;
      }
    }
  }
}

.--selected {
  color: $black;
}
