.ant-row {
    flex-flow: initial;
    place-content: initial;
    flex-direction: column;
}

.ant-row a {
    text-align: center;
}
.ant-form .ant-form-item {
    margin-bottom: 24px;
}
.ant-form-item .ant-input {
    font-size: 16px;
    width: 100%;
    align-self: stretch;
    flex-grow: 0;
    border-radius: 8px;
    border: solid 1px #d4d4d4;
    background-color: #ffffff;
    outline: none;
}
.ant-form-item .ant-form-item-label {
    text-align: left;
}
.ant-form-item .ant-input:focus {
    border-color: #a4a6a9;
    box-shadow: none;
}