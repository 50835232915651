@import "../../../../../_styles/utils";

.LecMenuComponentContainer {
  margin-left: 20px;
  box-sizing: border-box;
  padding: 12px 0 19px 0;
  flex-wrap: wrap;

  img {
    width: 38px;
  }

  &.selected {
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: white;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    margin-left: 0;
    padding-left: 20px;

    span {
      font-size: 16px;
      line-height: 1;
      color: #000;
      font-family: $spoqa-hans-neo;
      font-weight: bold;
      letter-spacing: -0.48px;
      margin-left: 8px;
    }
  }
  &:last-child {
    position: absolute;
    right: 16px;
  }
}

// 듀얼모드일 때,
.LecMenuComponentContainer.dual {
  margin: 20px 0 0 0;
  box-sizing: border-box;
  padding: 0;
  flex-wrap: wrap;
  @include lectureNav {
    &.isNav {
      display: none;
    }
  }

  img {
    width: 38px;
  }

  &.selected {
    margin: 20px 0 0 0;
    padding: 0;
    background: none;
    border-radius: 0;
  }

  &:last-child {
    position: absolute;
    bottom: 20px;
    right: unset;
  }
}

.LecMenuComponentContainer.isNav {
  position: absolute;
  left: -50px;
  margin-left: 0;
  @include lectureNav {
    position: relative;
    left: 0;
  }
}
