@import '../../../../_styles/utils';

.classroom_banner {
  &__wrapper {
    cursor: pointer;
    width: 100%;
    margin-bottom: 20px;
    display: none;
    @include desktop {
      margin-bottom: 32px;
      display: block;
    }
  }

  &__img {
    width: 100%;
  }
}