@import '../../_styles/utils.scss';

.modalBodyContainer {
  width: 100%;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .titleContainer {
    text-align: center;
  }

  .myinfoContainer {
    border: 1px solid #CED4DA;
    border-radius: 10px;
    padding: 15px 20px;

    width: 300px;
    margin: 10px auto;

    font-size: 20px;
    font-weight: 500;
    color: black;

    ul {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .labelContainer {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          width: 40px;
          height: 40px;
        }

        span {
          padding: 0px;
          margin: 0px;
        }
      }

      .numberContainer {
        border-radius: 10px;
        background-color: #F1F3F5;
        padding: 5px 15px;

      }
    }
  }

  .treasureBoxContainer {
    margin-top: 75px;

    img {
      width: 200px;
      height: 200px;
    }
  }

  .treasureBoxContainerOpening {
    //margin-top: 20px;
    img {
      width: 400px;
      height: 276px;
    }
  }

  .emtpyboxContainer {
    img {
      margin-top: 150px;
      width: 200px;
      height: 130px;
    }
  }

  .addedPointContainer {
    position: fixed;
    top: calc(50% + 10px);
    font-size: 32px;
    color: black;
    font-weight: 500;
  }


  .buttonContainer {
    text-align: center;
    background-color: rgb(249, 251, 250);

    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 67%;

    .endButtonContainer {
      margin: 0 auto;
      width: 150px;
      button {
        margin-top: 30px;
        color: black;
        font-weight: 500;
        background-color: white;
        border: 2px solid #13C2C2;
        border-radius: 5px;
        width: 150px;
        font-size: 18px;
        height: 45px;
      }

      button:hover {
        background-color: $hover-color;
        color: white;
      }
    }

    .continueButtonContainer {
      margin: 0 auto;
      button {
        margin-top: 30px;
        color: white;
        font-weight: 500;
        background-color: $hover-color;
        border: 2px solid $hover-color;
        border-radius: 5px;
        width: 150px;
        font-size: 18px;
        height: 45px;
      }

      button:hover {
        background-color: $hover-color-deeper;
        border-color: $hover-color-deeper;
      }
    }


  }

  .buttonContainerInactive {
    text-align: center;


    button {
      margin-top: 30px;
      color: white;
      font-weight: 500;
      background-color: lightgray;
      //border: 2px solid #13C2C2;
      border-radius: 5px;
      width: 150px;
      height: 48px;
      font-size: 18px;
    }
  }
}