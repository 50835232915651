@import '../../../../../../_styles/utils';

.JindoCharacterContainer {
  display: flex;
  justify-content: center;
  -webkit-animation-name: running;
  -webkit-animation-duration: 4s;
  -webkit-animation-fill-mode: forwards;
  margin-top: -25px;
  transform: translateX(var(--initialX));


  @-webkit-keyframes running {
    0% {
      transform: translateX(var(--initialX));
    }
    100% {
      transform: translateX(var(--moveX));
    }
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}