@import '../../../../_styles/utils';

.nps_scroller__wrapper {
  position: relative;
  margin-bottom: 32px;

  .nps_scroller__current {
    font-family: "Pretendard";
    color: #505254;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.3;
    background: $white;
    border-radius: 8px;
    width: 100px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 30px auto;

    &.pirates {
      background: #C7D2D8;
      color: #141617;
    }
  }

  .nps_scroller__footer {
    position: absolute;
    bottom: -32px;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 15px;
    font-family: "Pretendard";
    font-weight: bold;
    line-height: 1.3;
  }

  &.--isNotStarted {
    .nps_scroller__current {
      border: 2px solid #c7c9cb;
      color: #c7c9cb;
      background: #e9ecef;

      &.pirates {
        background: #3A3E41;
        color: #5F666B;
        border: 2px solid #81898F;
      }
    }

    .nps_scroller__footer {
      color: #a4a6a9;

      &.pirates {
        color: #81898F;
      }
    }
  }
}
