@import '../../_styles/utils.scss';

.npsContainer {
  width: 840px;
  margin: auto;

  @include adesktop {
    width: 90%;
    margin: auto;
  }

  .satContainer {
    width: 100%;
  }

  .contentsContainer {
    width: 100%;
    margin: 40px auto;
    @include adesktop {
      margin-top: 30px;
    }

    h2 {
      @include adesktop {
        font-size: 28px;
      }
    }

    h4 {
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: 500;
      color: black;
      letter-spacing: -1px;
    }

    p {
      font-size: 16px;
      color: black;
      letter-spacing: -1px;
      @include adesktop {
        font-size: 15px;
      }
    }

    textarea {
      border-color: #CED4DA;
      border-radius: 5px;
      padding: 10px;
      width: 840px;
      @include adesktop {
        width: 100%;
      }
    }

  }

  .scoreContainer {
    .slideContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      @include adesktop {
        flex-direction: column;
        align-items: flex-start;
      }

      .slider:hover {
        border-color: $hover-color;
        //background-color: $hover-color;
      }
    }

    .rcmd {
      border-bottom: 1px dotted grey;
      margin-left: 5px;

      @include adesktop {
        margin-top: 10px;
      }

      &:hover {
        cursor: pointer;
        border-bottom: 1px solid grey;
      }
    }
  }

  .buttonContainer {
    width: 100%;


    .enterButtonContainer:hover {
      background-color: #009696;
      border: 1px solid #009696;
    }

    .enterButtonContainer {
      float: right;
      border: 1px solid #13C2C2;
      width: 350px;
      height: 62px;
      border-radius: 10px;
      background-color: #13c2c2;
      margin-bottom: 62px;

      @include adesktop {
        width: 100%;

      }

      .enterButtonTextContainer {
        width: 350px;
        height: 25.2px;

        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #ffffff;
        padding-bottom: 38px;


        @include adesktop {
          width: 100%;
          padding-bottom: 38px;
        }
      }
    }
  }
}

.wrapper {
  background-color: #f5f5f5;
}

.roadmapPageContainer {
  width: 1157px;
  margin: 40px auto 70px;

  // @include mobile {
  //     width: 100%;
  // }

  .event-banner-box {
    width: 100%;
    background-color: #212529;
    margin-top: 20px;
    border-radius: 5px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    padding: 20px 60px;

    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      span:first-child {
        font-weight: 500;
        font-size: 16px;
      }
    }

    .right {
      img {
        width: 140px;
        height: 54px;
      }
    }
  }


  .weekContentWrapper {
    margin: 22px 0;
  }
}

.modal-body {
  width: 100%;

  .event-title {
    padding: 17px 20px 13px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #212529;
    letter-spacing: -1px;
    font-size: 20px;

    span:first-child {
      font-weight: 500;
      font-size: 20px;
    }

    span {
      font-family: 'Black Han Sans', sans-serif;
      font-weight: 500;
      font-size: 28px;
    }
  }

  .header {
    text-align: center;
    margin: 42px auto;

    h3 {
      font-size: 21px;
      font-weight: 500;
      letter-spacing: -1px;

      span.pink-background {
        background-color: rgba(232, 52, 78, 0.2);
      }
    }

    p {
      line-height: 25px;
      font-size: 16px;
      color: black;
      font-weight: 500;
      letter-spacing: -0.5px;
      margin-top: 40px;
    }
  }

  .body {
    margin-top: 34px;

    .friend {
      padding: 4px 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 436px;
      margin: auto;

      input {
        height: 38px;
        border: 1px solid #e2e3e4;
        border-radius: 5px;
        font-size: 17px;
        background-color: white;
        color: black;
        padding: 10px;
        text-align: center;
      }

      input::placeholder {
        text-align: center;
        color: #C3D0DD;
      }

      .number {
        background-color: #212529;
        font-size: 21px;
        font-weight: 700;
        padding: 2px 25px;
        border-radius: 30px;
        color: white;
      }

      .label {
        input {
          width: 100px;
        }
      }

      .phone {
        input {
          width: 235px;
        }
      }
    }
  }

  .buttonContainer {
    text-align: center;
    margin-top: 28px;
    cursor: pointer;

    button {
      width: 280px;
      padding: 10px 57px;
      border-radius: 10px;
      border: none;
      font-size: 23px;
      font-weight: 500;
      background-color: $primary-color;
      color: white;
      //letter-spacing: -1px;
    }

    button.isSending {
      width: 280px;
      padding: 10px 57px;
      border-radius: 10px;
      border: none;
      font-size: 23px;
      font-weight: 500;
      background-color: rgba(232, 52, 78, 0.2);
      color: white;
    }

    button.isSending:hover {
      background-color: rgba(232, 52, 78, 0.2);
    }

    button:hover {
      background-color: $primary-hover-color;
    }
  }
}

.free-modal-body {
  width: 100%;

  .event-title {
    padding: 17px 20px 13px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #212529;
    letter-spacing: -1px;
    font-size: 20px;

    span:first-child {
      font-weight: 500;
      font-size: 20px;
    }

    span {
      font-family: 'Black Han Sans', sans-serif;
      font-weight: 500;
      font-size: 28px;
    }
  }

  .header {
    text-align: center;
    margin: 22px auto;

    img {
      width: 250px;
      margin: 0 auto;
      padding-bottom: 15px;
    }

    h3 {
      font-size: 21px;
      font-weight: 500;
      letter-spacing: -1px;

      span.pink-background {
        background-color: rgba(232, 52, 78, 0.2);
      }
    }

    p {
      line-height: 25px;
      font-size: 16px;
      color: #495057;
      font-weight: 500;
      letter-spacing: -0.5px;
      margin-top: 20px;
    }
  }

  .footer {
    text-align: left;
    margin: 0 auto 22px auto;
    width: 50%;
    color: #212529;

    p {
      line-height: 22.4px;
      font-size: 14px;
      letter-spacing: -1px;
    }

    p.footer-main {
      margin-bottom: 10px;
    }
  }

  .freeButtonContainer {
    text-align: center;
    margin-top: 28px;

    button {
      cursor: pointer;
      padding: 12px 22px;
      border-radius: 5px;
      border: none;
      font-size: 15px;
      line-height: 15px;
      font-weight: 500;
      background-color: $primary-color;
      color: white;
      //letter-spacing: -1px;
    }

    button:hover {
      background-color: $primary-hover-color;
    }
  }
}

.subCTA {
  margin-top: 10px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  color: #495057;

  span:hover {
    border-bottom: 1px solid gray;
  }
}