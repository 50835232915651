@import "../../../../_styles/utils";

.roadmap-details {
  width: 100%;
  
  &__el {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    width: 100%;
    
    &.--first {
      margin-top: 0px;
    }

    &.--bg {
      background-color: $white;
      border-radius: 25px;
      //margin-bottom: 16px;
    }

    &__header {
      font-family: $spoqa-hans-neo;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.6px;
      text-align: left;
      color: $black;
      margin: 32px 18px 0;
    }

    &__middle {
      font-family: $spoqa-hans-neo;
      font-size: 18px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: -0.6px;
      text-align: left;
      color: $black;
      margin: 24px 18px 0;
    }

    &__small {
      font-family: $spoqa-hans-neo;
      font-size: 14px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: -0.6px;
      text-align: left;
      color: $black;
      margin: 18px 18px 0;
    }

    &__content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &__hr-vertical {
        width: 2px;
        height: 60px;
        background-color: #dee2e6;
      }
    }
  }
}

.--flex-column {
  flex-direction: column;
}

.badge-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  border-radius: 24px;
  background-color: $white;

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title {
      display: flex;
      align-items: center;

      &__img {
        width: 24px;
        height: 24px;
        object-fit: contain;
        margin-right: 3px;
      }

      &__span {
        padding-top: 3px;
        font-family: $spoqa-hans-neo;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: #000000;
      }
    }

    &__status {
      display: block;
      padding: 4px 8px;
      border-radius: 24px;
      border: solid 1px $black;
      font-family: $spoqa-hans-neo;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.14;
      letter-spacing: -0.42px;
      color: $black;
    }
  }

  &__main {
    &__dirs {
      width: 208px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 0;

      &__left {
        cursor: pointer;
      }

      &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &__shallowpink {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 94px;
          height: 94px;
          //background-color: rgba(232, 52, 78, 0.2);
          border-radius: 200px;

          &__pink {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 88px;
            height: 88px;
            background-color: $primary-color;
            border-radius: 200px;
            position: relative;

            background: linear-gradient(
              to top,
              $primary-color,
              var(--percent),
              rgba(232, 52, 78, 0.2)
            );

            &__white {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 82px;
              height: 82px;
              background-color: $white;
              border-radius: 200px;

              &__body {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-family: $spoqa-hans-neo;
                font-size: 14px;
                font-weight: bold;
                line-height: 1.14;
                letter-spacing: -0.42px;
                text-align: center;
                color: $white;

                border-radius: 100px;
                width: 76px;
                height: 76px;
                background-color: rgba(232, 52, 78, var(--percent));

                &__span {
                  font-family: $spoqa-hans-neo;
                  font-size: 20px;
                  font-weight: bold;
                  line-height: 1.2;
                  letter-spacing: -0.6px;
                  text-align: center;
                  color: rgba(0, 0, 0, var(--percent));
                }
              }

              &__check {
                position: absolute;
                top: -12px;
              }
            }
          }
        }

        &__right {
          cursor: pointer;
        }
      }
    }
  }

  &__badges {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &__badge {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;

      &__img {
        width: 87px;
        height: 87px;
        object-fit: contain;
      }

      &__title {
        font-family: $spoqa-hans-neo;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        text-align: center;
        color: #000000;
        width: 87px;
        cursor: default;
      }

      &__desc {
        display: none;
        position: absolute;
        top: -69px;
        text-align: center;
        width: var(--width);
        max-width: 335px;
        padding: 10px 24px;
        border-radius: 30px;
        background-color: $black;

        font-family: $spoqa-hans-neo;
        font-size: 14px;
        color: $white;
      }

      &__arrow {
        display: none;
        position: absolute;
        top: -11.5px;
        left: 33px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 15px solid #000000;
      }
    }
  }
}
