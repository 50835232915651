@import '../../../../_styles/utils';

.summary-wrap {
  box-sizing: border-box;
  margin: 16px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 89%;
  @include desktop {
    max-width: 100%;
    margin: 32px auto;
  }

  .summary-card {
    box-sizing: border-box;
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 24px;
    background: white;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.15);
    color: #000;
    width: calc((100% - 24px) / 3);

    @include desktop {
      width: 350px;
      padding: 21px 0;
      flex-direction: row;
    }

    .thumb {
      width: 27px;
      @include desktop {
        width: 46px;
      }

      img {
        width: 100%;
      }
    }

    .summary-detail {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 42px;
      margin-top: 16px;

      @include desktop {
        text-align: left;
        height: 46px;
        margin-top: 0;

      }

      .summary-main {
        font-size: 17px;
        line-height: 1;
        font-weight: bold;
        text-align: center;
        margin: 0;
        font-family: $spoqa-hans-neo;
        @include desktop {
          font-size: 24px;
          text-align: left;
        }
      }

      .summary-sub {
        font-size: 12px;
        line-height: 1;
        font-weight: 500;
        text-align: center;
        margin: 0;
        font-family: $spoqa-hans-neo;
        @include desktop {
          font-size: 14px;
          text-align: left;
        }
      }
    }
  }
}