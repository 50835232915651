@import '../../../_styles/utils';

.wrapper {
  width:100vw;
  min-height:100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 25px;
  @include desktop{
    padding-top: 100px;
    padding-bottom: 114px;
  }
  background-color: #2f3031;
  font-family: Pretendard;
  font-weight: bold;
  font-size: 16px;

  .header {
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    line-height: 1.4;
    margin-bottom: 60px;
    padding:0 20px;
    @include desktop{
      height: 159px;
      padding:0;
      margin-bottom: 80px;
    }

    .chip {
      height: 28px;
      @include desktop{
        height: 32px;
      }
      flex-grow: 0;
      display: flex;
      padding: 5px 14px;
      gap: 22px;
      border-radius: 100px;
      background-color: #e8344e;

      &__name {
        font-size: 13px;
        @include desktop{
            font-size: 16px;
        }
        letter-spacing: 0.03px;
        color: #fff;
      }
    }

      .title {
        font-size: 24px;
        letter-spacing: normal;
        color: #eaebed;
         @include desktop{
            font-size: 28px;
         }
      }

      p {
        height: 21px;
        font-weight: 500;
        letter-spacing: normal;
        color: #c7c9cb;
        margin:0;
        font-size: 14px;
        @include desktop{
            font-size: 16px;
         }
      }
  }

  .first_img {
    width: 640px;
  }
  .first_img_mobile {
    width: 360px;
  }
  .second_img {
    width: 287px;
    position:relative;
    bottom:30px;
    @include desktop{
      width: 350px;
    }
  }

  button {
    width: 320px;
    height: 60px;
    border:none;
    border-radius: 8px;
    background-color: #e8344e;
    color: white;
    font-weight: bold;

    &:hover {
      cursor:pointer;
    }
  }
}