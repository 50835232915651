@import "../../../../_styles/utils";

.classroom-cards-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 89%;
  @include desktop {
    max-width: 100%;
  }

  .bannner {
    width: 100%;
    height: 170px;
    border: 1px solid #ff9ea9;
    border-radius: 12px;

    background-image: var(--bg-image-mo);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    margin: 16px 0;

    cursor: pointer;

    @include desktop {
      background-image: var(--bg-image-web);
    }
  }
}
