@use 'sass:math';
@import "../../../../../_styles/utils";
.RtannyBody {
    position: absolute;
    -webkit-animation: linear alternate;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 4s;
    left: -50px;

    img {
        width: 44px;
    }

    //@for $i from 1 through 10 {
    //    &.#{$i} {
    //        -webkit-animation-name: run#{$i};
    //        animation-delay: $i * 0.3s;
    //
    //        img {
    //            -webkit-animation: linear alternate;
    //            animation-fill-mode: forwards;
    //            -webkit-animation-duration: 0.7s;
    //            @if $i % 3 == 0 {
    //                animation-delay: $i * 0.3s;
    //                -webkit-animation-name: jump;
    //            } @else if $i % 9 == 0 {
    //                -webkit-animation-name: tumbling;
    //                animation-delay: $i * 0.3s;
    //            }
    //        }
    //    }
    //}

    &:hover {
        -webkit-animation-play-state: paused;
        -moz-animation-play-state: paused;
        -o-animation-play-state: paused;
        animation-play-state: paused;
    }
    .RtannyBody-hover {
        transform: translateX(-40%);
    }
}

@for $i from 1 through 10 {
    @-webkit-keyframes run#{$i} {
        0% {
            left: -50px;
            bottom: 0;
        }
        100% {
            left: percentage(math.div(random(65) + 15, 100));
            bottom: 0;
        }
    }
}

@-webkit-keyframes jump {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-60px);
    }
    100% {
        transform: translateY(0);
    }
}

@-webkit-keyframes tumbling {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-60px);
    }
    100% {
        transform: translateY(0);
    }
}
