@import '../../../_styles/utils';

.CheckinMainContainer {
  width: 100%;
  margin: 0 auto;
  padding-top: 44px;
  background-color: $main-background;
  h2 {
    text-align: center;
    @include desktop {
      text-align: center;
    }
  }
}