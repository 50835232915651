@import '../../../../_styles/utils';

.UserRaceInfoWrapper {
  border-radius: 100px;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.15);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  height: 61px;
  width: max-content;
  max-width: 100%;
  padding: 8px;

  .UserRaceInfoCourse {
    font-family: 'GmarketSans';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    color: black;
  }

  .UserRaceInfoUser {
    font-family: 'GmarketSans';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    color: black;
  }
}