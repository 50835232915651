@import '../../../../../_styles/utils';

.CodingRaceCastleContainer {
  img {
    width: 15%;
    max-width: 260px;
    object-fit: contain;
    right: -30px;
    position: absolute;
  }
  .castle-hidden{
    display: none;
  }

}