@import '../../_styles/utils.scss';

.floating {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  height: 84px;
  width: 100%;
  background: rgba(255, 255, 255, 0.88);
  @include desktop {
    height: 80px;
  }
  .floating_context{
    justify-content: center;
    display: flex;
    height: 84px;
    align-items: center;
    p {
      display: none;
    }
    @include desktop {
      p {
        display: block;
        color: #1c1d1e;
        font-size: 16px;
        font-weight: 700;
        font-family: "Pretendard";
        line-height: 1.5;
        padding: 16px 0;
        margin-bottom: 0;
        span {
          font-weight: 500;
          font-family: "Pretendard";
        }
      }
    }
    button {
      font-family: "Pretendard";
      color: white;
      background: $hanghae-primary-color;
      border-radius: 4px;
      width: 328px;
      height: 52px;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.5;
      letter-spacing: -0.54px;
      text-align: center;
      outline: none;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      @include desktop {
        margin: 7px 0 12px 446px;
        width: 240px;
        height: 56px;
      }
    }
  }
}
