@import "../../../../_styles/utils";

.round-week {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 8px;
  @include mobile {
    width: 360px;
  }
  @include tablet {
    width: 100%;
  }

  &__lectures {
    width: 100%;
    margin: auto;
    padding: 16px;
    border-radius: 10px;
    background-color: white !important;

    &.pirates {
      background-color: #2B2D2E !important;
    }

    @include desktop {
      width: 100%;
      padding: 16px 42px;
      border-radius: 24px;
    }
  }

  .__hr--dotted {
    width: 100%;
    height: 2px;
    background-image: linear-gradient(
                    to right,
                    black 50%,
                    rgba(255, 255, 255, 0) 0%
    );
    background-size: 10px 2px;
    background-repeat: repeat-x;
    margin: 22px auto 25px;

    &.pirates {
      background-image: linear-gradient(
                      to right,
                      #5F666B 50%,
                      rgba(255, 255, 255, 0) 0%
      );
    }
  }

  .__timer-box {
    background-color: #f8f8f8;
    height: 40px;
    border-radius: 10px;
    text-align: left;
  }

  .__timer-content {
    font-size: 16px;
    padding-top: 7px;
    font-weight: 500;
    padding-left: 14px;
  }

  .leftTimeText {
    font-weight: 600;
    color: #e8344e;
  }

  &__zero {
    margin-bottom: 16px;
  }

  &__wil {
    margin-bottom: 16px;
  }
  .__monthly_free_coupon {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    gap: 14px;

    background: #f6f9fa;
    border-radius: 8px;

    margin-top: 10px;

    width: 100%;
    height: 64px;
    .__text {
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;

      text-align: center;

      color: #141617;
      span {
        font-weight: 700;
        color: #e8344e;
      }

      @include desktop {
        font-size: 16px;
      }
    }
    img {
      width: 52px;
      margin-right: 14px;

      @include desktop {
        width: 74px;
        margin-right: 20px;
      }
    }
    @include desktop {
      margin-top: 24px;
      height: 74px;
      border-radius: 24px;
      padding: 20px 40px;
      gap: 20px;
    }
  }
}
