@import '../../_styles/utils';

.pageContainer {
  width: 50%;
  max-width: 1124px;
  margin: auto;
  padding-bottom: 20px;

  @include adesktop {
    width: 90%;
  }

  .noticeContainer {
    div {
      margin-top: 30px;
    }

    h2 {
      letter-spacing: -1px;
    }


    ul {
      border: 1px solid #dee2e6;
      border-radius: 5px;
      padding: 10px;

      li:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  .howtoContainer {
    margin-top: 30px;

    ul.steps {
      padding: 10px;
      border: 1px solid #dee2e6;
      border-radius: 5px;

      li {
        margin-bottom: 10px;

        ul.accInfo {
          border-left: 1px solid black;
          margin-top: 10px;
          margin-left: 30px;
          padding-left: 15px;

          li:not(:last-child) {
            margin-bottom: 10px;
          }

          li:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .yourNameContainer {
    margin-top: 20px;
    input {
      display: block;
      border: 1px solid #868e96;
      border-radius: 5px;
      padding: 10px;
    }
  }

  .agree {
    text-align: center;
    color: #868e96;
    margin: 20px auto 0px;
    a {
      color: #868e96;
    }
  }

  .ctaContainer {
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 15px;
    width: 100%;
    padding: 10px;
    text-align: center;
    background-color: $hover-color;
    color: white;
    font-size: 16px;
    font-weight: 500;
    border-radius: 10px;

    &:hover {
      background-color: $hover-color-deeper;
    }
  }
}